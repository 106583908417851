import React, { Component, useState } from 'react';
import { Form, getFormValues } from 'react-simple-form-control';
import './Login.scss';
import '../../../assets/Geogrotesque/geofrotesque.css';
import Auth from '../../../services/Auth';
import enviromment from '../../../environment';
import { Link } from 'react-router-dom';
import Topo from '../../../components/EmpresaConvenio';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading'
import BannerEmpresaConvenio from '../../../components/EmpresaConvenio/BannerEmpresaConvenio';
import '../../../components/EmpresaConvenio/EmpresaConvenio.css';
import SolicitacaoService from '../../../services/SolicitacaoService';
import EmpresaService from '../../../services/EmpresaService';
import { Modal, Button } from 'react-bootstrap';
import SelecionarEmpresa from '../../../views/Pages/Login/SelecionarEmpresa';
import Recaptcha from 'react-recaptcha';
import EsqueciSenha from './EsqueciSenha';
import AssinaturaContratoService from '../../../services/AssinaturaContratoService';

export default class LoginLDAP extends Component {

    constructor(props) {
        super(props);
        const config = process.env.REACT_APP_STAGE === 'prod'
        this.state = {
            isVerified: false,
            politica: props.politica || false,
            valid: false,
            loading: false,
            vigencia: {
                msg: '',
                authorization: false,
                showModal: false
            },
            error: '',
            form: {
                listEmpresas: { value: props.listEmpresas || [] },
                ru: {
                    value: '',
                    validation: {
                        output: "ruError",
                        validators: [{
                            type: "required",
                            msg: "Campo obrigatório."
                        }]
                    }
                },
                senha: {
                    value: '',
                    validation: {
                        output: "senhaError",
                        validators: [{
                            type: "required",
                            msg: "Campo obrigatório."
                        }]
                    }
                },
                sistema: {
                    value: enviromment.sistema,
                }
            }
        }
        this._onChangeForm = this._onChangeForm.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this.load = this.load.bind(this);
        this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this._handlePolitica = this._handlePolitica.bind(this);

        this.ModalLoginEmpresas = this.ModalLoginEmpresas.bind(this);
    }

    recaptchaLoaded() {
        console.log('captcha carregado');
    }

    load() {
        var politica = this.state.politica;
        politica = localStorage.getItem("uninterPolitica_" + enviromment.ambiente);
        this.setState({ politica });

        //Verificação recaptcha
        if (enviromment.ambiente !== "PROD")
            this.setState({ isVerified: true })

    }

    componentDidMount() {
        const { form } = this.state;
        form.ru.ref.current.focus(); //Set focus
        this.load();
    }

    verifyCallback(response) {
        if (response) {
            this.setState({
                isVerified: true
            })
        }
    }

    _handlePolitica(response) {
        if (response) {
            this.setState({
                politica: true
            });
            localStorage.setItem("uninterPolitica_" + enviromment.ambiente, true);
        }
    }

    verificarVigencia = async () => {
        const { form, vigencia } = this.state;
        const { data: response } = await SolicitacaoService.getVigencia(form.ru.value);
        if (response == false) {
            vigencia.msg = 'O contrato da empresa qual você tem vínculo está expirado. Para acessar entre em contato como comercial da Uninter.com';
            vigencia.titulo = 'VINGÊNCIA DO CONTRATO';
            vigencia.authorization = false;
            vigencia.showModal = true;
            this.setState({ vigencia });
            setTimeout(() => { Auth.logout(); }, 2000);
        } else {
            this.props.history.push("/");
        }
    }

    ModalLoginEmpresas() {
        const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
        this.props.history.push("/selecionarEmpresa")
        return (
            <>
                <button className="uninter-btn uninter-btn-editar-operador" tooltip-position="left" tooltip="Download do Contrato" variant="primary" onClick={handleShow} >
                    <i className="fa fa-download"></i>
                </button>
                <Modal show={show} onHide={handleClose} animation={false} size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Download do Contrato - UNINTER</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><SelecionarEmpresa ru={this.state.form.ru} /></Modal.Body>
                    <Modal.Footer>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <span className="pull-left" >
                                {new Date().getFullYear().toString()} &copy; Empresa Convênio Uninter - Departamento de Tecnologia da Informação
                                </span>
                            <p className="copyright pull-right"></p>
                        </div>
                        <input className="uninter-box-home-convenioempresas-btn-enviar-formulario" id="btnCancelar" hidden={true} onClick={handleClose} value="CANCELAR" />
                    </Modal.Footer>
                </Modal>
            </>
        );
    }


    _onSubmit = async (values, valid) => {
        this.setState({ loading: true });
        const { form } = this.state;
        if (valid[0]) {
            var result = await Auth.login(values);

            if (this.state.isVerified) {
                if (result) {
                    // form.listEmpresas.value = await AssinaturaContratoService.OnGetListaEmpresasParaAssinatura();
                    // //(form.listEmpresas.value.length == 0 || form.listEmpresas.value.length > 1) ? this.props.history.push("/") : this.props.history.push("/selecionarEmpresa");
                    // if(form.listEmpresas.value.length == 1)
                    // { 
                    //    var isEmpresa=form.listEmpresas.value[0].codigo;
                    //    this.props.history.push(`/administracao/visualizarContrato/${isEmpresa}`);
                    //    localStorage.setItem("IdEmpresa", isEmpresa);
                    // }
                    // else if(form.listEmpresas.value.length > 1) {this.props.history.push("/selecionarEmpresa");}
                    // else this.props.history.push("/")
                    
                    this.props.history.push("/")
                }
                else
                    toast.warn("Login/Senha incorretos.", { timeOut: 5000 });

            } else {
                toast.error("Favor preencher o reCaptcha.")
            }
        }
        this.setState({ loading: false });
    }

    async _onChangeForm(form) {
        this.setState({ form })
    }

    _onValid = valid => this.setState({ valid })

    render() {
        const { form, loading, vigencia, politica } = this.state;

        console.log(politica);
        return (
            <>
                {loading && Loading}
                <div>
                    <Topo></Topo>
                    <BannerEmpresaConvenio></BannerEmpresaConvenio>
                    <div className="uninter-container-convenioempresas">
                        <div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 offset-lg-1 uninter-topo-convenioempresas">
                                {!!vigencia.showModal && (
                                    <this.ModalLoginEmpresas data={vigencia} />
                                )}
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                    <h3>CONVÊNIO EMPRESARIAL UNINTER</h3>
                                    <br></br>
                                    <div className="uninter-conteudo-texto-convenioempresas">
                                        <p>
                                            Quem quer superar metas e desafios — e reconhece o conhecimento como o caminho mais seguro para o sucesso — tem tudo a ver com a Uninter.
                                        </p>
                                        <p>
                                            Estamos entre os maiores grupos educacionais do país, com polos presenciais em mais de 700 cidades do Brasil e também nos Estados Unidos. Além de nos conferir um excelente conceito geral no MEC, a nossa qualidade de ensino nos tornou uma referência não só para o mercado, mas também para as pessoas que acompanham a evolução e as conquistas de nossos alunos.
                                        </p>
                                        <p>
                                            Com o Convênio Empresarial Uninter, sua empresa tem a oportunidade de fazer parte da missão de transformar vidas por meio da educação. Seja no modelo de convênio desconto ou de contrato pagamento, essa é a iniciativa ideal para estimular o contínuo desenvolvimento dos colaboradores, bem como de seus dependentes e familiares. E sem custo para a empresa!
                                        </p>
                                        <p>
                                            A Uninter já é parceira de mais de 8.000 organizações, como: fundações, prefeituras, sindicatos, associações e outras empresas. Faça parte disso: ofereça esse benefício e aproveite as vantagens.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                    <div className="uninter-box-home-convenioempresas">
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 uninter-box-home-un1">
                                            <div className="uninter-box-home-convenioempresas-un1">
                                                <h3>CADASTRO DE EMPRESA</h3>
                                                <span>Preencha o Formulário clicando no botão abaixo.</span>

                                                <Link to={!!politica ? "formulario" : "login"}>
                                                    <div className="chamaFormulario uninter-box-home-convenioempresas-btn">
                                                        Formulário para<br />
                                                        Formalização de Convênio
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 uninter-box-home-un2">
                                            <div className="uninter-box-home-convenioempresas-un2">
                                                <h3>EMPRESA CADASTRADA</h3>
                                                <span><b className="font-b">Área Privada</b><br />
                                                    Digite abaixo Login e Senha para acessar a Área Privada</span>
                                                <Form onSubmit={this._onSubmit} formControl={form} onChangeForm={this._onChangeForm} onValid={this._onValid}>
                                                    <center>
                                                        <ul className="uninter-convenioempresas uninter-passo-interno uninter-convenioempresas-login">
                                                            <li>
                                                                <div className={this.state.focused ? "focused col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos" : "col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos"}>
                                                                    <input type="text" id="inputMatricula" name="ru" className="form-control" placeholder="Login" required="" />
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                    <input type="password" id="inputPassword" name="senha" className="form-control" placeholder="Senha" required="" />
                                                                </div>
                                                            </li>
                                                            <button className="uninter-box-home-convenioempresas-btn" type="submit">Entrar</button>

                                                           <EsqueciSenha />

                                                            <Recaptcha
                                                                sitekey="6Lc8vaUUAAAAAI2cXfHs3ZE8Dt5O6M9ap1E7GzSV"
                                                                render="explicit"
                                                                onloadCallback={this.recaptchaLoaded}
                                                                verifyCallback={this.verifyCallback}
                                                                hl="pt-BR"
                                                            />
                                                        </ul>
                                                    </center>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                    <div className="uninter-conteudo-texto-convenioempresas-box-paragrafos col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <br></br>
                                            <h3>CONVÊNIO DESCONTO</h3>
                                            <br></br>
                                            <div className="uninter-conteudo-texto-convenioempresas">
                                                <p>
                                                    Neste formato de parceria, a Uninter concede aos beneficiários* o <b>DESCONTO DE 10%</b> sobre o valor líquido das mensalidades dos cursos de graduação e de pós-graduação (Lato Sensu), nas modalidades a distância, presencial, telepresencial e semipresencial. Válido também para o EJA (Educação de Jovens e Adultos), na modalidade a distância.
                                        </p>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <br></br>
                                            <h3>CONTRATO PAGAMENTO (CONVÊNIO)</h3>
                                            <br></br>
                                            <div className="uninter-conteudo-texto-convenioempresas">
                                                <p>
                                                    No convênio por contrato pagamento, <b>a empresa pode custear o percentual de até 100%</b> do valor das parcelas mensais do curso de seus colaboradores. Caso o percentual deste investimento da empresa não atenda a totalidade dos custos, o colaborador terá a responsabilidade de arcar com o saldo restante das parcelas/mensalidades, dentro do prazo de vencimento estipulado em contrato. Por meio deste formato de parceria, todas as modalidades e níveis de cursos também estão disponíveis. Caso tenha interesse neste tipo de contrato, favor encaminhar e-mail para <a href="mailto:parceria.corporativo@uninter.com">parceria.corporativo@uninter.com</a>.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="uninter-conteudo-texto-politicaprivacidade">
                                    Nós usamos cookies para melhorar a sua experiência em nossos sites, personalizar publicidade e recomendar conteúdo de seu interesse. Ao acessar o site da Uninter, você concorda com o uso dessa tecnologia. Saiba mais em nossa   <a target="_self" href="https://www.uninter.com/politica-de-privacidade/">  Política de Privacidade</a> e <a target="_self" href="https://www.uninter.com/politica-de-cookies/">  Política de Cookies.</a>
                                </div> */}


                            </div>
                        </div>
                    </div>
                </div>



                {!politica && (
                    <div className="uninter-conteudo-barra-texto-politicaprivacidade" id="cookie-law-info-bar">
                        <span>
                            <div className="cli-bar-container cli-style-v2">
                                <br></br>
                                <div className="cli-bar-message">Nós usamos cookies para melhorar a sua experiência em nossos sites, personalizar publicidade e recomendar conteúdo de seu interesse. Ao acessar o site da Uninter, você concorda com o uso dessa tecnologia. Saiba mais em nossa
                                <a className="cookie-banner-lgpd-link cookie-banner-lgpd_privacy-link" target="_self" href="https://www.uninter.com/politica-de-privacidade/">  Política de Privacidade</a> e <a className="cookie-banner-lgpd-link cookie-banner-lgpd_privacy-link" target="_self" href="https://www.uninter.com/politica-de-cookies/">  Política de Cookies.</a>
                                </div>
                                <br></br>
                                <div className="cli-bar-btn_container">
                                    <a role="button" tabindex="0" data-cli_action="accept" id="cookie_action_close_header" onClick={this._handlePolitica} className="large cli-plugin-button cli-plugin-main-button cookie_action_close_header cli_action_button">Concordo</a>
                                </div>
                                <br></br>
                            </div>
                        </span>
                    </div>
                )}







            </>
        )
    }
}
