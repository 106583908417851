import BaseApi from "./BaseApi";

export default class EnderecoService {

    static async getCep(zipcode) {
        try {
            let res = await BaseApi.get(`v1/GetEnderecoByCep/${zipcode}`);
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    }

    static async getUFs() {
        try {
            let res = await BaseApi.get(`v1/StatesZipCode/`);
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    }

    static async getCidades(uf) {
        try {
            let res = await BaseApi.get(`v1/CitiesZipCode/${uf}`);
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    }
}