
  import React, { useState } from 'react';
  import { Link } from 'react-router-dom';
  import enviromment from '../../environment';
  import './BreadCrumb.scss';

  export default function BreadCrumb(props){
    const breads = props.breads.map(item =>
        <li key={item.url} className={`breadcrumb-item ${item.active ? 'active':''}`} aria-current="page" >
                {item.active ? <span >{item.name}</span> : <Link to={item.url}>{item.name}</Link>}
        </li>
    );
      return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li>
                    <button className="uninter-btn-voltar" style={{float:'left', marginRight: 15}} onClick={() => {window.history.back()}}>
                        Voltar
                    </button>
                </li>
                <li className="uninter-breadcrumb-enviromement-nome">
                    <h3>
                        {enviromment.nome}
                    </h3>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/">
                        <i className="fa fa-home">
                        </i> Início
                    </Link>
                </li>
                {breads}
            </ol>
        </nav>
      );
  }

  BreadCrumb.defaultProps = {
    breads: [],
};