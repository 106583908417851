import React, { Component } from 'react';
import '../../containers/Default/Default.css';
import SolicitacaoService from '../../services/SolicitacaoService';
import contratoService from '../../services/ContratoService';
import documentoService from '../../services/DocumentoService';
import Loading from '../../components/Loading';
import Table from '../../components/Table';
import { Form } from 'react-simple-form-control'
import { Combobox } from '../../components/Listas/Combobox';
import { toast } from 'react-toastify';

export default class DocumentoLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: false,
            error: '',
            data: [],
            form: {
                IdEmpresa: {
                    value: props.IdEmpresa || '',
                },
                IdSolicitacao: {
                    value: props.IdSolicitacao || '',
                },
                IdSituacaoSolicitacao: {
                    value: props.IdSituacaoSolicitacao || '',
                },
                CNPJ: {
                    mask: '99.999.999/9999-99',
                    value: props.CNPJ || '',
                },
                NomeFantasia: {
                    value: props.NomeFantasia || '',
                },
                IdTipoDocumento: {
                    value: props.IdTipoDocumento || 0,
                },
                IdTipoArquivo: {
                    value: props.IdTipoArquivo || 0,
                },
                IdTipoContrato: {
                    value: props.IdTipoContrato || 0,
                },
                IdModeloContrato: {
                    value: props.IdModeloContrato || 0,
                },
                nomeArquivo: {
                    value: props.nomeArquivo || '',
                },
                caminho: {
                    value: props.caminho || '',
                },
                listModelos: { value: props.listModelos || [{ codigo: 0, descricao: 'SELECIONE' }], },
                listTiposContrato: { value: props.listTiposContrato || [{ codigo: 0, descricao: 'SELECIONE' }], },
                listTiposDocumentos: { value: props.listTiposDocumentos || [{ codigo: 0, descricao: 'SELECIONE' }], }
            }
        }
        this._onChangeForm = this._onChangeForm.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    load = async () => {
        this.setState({ loading: true });
        const { form } = this.state;
        form.listModelos.value = await contratoService.OnGetModelos();
        form.listTiposContrato.value = await contratoService.OnGetTipos();
        form.listTiposContrato.value = await contratoService.OnGetTipos();
        form.listTiposDocumentos.value = await documentoService.OnGetTipos();
        const response = await SolicitacaoService.OnPostAllAnexos(this.montaFiltros());
        debugger
        document.getElementById("contratoAssinado").style.visibility = "hidden";

        if (form.IdSituacaoSolicitacao.value == 4) {
            document.getElementById("contratoAssinado").style.visibility = "visible";
        }

        this.setState({ data: response.data, loading: false })
    }

    _onSubmit = async (values) => {
        try {
            this.setState({ loading: true });
            const response = await SolicitacaoService.OnPostAllAnexos(this.montaFiltros());
            this.setState({ data: response.data, loading: false });
        }
        catch (error) { }
    }

    _handleLimpaFiltro = async (values) => {
        this.setState({ loading: true });
        const { form } = this.state;

        form.IdTipoDocumento.value = ''

        // this.load();
        this.setState({ form, loading: false })
    }

    buscarDadosForm() {
        const { form } = this.state;
        return {
            IdSolicitacao: form.IdSolicitacao.value,
            IdTipoDocumento: form.IdTipoDocumento.value
        }
    }

    DownloadContrato = async () => {

        this.setState({ loading: true })
        debugger
        const { form } = this.state
        const res = await contratoService.downloadContratoAssinado(form.IdEmpresa.value);
        const linkSource = `data:application/pdf;base64,${res}`;
        const downloadLink = document.createElement("a");
        const fileName = `Contrato.pdf`

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.setState({ loading: false })
    }

    montaFiltros() {
        const { form } = this.state;
        debugger;
        return {
            IdSolicitacao: form.IdSolicitacao.value,
            TipoAnexo: form.IdTipoArquivo.value,
            TipoContrato: form.IdTipoContrato.value,
            TipoDocumento: form.IdTipoDocumento.value,
            ModeloContrato: form.IdModeloContrato.value,
            IdAnexo: 0
        }
    }


    _handleTipoDocumentoChange = async (event) => {
        const { form } = this.state;
        form.IdTipoDocumento.value = event.target.value;
        this.setState({ form });
    }
    _handleTipoContratoChange = async (event) => {
        const { form } = this.state;
        form.IdTipoContrato.value = parseInt(event.target.value);
        this.setState({ form, IdTipoContrato: event })
    }
    _handleModeloContratoChange = async (event) => {
        const { form } = this.state;
        form.IdModeloContrato.value = parseInt(event.target.value);
        this.setState({ form, IdModeloContrato: event })
    }

    DownloadFile = async (data) => {
        var model = {
            TipoAnexo: data.idTipoAnexo,
            Caminho: data.caminho
        }
        const res = await SolicitacaoService.OnPostDownloadAnexo(model);
        if (res.success == true) {
            let documento = res.data;
            var byteCharacters = atob(documento);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], { type: data.caminho.split(".")[1] });
            if (typeof window.navigator.msSaveBlob === "function") {
                window.navigator.msSaveBlob(blob, data.nomeArquivo);
            }
            else {
                const a = document.createElement('a');
                a.style = 'display: none';
                document.body.appendChild(a);
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = data.nomeArquivo;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        }
    }

    async _onChangeForm(form) {
        this.setState({ form })
    }

    _onValid = valid => this.setState({ valid })

    render() {
        var columns = [
            {
                Header: 'Id',
                width: 100,
                accessor: 'idAnexo',
                className: 'uninter-table-value'
            },
            {
                Header: 'Nome Documento',
                width: 230,
                accessor: 'nomeArquivo',
                className: 'uninter-table-value'
            },
            {
                Header: 'Caminho',
                width: 370,
                accessor: 'caminho',
                className: 'uninter-table-value'
            },
            {
                Header: 'Tipo',
                id: 'idTipoAnexo',
                accessor: (d) => {
                    if (d.idTipoAnexo == 1) return <span>Contrato</span>
                    if (d.idTipoAnexo == 0) return <span>Outro Documento</span>
                },
                width: 200,
                className: 'uninter-table-value'
            },
            {
                id: 'action',
                Header: 'Ação',
                accessor: d => {
                    return (
                        <>
                            <span id={d.idAnexo} className="uninter-btn uninter-btn-editar-operador" tooltip-position="left" tooltip="Download"><i className="fa fa-download" onClick={() => this.DownloadFile(d)}></i></span>
                        </>
                    );
                },
                className: 'uninter-table-acao'
            }]
        const { form, valid } = this.state;

        return (
            <>
                {
                    this.state.loading && Loading
                }
                {
                    <>
                        <Form onSubmit={this._onSubmit} formControl={form} onChangeForm={this._onChangeForm} onValid={this._onValid}>
                            <div className="row">
                                <ul className="uninter-declaro-convenioempresas uninter-desconto-pagamento">
                                    <li className="form-check">
                                        <input type="radio" id="contrato" name="IdTipoArquivo" value="0" />
                                        <label className="form-check-label ">Todos</label>
                                        <input type="radio" id="contrato" name="IdTipoArquivo" value="1" />
                                        <label className="form-check-label ">Contrato</label>
                                        <input type="radio" id="documento" name="IdTipoArquivo" value="2" />
                                        <label className="form-check-label checkPagamento">Outros Documentos</label><br />
                                    </li>
                                    <span className="error" id="IdTipoArquivoError"></span>
                                </ul>
                            </div>
                            <div className="row" hidden={form.IdTipoArquivo.value == 0 || form.IdTipoArquivo.value == 1}>
                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1 uninter-campos">
                                    <label>Selecione Tipo de Documento</label>
                                    <Combobox onChange={this._handleTipoDocumentoChange} opstions={form.listTiposDocumentos.value} id={"IdTipoDocumento"} name={"IdTipoDocumento"}></Combobox>
                                </div>
                            </div>
                            <div className="row" hidden={form.IdTipoArquivo.value == 0 || form.IdTipoArquivo.value == 2}>
                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1 uninter-campos">
                                    <label>Selecione Tipo de Contrato</label>
                                    <Combobox onChange={this._handleTipoContratoChange} opstions={form.listTiposContrato.value} id={"IdTipoContrato"} name={"IdTipoContrato"}></Combobox>
                                </div>
                            </div>
                            <div className="row" hidden={form.IdTipoArquivo.value == 0 || form.IdTipoArquivo.value == 2}>
                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1 uninter-campos">
                                    <label>Selecione Modelo de Contrato</label>
                                    <Combobox onChange={this._handleModeloContratoChange} opstions={form.listModelos.value} id={"IdModeloContrato"} name={"IdModeloContrato"}></Combobox>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <center>
                                    <input className="uninter-box-home-convenioempresas-btn-enviar-formulario" type="reset" onClick={this._handleLimpaFiltro} value="LIMPAR FILTROS" />
                                    <input className="uninter-box-home-convenioempresas-btn-enviar-formulario" style={{ margin: "0 15px" }} type="submit" value="FILTRAR TIPO" />
                                </center>
                                <input id="contratoAssinado" className="uninter-box-home-convenioempresas-btn-enviar-formulario" onClick={this.DownloadContrato} value="CONTRATO" />
                            </div>
                            <br />
                            <div className="form-group col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="row">
                                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-dados-contato-un">
                                        <div className="card">
                                            <div className="card-lista-inscricao">
                                                <div className="uninter-card-table-body card-body">
                                                    <Table
                                                        columns={columns}
                                                        data={this.state.data}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </>
                }
            </>
        )
    }
}