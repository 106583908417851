import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './services/Permission/PrivateRoute'
import AcessoNegado from './views/Pages/AcessoNegado';
import NotFound from './views/Pages/NotFound';
import Login from './views/Pages/Login';
import BrowserInvalido from './views/Pages/BrowserInvalido';
import { Loading} from './components';
import './scss/style.scss';
import Formulario from './views/Formulario';
import Dashboard from './views/Dashboard';
import MultiUpload from './views/MultiUpload/MultiUpload';
import Usuarios from './views/Cadastros/Usuarios';
import Finish from './views/Formulario/Finish';

// Containers
const Default = React.lazy(() => import('./containers/Default'));

//Esse é o arquivo de rotas main, elas que rodam o primerio layer da aplicação. Como você quer que essa tela seja "home" tem que ficacr configurado aqui
class App extends Component {
  render() {
    return(
      <React.Suspense fallback={Loading}>
        <Switch>
          <Route exact path="/formulario" name="Formulário" render={props => <Formulario {...props}/>} />
          <Route exact path="/finish" name="Empresa Convênio - Cadastrado com Sucesso" render={props => <Finish {...props}/>} />
          <Route exact path="/multiUpload" name="multiUpload" render={props => <MultiUpload {...props}/>} />
          <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
          <Route exact path="/404" name="Page 404" render={props => <NotFound {...props}/>} />
          <Route exact path="/500" name="Page 500" render={props => <AcessoNegado {...props}/>} />
          <Route exact path="/browserInvalido" name="Browser Inválido" render={props => <BrowserInvalido {...props}/>} />
          <PrivateRoute path="*" name="Home" component={props => <Default {...props}/>} />
        </Switch>
      </React.Suspense>
    );
  };
}

export default App;