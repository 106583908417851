

export function mapMinutosToTime(minutos) {
    minutos = Number(minutos);
    const horas = 60;
    const dias = 1440;
    if (minutos >= horas && minutos < dias) {
        return { time: horas, value: (minutos / horas).toFixed(0) }
    } else if (minutos >= dias) {
        return { time: dias, value: (minutos / dias).toFixed(0) }
    }
    return { time: 1, value: minutos }
};

export function isURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(str);
}

export function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export const cpfMask = value => {
    debugger
    return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const telefoneMask = value => {
    value = value.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    if (value.length >= 10) {
        value = value.replace(/(.{1})(\d)/, '($1$2) ')
    }
    value = value.replace(/(.{4})$/, "-$1")
    return value;
}

export const isCompareDates = (dateStart, dateFinish) => {
    var parts = dateStart.split('-');
    dateStart = new Date(parts[0], parts[1] - 1, parts[2]);

    parts = dateFinish.split('-');
    dateFinish = new Date(parts[0], parts[1] - 1, parts[2]);

    return dateStart <= dateFinish;
}

export const isDayValid = value => {
    return (isDateValid(value) && parseInt(value.substring(0, 4)) > 1900);
}

export const isDateValid = value => {
    var patternData = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
    return patternData.test(value);
}

export const isNullOrEmptyOrWhiteSpaceOrUndefined = value => {
    if (value !== null && value !== undefined)
        value = value.trim();;

    return (value == null ||
        value == '' ||
        value == undefined);
}

export const descricaoMax15 = value => {
    if (isNullOrEmptyOrWhiteSpaceOrUndefined(value))
        return '';
    else {
        if (value.length > 15)
            return value.substring(0, 12) + "...";
        else
            return value;
    }
}

export const isNullOrUndefined = value => {
    return (value === null || value === undefined);
}

export const isNullOrZeroOrUndefined = value => {
    return (value === null || value < 1 || value === undefined);
}

export const urlToRecuperarSenha = (accountUrl, url, name) => {
    const encodeQs = btoa(`redirectTo=${url}&redirectTitle=${name}`);
    return `${accountUrl}recuperar-senha?${encodeQs}`;
}

export const removeCaracteresEspeciais = value => {
    value = value.replace('/[áàãâä]/ui', 'a');
    value = value.replace('/[éèêë]/ui', 'e');
    value = value.replace('/[íìîï]/ui', 'i');
    value = value.replace('/[óòõôö]/ui', 'o');
    value = value.replace('/[úùûü]/ui', 'u');
    value = value.replace('/[ç]/ui', 'c');
    value = value.replace('/[^a-z0-9]/i', '_');
    value = value.replace('/_+/', '_');
    value = value.replace('(', '');
    value = value.replace(')', '');
    value = value.replace('.', '');
    value = value.replace('.', '');
    value = value.replace('-', '');
    value = value.replace(' ', '');
    return value;
}
export const emailIsValid = value => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(value) == 0 ? false : true;
}

export const arrayIsNotNull = value=>{
    return value.length > 0;
}

export const downloadFile = (file, name, fileExtention) => {

    const byteCharacters = atob(file);

    let byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray]);
    const a = document.createElement('a');
    a.style = 'display: none';
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `${name}.${fileExtention}`;
    a.click();
    window.URL.revokeObjectURL(url);
}