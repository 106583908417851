import React, { Component } from 'react';
import Topo from '../../components/EmpresaConvenio';
import FileUpload from '../../components/EmpresaConvenio/FileUpload';
import { Form, mapValues, getFormValues } from 'react-simple-form-control'
import '../../assets/Geogrotesque/geofrotesque.css';
import '../../assets/css/bootstrap-grid.css';
import '../../assets/css/style.css';
import Loading from '../../components/Loading';
import { cpfMask, telefoneMask } from '../../helpers';
import { toast } from 'react-toastify';
import solicitacaoService from '../../services/SolicitacaoService';
import contratoService from '../../services/ContratoService';
import documentoService from '../../services/DocumentoService';
import enderecoService from '../../services/EnderecoService';
import empresaService from '../../services/EmpresaService';
import { Link } from 'react-router-dom';
import ModalAlert from '../../components/ModalAlert';
import '../../components/EmpresaConvenio/EmpresaConvenio.css'
import '../../assets/Geogrotesque/geofrotesque.css';
import '../../assets/css/bootstrap-grid.css';
import '../../assets/css/style.css';
import { FormText, Tooltip } from 'react-bootstrap';

import FileMultiUpload from '../../components/EmpresaConvenio/FileMultiUpload';

// const ListItem = ({ onChange, onDelete, value }) => {
//     return (
//       <div className="Item-container">
//         <input
//           className="Item-field"
//           value={value}
//           onChange={onChange}
//         />
//         <button onClick={onDelete}>Excluir</button>
//       </div>
//     );
//   };
export default class MultiUpload extends Component {

    constructor(props) {
        super(props);
        
        // this.state = {            
        //     valid: true,
        //     loading:false,
        //     error: '',
        //     form: {
        //         lista:{
        //             value:[]
        //         } ,
        //         arquivoNovo:{
        //             value:{}
        //         },
        //         tipoDocumento:{
        //             value:0
        //         }
        //     },
        //     showModal: false,
        //     loading: true
        // }
        
        // this.fileInputRef = React.createRef();
        // this._onChangeForm = this._onChangeForm.bind(this);
        // this.handleChange = this.handleChange.bind(this);
        // this._onSubmit = this._onSubmit.bind(this);
    }

    // componentDidMount() {
    //     const {
    //     } = this.props;
    //     var form = this.state.form;
    //     form.lista.value = []
    //     this.setState({ form, loading: false });
    // }

    // async _onChangeForm(form) {
    //     this.setState({ form })
    // }
    
    // _onValid = valid => this.setState({ valid })

    // fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
   
    // handleUploadDemand = (ie: ChangeEvent<HTMLInputElement>) => {
    //     const fileList: FileList = ie.target.files;
    //     // do something with the FileList, for example:
    //     const fileReader = new FileReader();
    //     fileReader.onload = () => {
    //         const str = String(fileReader.result);
    //         try {
    //         } catch (error) {
    //         }
    //     };
    //     fileReader.readAsBinaryString(fileList[0])
    // }

    // handleChange(selectorFiles: FileList)
    // {        
    //     const { form } = this.state;
    //     var texto = "";
    //     let validos = /(\.jpg|\.png|\.gif|\.pdf|\.doc|\.docx)$/i;

    //     var newFileList = Array.from(selectorFiles)
    //     for (var i = 0; i < selectorFiles.length; i++) {
    //         if(!validos.test(selectorFiles[i].name))
    //         {
    //             newFileList.splice(i,1);
    //             toast.warn("Tipo de arquivo selecionado não é permitido. O arquivo ["+selectorFiles[i].name+"] será removido.");
    //         }
    //         else
    //         {
    //             if(texto === "")
    //                 texto = selectorFiles[i].name;
    //             else
    //                 texto += ", " +selectorFiles[i].name;
            
    //             if(texto.length > 14)
    //             { 
    //                 var textoReduzido = texto.substring(0,14) + "...";
    //                 document.getElementById('inputGroupFile011').innerHTML = textoReduzido;
    //             }
    //             else
    //                 document.getElementById('inputGroupFile011').innerHTML = texto;
                
    //             document.getElementById('inputGroupFile011').title = texto;
    //         }
    //     }

    //     selectorFiles=newFileList;
    //     form.arquivoNovo.value = newFileList;
    //     this.setState({ form });
    // }

    // handleClick()
    // {
    //     this.fileInputRef.current.click();
    // }

    // handleDrop(){
    //     document.getElementById('idContrato').value = '';
    //     document.getElementById('inputGroupFile011').innerHTML = this.props.linput;
    // }   

    // addClick= async(event)=>{
    //     debugger;
    //     const { form } = this.state;

    //     var checkArquivo = function(value){;;
    //         return (value.arquivo.name == form.arquivoNovo.value[0].name &&
    //                 value.arquivo.type == form.arquivoNovo.value[0].type &&
    //                 value.arquivo.size == form.arquivoNovo.value[0].size);
    //     }

    //     if(form.tipoDocumento.value === 0)
    //     {
    //         toast.warn('Selecione o tipo de Documento.');
    //     }
    //     else
    //     {
    //         if(form.lista.value !== undefined && form.lista.value.length > 0 && form.lista.value.filter(checkArquivo).length > 0)
    //             toast.warn("Não é permitido adicionar o mesmo arquivo mais de uma vez.");
    //         else
    //             form.lista.value.push({name:form.arquivoNovo.value[0].name, extensao:form.arquivoNovo.value[0].type, tipo:form.tipoDocumento.value, idTipo:form.tipoDocumento.value, arquivo:form.arquivoNovo.value[0]});       
    //     } 
    //     this.setState({ form });
    // }

    // _onSubmit = async (values, valid) => {      
    //     if (valid[0]) {
         
    //     } else {
           
    //     }
    // }

    // onChangeTipoDocumento= async (event) =>
    // {
    //     const { form } = this.state;
    //     form.tipoDocumento.value =event.target.value;     
    //     this.setState({ form });
    // }

    render() {
        // const { form } = this.state;
       
        // const todoItems = form.lista.value.map((todo, index) =>
        //     <table>
        //         <tr>
        //             <th>Nome</th>
        //             <th>Extensao</th>
        //             <th>Tipo</th>
        //         </tr>            
        //         <tr key={index} id={index}>
        //             <td>{todo.name}</td>
        //             <td>{todo.extensao}</td>
        //             <td>{todo.tipo}</td>                
        //         </tr>
        //     </table>
        // );

        return (
            <>
            <FileMultiUpload PrazoIndeternimado={1} IdAprovarSolicitacao={1}></FileMultiUpload>
                {/* {
                    this.state.loading &&
                    Loading
                }
      
                <div>
                    <Topo></Topo>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <Link className="uninter-box-home-convenioempresas-btn-enviar-formulario" id="linkLogin" to="Login">Início</Link>
                    </div>
                    <div className="uninter-container-convenioempresas">        
                        <Form onSubmit={this._onSubmit} formControl={form} onChangeForm={this._onChangeForm} onValid={this._onValid}>                                                  
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">   
                                    <label>Selecione tipo de documento</label>                  
                                    <br></br>
                                    <select onChange={this.onChangeTipoDocumento}>
                                        <option value="0">Selecione</option>
                                        <option value="1">Tipo 1</option>
                                        <option value="2">Tipo 2</option>
                                        <option value="3">Tipo 3</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">                                   
                                    <input type="file" className="custom-file-input" id={this.props.idfile || "idArquivo"} aria-describedby="inputGroupFileAddon01"  onChange={ (e) => this.handleChange(e.target.files)}
                                            multiple={this.props.multiple || false} ref={this.fileInputRef} hidden={true} accept="application/pdf,image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" name={this.props.name}/>
                                    <br/>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text label-file" id="inputGroupFileAddon01" id="basic-addon1">{this.props.ltype || "Arquivo"}</span>
                                        </div>
                                        <label type="text" className="form-control label-file input-white-file" aria-label="Large" id="inputGroupFile011" aria-describedby="basic-addon1 basic-addon2">{this.props.linput || "Selecione um arquivo"}</label>
                                        <div className="input-group-append">
                                            <span className="input-group-text label-file search-file" id="basic-addon2" onClick={ (e) => this.handleClick(e.target.files)}>{this.props.lsearch || "Buscar"}</span>
                                        </div>
                                        <div className="input-group-append">
                                            <span className="input-group-text label-file drop-file" id="basic-addon2" onClick={ (e) => this.handleDrop()}><i className="fa fa-times" aria-hidden="true"></i></span>
                                        </div>   
                                    </div>
                                </div>
                            </div>      
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">   
                                    <button onClick={this.addClick}> Add</button>      
                                </div>
                            </div>       
                           <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">   
                                    {this.state.lista}
                                </div>
                            </div>    
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">   
                                {todoItems}        
                                </div>
                                </div>    
                        </Form>                 
                    </div>
                </div> */}
            </>
        )
    }
}