import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'

export default function Table(props) {
    const translations = {
        previousText: 'Anterior', nextText: 'Próximo', loadingText: 'Carregando...',
        noDataText: 'Nenhum resultado encontrado', pageText: 'Página', ofText: 'de', rowsText: 'linhas',
        pageJumpText: 'Ir para página', rowsSelectorText: 'por página'
    };
    const [pageSize, setPageSize] = useState(10);
    // const [showPagination, setShowPagination] = useState(pageSize < props.data.length);
    // useEffect(() => {
    //     setShowPagination(pageSize < props.data.length);
    //   });
    // if(props.data.length == 0){
    //     return (
    //         <div style={{textAlign:'center'}}>Nenhum registro encontrado</div>
    //     );
    // }
    return (<ReactTable
        minRows={0}
        defaultPageSize={pageSize}
        onPageSizeChange={(pageSize, pageIndex) => {setPageSize(pageSize)}}
        showPagination={true}
        {...translations}
        {...props}
    />
    );
}