import React, { Component } from 'react';
import AuthService from '../../../services/Auth';
import { Redirect } from 'react-router-dom';
import { Loading } from '../../../components';
import './Login.scss';
import '../../../assets/Geogrotesque/geofrotesque.css';

export default class Login extends Component {
    constructor(){
        super();
        this.state = {
            loading: true,
            logged: false
        }
    }

    async login(){
        await AuthService.login();
        this.setState({loading:false, logged:AuthService.isLoggedIn()});
    }

    render() {
        this.login();
        if(!this.state.loading){
            if (this.state.logged) {
                return <Redirect to='/'/>;
            }
            return <Redirect to='/500'/>;
        }
        return Loading;
    }
}
