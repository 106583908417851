import React from 'react';

import environment from '../../../environment';

import { urlToRecuperarSenha } from '../../../helpers';

const EsqueciSenha = () => {
    const url = urlToRecuperarSenha(environment.webAccount, environment.web, 'Empresa convênio');

    return (
        <a href={url} class="button esqueci-minha-senha">Esqueci minha senha</a>
    );
};

export default EsqueciSenha;