import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class NotFound extends Component {
    render() {
        return (
            <div className="page-wrap d-flex flex-row align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <span className="display-1 d-block">404</span>
                            <div className="mb-4 lead">A página que você está buscando não foi encontrada.</div>
                            <Link to="/" className="btn btn-link">Início</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
