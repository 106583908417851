import React, { Component, HTMLInputElement } from 'react';
import './EmpresaConvenio.css'
import '../../assets/Geogrotesque/geofrotesque.css';
import '../../assets/css/bootstrap-grid.css';
import '../../assets/css/style.css';
import { toast } from 'react-toastify';

export default class FileUpload extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        if(this.props.accept == "" || this.props.accept == undefined || this.props.accept ==null)
            this.props.accept="application/pdf,image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    }

    fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();

    handleUploadDemand = (ie: ChangeEvent<HTMLInputElement>) => {
        const fileList: FileList = ie.target.files;
        // do something with the FileList, for example:
        const fileReader = new FileReader();
        fileReader.onload = () => {
            const str = String(fileReader.result);
            try {
            } catch (error) {
            }
        };
        fileReader.readAsBinaryString(fileList[0])
    }

    handleChange(selectorFiles: FileList) {
        var texto = "";
        let validos = /(\.jpg|\.png|\.gif|\.jpeg|\.pdf|\.doc|\.docx|\.docx)$/i;

        if(this.props.accept  == "application/vnd.openxmlformats-officedocument.wordprocessingml.sheet")
            validos = /(\.ods|\.xls|\.xlsx)$/i;
        
        var newFileList = Array.from(selectorFiles)
        for (var i = 0; i < selectorFiles.length; i++) {
            if (!validos.test(selectorFiles[i].name)) {
                newFileList.splice(i, 1);
                toast.warn("Tipo de arquivo selecionado não é permitido. O arquivo [" + selectorFiles[i].name + "] será removido.");
            }
            else {
                if (texto === "")
                    texto = selectorFiles[i].name;
                else
                    texto += ", " + selectorFiles[i].name;

                if (texto.length > 14) {
                    var textoReduzido = texto.substring(0, 14) + "...";
                    document.getElementById('inputGroupFile011').innerHTML = textoReduzido;
                }
                else
                    document.getElementById('inputGroupFile011').innerHTML = texto;

                document.getElementById('inputGroupFile011').title = texto;
            }
        }

        if (texto !== "")
            document.getElementById('basic-addon2').innerHTML = "Alterar";
        else
            document.getElementById('basic-addon2').visibility = "Buscar";

        selectorFiles = newFileList;
    }

    handleClick() {
        this.fileInputRef.current.click();
    }

    handleDrop() {
        document.getElementById('idContrato').value = '';
        document.getElementById('inputGroupFile011').innerHTML = this.props.linput;
    }

    render() {
        return <div>
            <input type="file" className="custom-file-input" id={this.props.idfile} aria-describedby="inputGroupFileAddon01" onChange={(e) => this.handleChange(e.target.files)}
                multiple={this.props.multiple} ref={this.fileInputRef} hidden={true} accept={this.props.accept} name={this.props.name} />
            <br />
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text label-file" id="inputGroupFileAddon01" id="basic-addon1">{this.props.ltype}</span>
                </div>
                <label type="text" className="form-control label-file input-white-file" aria-label="Large" id="inputGroupFile011" aria-describedby="basic-addon1 basic-addon2">{this.props.linput}</label>
                <div className="input-group-append">
                    <span className="input-group-text label-file search-file" id="basic-addon2" onClick={(e) => this.handleClick(e.target.files)}>{this.props.lsearch}</span>
                </div>
                <div className="input-group-append">
                    <span className="input-group-text label-file drop-file" id="basic-addon2" onClick={(e) => this.handleDrop()}><i className="fa fa-times" aria-hidden="true"></i></span>
                </div>
            </div>
        </div>
    }
}