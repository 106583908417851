import React, { useState } from 'react';

import './Menu.css';
import logoDev from '../../assets/_images/logo/logo-uninter-DEV.png';
import logoQA from '../../assets/_images/logo/logo-uninter-qa.png';
import logo from '../../assets/_images/logo/logo-uninter.png';
import { Link, NavLink } from 'react-router-dom';
import AuthService from '../../services/Auth';
import AplicacaoService from '../../services/AplicacaoService';
import MenuItem from './MenuItem';
import enviromment from '../../environment';

function Menu(props) {

    const [toggleSideBar, setToggle] = useState(!AplicacaoService.getStateMenuOpen());
    const [menuAbertoId, setMenuAberto] = useState(0);

    const menus = AuthService.getMenu();

    const toggleMenu = () => {
        setToggle(!toggleSideBar);
        AplicacaoService.setStateMenuOpen(toggleSideBar);
    }

    function toggleListaMenu(menuId) {
        setMenuAberto(menuId === menuAbertoId ? 0 : menuId);
    }

    return (
        <div className="uninter-class-convenio-menu-base wrapper uninter-menu-base">
            <nav id="sidebar" className={(toggleSideBar ? 'active' : '')}>
                <div className="sidebar-header">
                    <div className="uninter-dark">
                        {!toggleSideBar && <Link to="/" className="navbar-brand " >
                            <img alt="" src={`${enviromment.ambiente === 'DEV' ? logoDev : (enviromment.ambiente === 'QA' ? logoQA : logo)}`} width="148" height="35" />
                        </Link>}
                        <button className="btn btn-lg btn-link navbar-toggler-desktop btn-hamburguer" type="button" onClick={toggleMenu} >
                            <i className="fa fa-bars uninter-detalhes-yellow" aria-hidden="true">
                            </i>
                        </button>
                    </div>
                </div>

                <ul className="list-unstyled components menu">

                    <li>
                        <NavLink to="/" exact={true} className="d-flex" activeClassName='abrir-menu' onClick={() => setMenuAberto(0)}>
                            <i className="fa fa-home"></i>
                            <div className="uninter-class-convenio-menu-base-texto">
                            Início
                            </div>
                        </NavLink>
                        <div className={`space`}></div>
                        {menus.map(menu => menu.visivel || menu.filhos.length > 0 ? <MenuItem key={menu.id} {...menu} menuAbertoId={menuAbertoId} toggleMenu={toggleListaMenu} /> : null)}
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Menu;