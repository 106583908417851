import React, { Component, useState } from 'react';
import enviromment from '../../environment';
import '../../containers/Default/Default.css';
import '../../assets/css/style.css';
import SolicitacaoService from '../../services/SolicitacaoService';
import Loading from '../../components/Loading';
import BreadCrumb from '../../components/BreadCrumb';
import Table from '../../components/Table';
import { Form, mapValues, getFormValues, isFormValid, isStepValid } from 'react-simple-form-control'
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import solicitacaoService from '../../services/SolicitacaoService';
import quintoElementoService from '../../services/QuintoElementoService';
import contratoService from '../../services/ContratoService';
import documentoService from '../../services/DocumentoService';
import pessoaService from '../../services/PessoaService';
import combobox, { Combobox } from '../../components/Listas/Combobox';
import Helpers, { isNullOrEmptyOrWhiteSpaceOrUndefined, isCompareDates, isDayValid, isDateValid, isNullOrUndefined, isNullOrZeroOrUndefined, descricaoMax15 } from '../../helpers/Helper';
import Select from 'react-select'

export default class Contrato extends Component {

    constructor(props) {
        super(props);
        this.state = {
            valid: true,
            loading: false,
            error: '',
            form: {
                IdSolicitacao: {
                    value: props.IdSolicitacao || '',
                },
                IdEmpresa: {
                    value: props.IdEmpresa || '',
                },
                IdTipoConvenio: {
                    value: props.IdTipoConvenio || '',
                },
                RazaoSocial: {
                    value: props.RazaoSocial || '',
                },
                CNPJ: {
                    mask: '99.999.999/9999-99',
                    value: props.CNPJ || '',
                },
                NomeFantasia: {
                    value: props.NomeFantasia || '',
                },
                IdSituacaoSolicitacao: {
                    value: props.IdSituacaoSolicitacao || '',
                },
                IdAprovarSolicitacao: {
                    value: props.IdAprovarSolicitacao || 0,
                    validation: {
                        output: "IdAprovarSolicitacaoError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                        ]
                    }
                },
                IdTipoArquivo: {
                    value: props.IdTipoArquivo || 0,
                },
                ControlarFuncionarios: {
                    value: props.ControlarFuncionarios || 0,
                },
                PercentualDescontoEAD: {
                    value: props.PercentualDescontoEAD || null
                },
                PercentualDescontoPresencial: {
                    value: props.PercentualDescontoPresencial || null
                },
                IdPercentualDescontoEAD: {
                    value: props.IdPercentualDescontoEAD || null
                },
                IdPercentualDescontoPresencial: {
                    value: props.IdPercentualDescontoPresencial || null
                },
                OutroPercentualDescontoEAD: {
                    value: 0
                },
                OutroPercentualDescontoPresencial: {
                    value: 0
                },
                Representante: {
                    value: props.Representante || '',
                },
                DataValidadeInicio: {
                    value: props.DataValidadeInicio || '',
                    validation: {
                        output: "DataValidadeInicioError",
                        validators: [
                            {
                                type: 'custom',
                                args: ["{DataValidadeFim}", "{IdAprovarSolicitacao}"],
                                params: ["{DataValidadeFim}", "{IdAprovarSolicitacao}"],
                                function: (value, DataValidadeFim, IdAprovarSolicitacao) => {
                                    return (value != "" && DataValidadeFim != "" && !isCompareDates(value, DataValidadeFim) && IdAprovarSolicitacao == 1) ? false : true;
                                },
                                msg: "A data início não pode ser maior que a data fim."
                            }
                        ]
                    }
                },
                DataValidadeFim: {
                    value: props.DataValidadeFim || '',
                    validation: {
                        output: "DataValidadeFimError",
                        validators: [
                            {
                                type: 'custom',
                                args: ["{DataValidadeInicio}", "{IdAprovarSolicitacao}"],
                                params: ["{DataValidadeInicio}", "{IdAprovarSolicitacao}"],
                                function: (value, DataValidadeInicio, IdAprovarSolicitacao) => {
                                    return (DataValidadeInicio != "" && value != "" && !isCompareDates(DataValidadeInicio, value) && IdAprovarSolicitacao == 1) ? false : true;
                                },
                                msg: "A data fim não pode ser menor que a data início."
                            }
                        ]
                    }
                },
                DataValidadeInicioArquivo: {
                    value: props.DataValidadeInicioArquivo || '',
                },
                DataValidadeFimArquivo: {
                    value: props.DataValidadeFimArquivo || '',
                },
                IdTipoDocumento: {
                    value: props.IdTipoDocumento || 0,
                },
                IdTipoContrato: {
                    value: props.IdTipoContrato || 0,
                },
                IdModeloContrato: {
                    value: props.IdModeloContrato || 0,
                },
                IdPessoa: {
                    value: props.IdPessoa || '',
                },
                Descricao: {
                    value: props.Descricao || '',
                    validation: {
                        output: "DescricaoError",
                        validators: [
                            {
                                type: 'custom',
                                args: ["{IdAprovarSolicitacao}"],
                                params: ["{IdAprovarSolicitacao}"],
                                function: (value, IdAprovarSolicitacao) => {
                                    return (value == '' && IdAprovarSolicitacao == 2) ? false : true;
                                },
                                msg: "Campo obrigatório."
                            },
                            {
                                type: "maxLength",
                                params: [500],
                                msg: "Preencha no máximo 500 caracteres."
                            }
                        ]
                    }
                },
                DescricaoArquivo: { value: props.DescricaoArquivo || '', },
                PrazoIndeterminado: {
                    value: props.PrazoIndeterminado || 0,
                    validation: {
                        output: "PrazoIndeterminadoError",
                        validators: [
                            {
                                type: 'custom',
                                args: ["{DataValidadeInicio}", "{DataValidadeFim}", "{IdAprovarSolicitacao}"],
                                params: ["{DataValidadeInicio}", "{DataValidadeFim}", "{IdAprovarSolicitacao}"],
                                function: (value, DataValidadeInicio, DataValidadeFim, IdAprovarSolicitacao) => {
                                    return (value == false && (DataValidadeInicio == "" || DataValidadeFim == "") && IdAprovarSolicitacao == 1) ? false : true;
                                },
                                msg: "Informe o período de vigência"
                            },
                        ]
                    }
                },
                PrazoIndeterminadoArquivo: {
                    value: props.PrazoIndeterminadoArquivo || 0,
                    // validation: {
                    //     output: "PrazoIndeterminadoArquivoError",
                    //     validators: [
                    //         {
                    //             type: 'custom',
                    //             args:  ["{DataValidadeInicioArquivo}", "{DataValidadeFimArquivo}","{IdAprovarSolicitacao}"],
                    //             params: ["{DataValidadeInicioArquivo}", "{DataValidadeFimArquivo}","{IdAprovarSolicitacao}"],
                    //             function: (value, DataValidadeInicioArquivo, DataValidadeFimArquivo, IdAprovarSolicitacao) => {                                    
                    //                 return (value == false && (DataValidadeInicioArquivo == "" || DataValidadeFimArquivo == "") && IdAprovarSolicitacao == 1)?false:true;
                    //             },
                    //             msg: "Informe o período de vigência"
                    //         },
                    //     ]
                    // }
                },
                arquivoNovo: {
                    value: {}
                },
                Lista: {
                    value: props.Lista || [],
                    validation: {
                        output: "ListaError",
                        validators: [
                            {
                                type: 'custom',
                                args: ["{IdAprovarSolicitacao}"],
                                params: ["{IdAprovarSolicitacao}"],
                                function: (value, IdAprovarSolicitacao) => {
                                    return (value.length == 0 && IdAprovarSolicitacao == 1) ? false : true;
                                },
                                msg: "Adicione um item na lista de arquivos."
                            },
                        ]
                    }
                },
                listModelos: { value: props.listModelos || [{ codigo: 0, descricao: 'SELECIONE' }], },
                listTiposContrato: { value: props.listTiposContrato || [{ codigo: 0, descricao: 'SELECIONE' }], },
                listTiposDocumentos: { value: props.listTiposDocumentos || [{ codigo: 0, descricao: 'SELECIONE' }], },
                listPercentDescount: { value: props.listPercentDescount || [{ codigo: 0, descricao: 'SELECIONE' }], },
                listResponsaveis: { value: props.listResponsaveis || [{ codigo: 0, descricao: 'SELECIONE' }], },
            },
            showModal: false,
            loading: true
        }
        this._onChangeForm = this._onChangeForm.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this.multifileInputRef = React.createRef();
        this.handleChangeMultiInputFile = this.handleChangeMultiInputFile.bind(this);
        this.onClickExcluir = this.onClickExcluir.bind(this);
    }

    isAprovado() {
        const { form } = this.state;
        return form.IdAprovarSolicitacao.value == 0;
    }
    isTipoContrato() {
        const { form } = this.state;
        return form.IdTipoArquivo.value == 1;
    }

    componentDidMount() {
        this.load();
    }

    load = async () => {
        const { form } = this.state;
        this.setState({ loading: true });
        form.listModelos.value = await contratoService.OnGetModelos();
        form.listTiposContrato.value = await contratoService.OnGetTipos();
        form.listTiposContrato.value = await contratoService.OnGetTipos();

        if (form.IdTipoConvenio.value == '2') {
            form.listTiposDocumentos.value = await documentoService.OnGetTipos(form.IdTipoConvenio.value);
            form.listResponsaveis.value = await pessoaService.OnGetRepresentantes(form.IdEmpresa.value);
            form.listResponsaveis.value.push({codigo: form.IdEmpresa.value, descricao: form.RazaoSocial.value}) //= await pessoaService.OnGetRepresentantes(form.IdEmpresa.value);
        }
        else {
            form.listTiposDocumentos.value = await documentoService.OnGetTipos();
        }
        form.listPercentDescount.value = await quintoElementoService.OnGetCashDiscount();
        this.setState({ form, loading: false });
    }

    async _onChangeForm(form) {
        this.setState({ form })
    }

    _onValid = valid => this.setState({ valid })

    /* multiupload*/
    handleUploadDemand = (ie: ChangeEvent<HTMLInputElement>) => {
        const fileList: FileList = ie.target.files;
        // do something with the FileList, for example:
        const fileReader = new FileReader();
        fileReader.onload = () => {
            const str = String(fileReader.result);
            try {
            } catch (error) {
            }
        };
        fileReader.readAsBinaryString(fileList[0])
    }

    handleChangeMultiInputFile(selectorFiles: FileList) {
        const { form } = this.state;
        var texto = "";
        let validos = /(\.jpg|\.png|\.jpeg|\.gif|\.pdf|\.doc|\.docx)$/i;
        var newFileList = Array.from(selectorFiles)
        for (var i = 0; i < selectorFiles.length; i++) {
            if (!validos.test(selectorFiles[i].name)) {
                newFileList.splice(i, 1);
                toast.warn("Tipo de arquivo selecionado não é permitido. O arquivo [" + selectorFiles[i].name + "] será removido.");
            }
            else {
                if (texto === "")
                    texto = selectorFiles[i].name;
                else
                    texto += ", " + selectorFiles[i].name;

                if (texto.length > 14) {
                    var textoReduzido = texto.substring(0, 14) + "...";
                    document.getElementById('inputGroupFile012').innerHTML = textoReduzido;
                }
                else
                    document.getElementById('inputGroupFile012').innerHTML = texto;

                document.getElementById('inputGroupFile012').title = texto;
            }
        }
        if (texto !== "")
            document.getElementById('basic-addon3').innerHTML = "Alterar";
        else
            document.getElementById('basic-addon3').innerHTML = "Buscar";

        selectorFiles = newFileList;
        form.arquivoNovo.value = newFileList;
        this.setState({ form });
    }

    handleClick() {
        this.multifileInputRef.current.click();
    }

    addClick = async (event) => {
        const { form } = this.state;

        var returnTypeFile = function (name) {
            let validos = /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx)$/i;
            if (validos.test(name)) {
                validos = /(\.jpg)$/i;
                if (validos.test(name)) {
                    return 'jpg';
                }
                validos = /(\.png)$/i;
                if (validos.test(name)) {
                    return 'png';
                }
                validos = /(\.jpeg)$/i;
                if (validos.test(name)) {
                    return 'jpeg';
                }
                validos = /(\.doc)$/i;
                if (validos.test(name)) {
                    return 'doc';
                }
                validos = /(\.pdf)$/i;
                if (validos.test(name)) {
                    return 'pdf';
                }
                validos = /(\.docx)$/i;
                if (validos.test(name)) {
                    return 'docx';
                }
            }
        }
        var checkArquivo = function (value) {
            return (value.arquivo.name == form.arquivoNovo.value[0].name &&
                value.arquivo.type == form.arquivoNovo.value[0].type &&
                value.arquivo.size == form.arquivoNovo.value[0].size);
        }
        if (form.IdTipoArquivo.value === 0) {
            toast.warn('Selecione o Tipo de Arquivo.');
        }
        else
            if ((isNullOrEmptyOrWhiteSpaceOrUndefined(form.DataValidadeInicioArquivo.value) || (!isDayValid(form.DataValidadeInicioArquivo.value))) &&
                (isNullOrEmptyOrWhiteSpaceOrUndefined(form.DataValidadeFimArquivo.value) || (!isDayValid(form.DataValidadeFimArquivo.value))) &&
                (form.PrazoIndeterminadoArquivo.value == 0)) {
                toast.warn('Informe o período de vigência.');
            }
            else
                if ((form.PrazoIndeterminadoArquivo.value == 0) && (isNullOrEmptyOrWhiteSpaceOrUndefined(form.DataValidadeInicioArquivo.value) || (!isDayValid(form.DataValidadeInicioArquivo.value))) &&
                    !(isNullOrEmptyOrWhiteSpaceOrUndefined(form.DataValidadeFimArquivo.value) || (!isDayValid(form.DataValidadeFimArquivo.value)))) {
                    toast.warn('Verique o campo [Data Início] do período de validade.');
                }
                else
                    if ((form.PrazoIndeterminadoArquivo.value == 0) && (isNullOrEmptyOrWhiteSpaceOrUndefined(form.DataValidadeFimArquivo.value) || (!isDayValid(form.DataValidadeFimArquivo.value))) &&
                        !(isNullOrEmptyOrWhiteSpaceOrUndefined(form.DataValidadeInicioArquivo.value) || (!isDayValid(form.DataValidadeInicioArquivo.value)))) {
                        toast.warn('Verique o campo [Data Fim] do período de validade.');
                    }
                    else if ((form.PrazoIndeterminadoArquivo.value == 0) && (!isCompareDates(form.DataValidadeInicioArquivo.value, form.DataValidadeFimArquivo.value))) {
                        toast.warn('A Data Início não pode ser maior que a Data Fim.');
                    }
                    else
                        if (form.IdModeloContrato.value === 0 && form.IdTipoArquivo.value == 1) {
                            toast.warn('Selecione o Modelo de Contrato.');
                        }
                        else
                            if (form.IdTipoContrato.value === 0 && form.IdTipoArquivo.value == 1) {
                                toast.warn('Selecione o Tipo de Contrato.');
                            }
                            else
                                if (form.IdTipoDocumento.value === 0 && form.IdTipoArquivo.value == 2) {
                                    toast.warn('Selecione o tipo de Documento.');
                                }
                                else if (form.DescricaoArquivo.value.length > 500) {
                                    toast.warn('O campo descrição do arquivo não pode ter mais de 500 caracteres.');
                                }
                                else if (form.arquivoNovo == undefined || form.arquivoNovo.value[0] == undefined) {
                                    toast.warn('Selecione o Arquivo.');
                                }
                                else {
                                    if (form.Lista.value !== undefined && form.Lista.value.length > 0 && form.Lista.value.filter(checkArquivo).length > 0)
                                        toast.warn("Não é permitido  o mesmo arquivo mais de uma vez.");
                                    else {
                                        var count = form.Lista.value.length == 0 ? 1 : form.Lista.value.length + 1;
                                        var tipoDoc = form.IdTipoArquivo.value == '1' ? '---' : document.getElementById('IdTipoDocumento').options[document.getElementById('IdTipoDocumento').selectedIndex].text;
                                        var idTipoDoc = form.IdTipoArquivo.value == '1' ? form.IdTipoContrato.value : form.IdTipoDocumento.value;
                                        var type = returnTypeFile(form.arquivoNovo.value[0].name) || '';
                                        form.Lista.value.push({
                                            name: form.arquivoNovo.value[0].name,
                                            extensao: type, tipo: tipoDoc,
                                            idTipo: idTipoDoc,
                                            arquivo: form.arquivoNovo.value[0],
                                            id: count,
                                            idTipoArquivo: form.IdTipoArquivo.value,
                                            tipoArquivo: form.IdTipoArquivo.value == '1' ? 'Contrato' : 'Outros Documentos',
                                            modeloContrato: isNullOrZeroOrUndefined(form.IdModeloContrato.value) ? ' --- ' : document.getElementById('IdModeloContrato').options[document.getElementById('IdModeloContrato').selectedIndex].text,
                                            idModeloContrato: isNullOrZeroOrUndefined(form.IdModeloContrato.value) ? 0 : form.IdModeloContrato.value,
                                            dataValidadeInicio: isNullOrEmptyOrWhiteSpaceOrUndefined(form.DataValidadeInicioArquivo.value) ? ' --- ' : form.DataValidadeInicioArquivo.value,
                                            dataValidadeFim: isNullOrEmptyOrWhiteSpaceOrUndefined(form.DataValidadeFimArquivo.value) ? ' --- ' : form.DataValidadeFimArquivo.value,
                                            prazoIndeterminado: form.PrazoIndeterminadoArquivo.value == true ? 1 : 0,
                                            descricao: isNullOrEmptyOrWhiteSpaceOrUndefined(form.DescricaoArquivo.value) ? ' --- ' : form.DescricaoArquivo.value,
                                            idPessoa: form.IdPessoa.value,
                                        });
                                        form.IdModeloContrato.value = 0;
                                        document.getElementById("IdModeloContrato").selectedIndex = 0;
                                        form.IdTipoContrato.value = 0;
                                        document.getElementById("IdTipoContrato").selectedIndex = 0;
                                        form.IdTipoDocumento.value = 0;
                                        document.getElementById("IdTipoDocumento").selectedIndex = 0;
                                        form.DataValidadeFimArquivo.value = '';
                                        form.DataValidadeInicioArquivo.value = '';
                                        form.PrazoIndeterminadoArquivo.value = 0;
                                        form.IdTipoArquivo.value = 0;
                                        form.DescricaoArquivo.value = '';

                                        if (form.IdTipoConvenio.value == 2) {
                                            var countArquivo = 0;
                    
                                            if (form.Lista.value.length > 0) {
                                                form.Lista.value.forEach(element => {
                                                    if (element.idTipo === idTipoDoc && element.idPessoa === form.IdPessoa.value) {
                                                        //tipo contrato social/estatuto somente para pessoa juríca
                                                        if (element.idTipo == 4 && form.IdPessoa.value !=  form.IdEmpresa.value) { //form.razaoSocial.value
                                                            form.Lista.value.pop();
                                                            toast.error(`Tipo contrato social/estatuto somente para Pessoa Jurídica.`)
                                                        }
                                                        // tipo CPF ou RG somente para pessoa física
                                                        if ((element.idTipo == 5 || element.idTipo == 6) && form.IdPessoa.value == form.IdEmpresa.value) { //form.razaoSocial.value
                                                            form.Lista.value.pop();
                                                            toast.error(`Tipo CPF ou RG somente para Pessoa Física.`)
                                                        }
                                                        countArquivo++;
                                                    }
                                                    if (countArquivo > 1) {
                                                        form.Lista.value.pop();
                                                        toast.error(`Arquivo já adicionado para o responsável selecionado.`)
                                                    }
                                                });
                                            }
                                        }    

                                        this.setState({ form });
                                        // document.getElementById('basic-addon5').click();
                                    }
                                }
        this.handleDrop();
    }

    handleDrop() {
        document.getElementById('idMultiplosArquivos').value = '';
        document.getElementById('basic-addon3').innerHTML = "Buscar";
        document.getElementById('inputGroupFile012').innerHTML = "Selecione um arquivo";
    }

    onClickExcluir = async (event) => {
        const { form } = this.state;
        if (event.currentTarget.accessKey !== undefined &&
            event.currentTarget.accessKey !== null &&
            event.currentTarget.accessKey !== '' &&
            event.currentTarget.accessKey !== '0') {
            var index = form.Lista.value.map(function (o) { return o.id; }).indexOf(parseInt(event.currentTarget.accessKey));
            form.Lista.value.splice(index, 1);
            this.setState({ form });
        }
    }
    /*multiupload*/

    _handleTipoDocumentoChange = async (event) => {
        const { form } = this.state;
        form.IdTipoDocumento.value = event.target.value;
        this.setState({ form });
    }
    _handleRepresentanteChange = async (event) => {
        const { form } = this.state;
        form.IdPessoa.value = event.target.value;
        this.setState({ form });
    }
    _handleTipoContratoChange = async (event) => {
        const { form } = this.state;
        form.IdTipoContrato.value = parseInt(event.target.value);
        this.setState({ form, IdTipoContrato: event })
    }
    _handleModeloContratoChange = async (event) => {
        const { form } = this.state;
        form.IdModeloContrato.value = parseInt(event.target.value);
        this.setState({ form, IdModeloContrato: event })
    }
    handleChangeAprovarReprovarSolicitacao = async (event) => {
        const { form } = this.state;
        if (parseInt(event.target.value) == 2) {
            form.DataValidadeInicio.value = "";
            form.DataValidadeFim.value = "";
            form.PrazoIndeterminado.value = 0;
        }
        form.IdAprovarSolicitacao.value = parseInt(event.target.value);
        this.setState({ form })
    }
    handleChangePrazoIndeterminado = async (event) => {
        const { form } = this.state;
        if (parseInt(event.target.value) == 1) {
            form.DataValidadeInicio.value = "";
            form.DataValidadeFim.value = "";
        }
        form.PrazoIndeterminado.value = parseInt(event.target.value);
        this.setState({ form })
    }
    handleChangePrazoIndeterminadoArquivo = async (event) => {
        const { form } = this.state;
        if (parseInt(event.target.value) == 1) {
            form.DataValidadeInicioArquivo.value = "";
            form.DataValidadeFimArquivo.value = "";
        }
        form.PrazoIndeterminadoArquivo.value = parseInt(event.target.value);
        this.setState({ form })
    }

    _handlePercentualDescontoEADChange = async (event) => {
        const { form } = this.state;
        form.IdPercentualDescontoEAD.value = event.target.value;
        var select = document.getElementById('IdPercentualDescontoEAD');
        form.PercentualDescontoEAD.value = select.options[select.selectedIndex].text.replace('%', '');
        this.setState({ form });
    }
    _handlePercentualDescontoPresencialChange = async (event) => {
        const { form } = this.state;
        form.IdPercentualDescontoPresencial.value = event.target.value;
        var select = document.getElementById('IdPercentualDescontoPresencial');
        form.PercentualDescontoPresencial.value = select.options[select.selectedIndex].text.replace('%', '');
        this.setState({ form });
    }

    _onSubmit = async (values, valid) => {
        if (valid[0]) {
            const { form } = this.state;
            this.setState({ loading: true });

            if (form.Lista.value.length == 0 && form.IdAprovarSolicitacao.value == 1) {
                toast.warn("Adicione um item na lista de arquivos.");
            }
            else {
                if (form.IdAprovarSolicitacao.value == 2 && isNullOrEmptyOrWhiteSpaceOrUndefined(form.Descricao.value)) {
                    toast.warn('Informe a justificativa da recusa da solicitação.');
                }
                else {
                    for (var index = 0; index < form.Lista.value.length; index++) {
                        let doc = new FormData();
                        var element = form.Lista.value[index];
                        doc.append('File', element.arquivo);
                        doc.append('isContrato', element.idTipoArquivo);
                        doc.append('Tipo', element.idTipoArquivo == '1' ? 1 : parseInt(element.idTipo));
                        doc.append('IdSolicitacao', form.IdSolicitacao.value);
                        doc.append('IdModeloContrato', element.idModeloContrato);
                        doc.append('IdPessoa', null);
                        doc.append('Descricao', element.descricao);
                        doc.append('DataInicioVigencia', element.dataValidadeInicio);
                        doc.append('DataFimVigencia', element.dataValidadeFim);
                        doc.append('PrazoIndeterminado', element.prazoIndeterminado)

                        const returnContrato = await solicitacaoService.OnPostMultUpload(doc);
                    }

                    let model = {
                        IdSolicitacao: form.IdSolicitacao.value,
                        IdEmpresa: form.IdEmpresa.value,
                        IdSituacaoSolicitacao: form.IdAprovarSolicitacao.value == '1' ? 4 : 5,
                        Ativo: form.IdAprovarSolicitacao.value == '1' ? 1 : 0,
                        Excluido: form.IdAprovarSolicitacao.value == '1' ? 0 : 1,
                        Descricao: form.Descricao.value,
                        DataInicial: form.DataValidadeInicio.value,
                        DataFinal: form.DataValidadeFim.value,
                        PrazoIndeterminado: form.PrazoIndeterminado.value == true ? 1 : 0,
                        PercentualDescontoEAD: form.PercentualDescontoEAD.value,
                        PercentualDescontoPresencial: form.PercentualDescontoPresencial.value,
                        IdPercentualDescontoEAD: form.IdPercentualDescontoEAD.value,
                        IdPercentualDescontoPresencial: form.IdPercentualDescontoPresencial.value,
                        AprovandoReprovando: form.IdAprovarSolicitacao.value == '1' ? 0 : 1,
                        IdTipoConvenio: form.IdTipoConvenio.value,
                        ConvenioPagamentoEmpresa: form.IdTipoConvenio.value == 2 ? true : 1,
                    };
                    const returnContrato = await solicitacaoService.OnPostAlterarSituacao(model);
                    if (returnContrato.success == true) {
                        toast.success("Arquivos enviados com sucesso.");
                        document.getElementById('btn-buscar').click()
                        document.getElementById('btnCancelar').click();
                    }
                }
            }
            this.setState({ form, loading: false });
        }
        else {
            toast.warn("Vefique os campos.");
        }
    }

    render() {
        const { form } = this.state;

        const todoItems = form.Lista.value.map((todo) =>
            <tr className="" >
                <td className="" title={todo.name}>{todo.name.length > 15 ? todo.name.substring(0, 14) + '...' : todo.name}</td>
                <td className="">{todo.extensao}</td>
                <td className="">{todo.tipoArquivo}</td>
                <td className="">{todo.tipo}</td>
                <td className="">{todo.modeloContrato}</td>
                <td className="">{todo.dataValidadeInicio + " até " + todo.dataValidadeFim}</td>
                <td className="" title={todo.descricao}>{descricaoMax15(todo.descricao)}</td>
                <td className=""><i className="fa fa-times-circle" aria-hidden="true" onClick={this.onClickExcluir} accessKey={todo.id}></i></td>
            </tr>
        );
        return (
            <>
                <div className="uninter-box ">
                    <div className="row">
                        <div className="uninter-passo-geral col-xs-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
                            <Form onSubmit={this._onSubmit} formControl={form} onChangeForm={this._onChangeForm} onValid={this._onValid}>
                                <h4 className="titleFormulario">{form.NomeFantasia.value} ({form.CNPJ.value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")})</h4>
                                <div className="row">
                                    <ul className="uninter-declaro-convenioempresas uninter-desconto-pagamento">
                                        <span className="aprovacao-solicitacao">Aprovação da Solicitação</span>
                                        <li className="form-check">
                                            <input type="radio" id="aprovar" name="IdAprovarSolicitacao" value="1" onClick={this.handleChangeAprovarReprovarSolicitacao} />
                                            <label className="form-check-label ">Aprovar</label>
                                            <input type="radio" id="reprovar" name="IdAprovarSolicitacao" value="2" onClick={this.handleChangeAprovarReprovarSolicitacao} />
                                            <label className="form-check-label">Reprovar</label>
                                            <br />
                                            <span className="uninter-error" id="IdAprovarSolicitacaoError"></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="uninter-box-interno" >
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="uninter-box-interno-periodo-vigencia"><span className="title">Período de Vigência</span></div>
                                                <div className="uninter-box-interno-prazo-indeterminado">
                                                    <input type="checkbox" id="PrazoIndeterminado" name="PrazoIndeterminado" value="1" onClick={this.handleChangePrazoIndeterminado} disabled={form.IdAprovarSolicitacao.value == 2} />
                                                    <label className="label">Prazo Indeterminado</label>
                                                </div>
                                            </div>
                                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                <label>Data Início</label>
                                                <input type="date" id="DataValidadeInicio" name="DataValidadeInicio" max="9999-12-31" disabled={form.PrazoIndeterminado.value == 1 || form.IdAprovarSolicitacao.value == 2} />
                                                <span className="uninter-error" id="DataValidadeInicioError"></span>
                                            </div>
                                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                <label>Data Fim</label>
                                                <input type="date" id="DataValidadeFim" name="DataValidadeFim" max="9999-12-31" disabled={form.PrazoIndeterminado.value == 1 || form.IdAprovarSolicitacao.value == 2} />
                                                <span className="uninter-error" id="DataValidadeFimError"></span>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                <span className="uninter-error" id="PrazoIndeterminadoError"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="uninter-box-interno" hidden={form.IdTipoConvenio.value == 2}>
                                    <div className="row" >
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="uninter-box-interno-desconto"><span className="title">Percentual Desconto Eja/Graduação/Pós EAD: 10%</span></div>
                                                <div className="uninter-box-interno-prazo-indeterminado">
                                                    <input type="checkbox" id="OutroPercentualDescontoEAD" name="OutroPercentualDescontoEAD" value="1" onClick={this.handleChangeOutroPercentualDescontoEAD} disabled={form.IdAprovarSolicitacao.value == 2} />
                                                    <label className="label">Alterar</label>
                                                </div>
                                            </div>
                                            <div className="row" hidden={form.OutroPercentualDescontoEAD.value == 0}>
                                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1 uninter-campos">
                                                    <label>Percentual de Desconto</label>
                                                    <Combobox onChange={this._handlePercentualDescontoEADChange} opstions={form.listPercentDescount.value} id={"IdPercentualDescontoEAD"} name={"IdPercentualDescontoEAD"}></Combobox>
                                                    <span className="error" id="IdPercentualDescontoEADError"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div><br></br>
                                <div className="uninter-box-interno" hidden={form.IdTipoConvenio.value == 2}>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="uninter-box-interno-desconto"><span className="title">Percentual Desconto Graduação/Pós Presencial/Semipresencial: 10%</span></div>
                                                <div className="uninter-box-interno-prazo-indeterminado">
                                                    <input type="checkbox" id="OutroPercentualDescontoPresencial" name="OutroPercentualDescontoPresencial" value="1" onClick={this.handleChangeOutroPercentualDescontoPresencial} disabled={form.IdAprovarSolicitacao.value == 2} />
                                                    <label className="label">Alterar</label>
                                                </div>
                                            </div>
                                            <div className="row" hidden={form.OutroPercentualDescontoPresencial.value == 0 || form.IdTipoConvenio.value == 2}>
                                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1 uninter-campos">
                                                    <label>Percentual de Desconto</label>
                                                    <Combobox onChange={this._handlePercentualDescontoPresencialChange} opstions={form.listPercentDescount.value} id={"IdPercentualDescontoPresencial"} name={"IdPercentualDescontoPresencial"}></Combobox>
                                                    <span className="error" id="IdPercentualDescontoPresencialError"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                        <label>{form.IdAprovarSolicitacao.value == 2 ? 'Justificativa' : 'Descrição'}</label>
                                        <textarea rows="5" id="Descricao" name="Descricao" className="form-control" maxLength="500"></textarea>
                                        <span className="error" id="DescricaoError"></span>
                                    </div>
                                </div>
                                <div className="uninter-box-convenioempresas-curso uninter-container-convenioempresas botton-px-20 uninter-box-upload-completo">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <center><span>Para inserir contrato e outros documentos, favor selecionar o arquivo abaixo e .</span></center>
                                            <div className="row">
                                                <ul className="uninter-declaro-convenioempresas uninter-desconto-pagamento">
                                                    <li className="form-check">
                                                        <input type="radio" id="contrato" name="IdTipoArquivo" value="1" disabled={form.IdTipoConvenio.value == 2} />
                                                        <label className="form-check-label ">Contrato</label>
                                                        <input type="radio" id="documento" name="IdTipoArquivo" value="2" />
                                                        <label className="form-check-label checkPagamento">Outros Documentos</label><br />
                                                    </li>
                                                    <span className="error" id="IdTipoArquivoError"></span>
                                                </ul>
                                            </div>
                                            <div className="uninter-box-interno">
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                            <div className="uninter-box-interno-periodo-vigencia"><span className="title">Período de Vigência</span></div>
                                                            <div className="uninter-box-interno-prazo-indeterminado">
                                                                <input type="checkbox" id="PrazoIndeterminadoArquivo" name="PrazoIndeterminadoArquivo" value="1" onClick={this.handleChangePrazoIndeterminadoArquivo} />
                                                                <label className="label">Prazo Indeterminado</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                            <label>Data Início</label>
                                                            <input type="date" id="DataValidadeInicioArquivo" name="DataValidadeInicioArquivo" max="9999-12-31" disabled={form.PrazoIndeterminadoArquivo.value == 1} />
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                            <label>Data Fim</label>
                                                            <input type="date" id="DataValidadeFimArquivo" name="DataValidadeFimArquivo" max="9999-12-31" disabled={form.PrazoIndeterminadoArquivo.value == 1} />
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                            <span className="uninter-error" id="PrazoIndeterminadoArquivoError"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" hidden={form.IdTipoArquivo.value == 0 || form.IdTipoArquivo.value == 1}>
                                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1 uninter-campos">
                                                    <label>Selecione Tipo de Documento</label>
                                                    <Combobox onChange={this._handleTipoDocumentoChange} opstions={form.listTiposDocumentos.value} id={"IdTipoDocumento"} name={"IdTipoDocumento"}></Combobox>
                                                    <span className="error" id="IdTipoDocumentoError"></span>
                                                </div>
                                            </div>

                                            <div className="row" hidden={form.IdTipoConvenio.value == 1 || form.IdTipoArquivo.value == 0}>
                                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1 uninter-campos" >
                                                    <label>Selecione o responsável pelo envio dos documentos obrigatórios</label>
                                                    <Combobox onChange={this._handleRepresentanteChange} opstions={form.listResponsaveis.value} id={"IdPessoa"} name={"IdPessoa"}></Combobox>
                                                </div>
                                            </div>
                                            <div className="row" hidden={form.IdTipoArquivo.value == 0 || form.IdTipoArquivo.value == 2 || form.IdTipoConvenio.value == 2}>
                                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1 uninter-campos">
                                                    <label>Selecione Tipo de Contrato</label>
                                                    <Combobox onChange={this._handleTipoContratoChange} opstions={form.listTiposContrato.value} id={"IdTipoContrato"} name={"IdTipoContrato"}></Combobox>
                                                    <span className="error" id="IdTipoContratoError"></span>
                                                </div>
                                            </div>
                                            <div className="row" hidden={form.IdTipoArquivo.value == 0 || form.IdTipoArquivo.value == 2 || form.IdTipoConvenio.value == 2}>
                                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1 uninter-campos">
                                                    <label>Selecione Modelo de Contrato</label>
                                                    <Combobox onChange={this._handleModeloContratoChange} opstions={form.listModelos.value} id={"IdModeloContrato"} name={"IdModeloContrato"}></Combobox>
                                                    <span className="error" id="IdModeloContratoError"></span>
                                                </div>
                                            </div>
                                            <div className="row" hidden={form.IdTipoConvenio.value == 2}>
                                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1 uninter-campos">
                                                    <label>Descrição</label>
                                                    <textarea rows="2" id="DescricaoArquivo" name="DescricaoArquivo" className="form-control" maxLength="500"></textarea>
                                                    <span className="error" id="DescricaoArquivoError"></span>
                                                </div>
                                            </div>
                                            {form.IdTipoArquivo.value > 0 && (
                                                <div className="row botton-px-20">
                                                    <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
                                                        <input type="file" className="custom-file-input" id={"idMultiplosArquivos"} name='arquivo' aria-describedby="inputGroupFileAddon02" onChange={(e) => this.handleChangeMultiInputFile(e.target.files)}
                                                            multiple={false} ref={this.multifileInputRef} hidden={true} accept="application/pdf,image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" name={this.props.name} />
                                                        <br />
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text label-file" id="inputGroupFileAddon02" id="basic-addon2">{"Arquivo"}</span>
                                                            </div>
                                                            <label type="text" className="form-control label-file input-white-file" aria-label="Large" id="inputGroupFile012" aria-describedby="basic-addon2 basic-addon3">{"Selecione um arquivo"}</label>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text label-file search-file" id="basic-addon3" onClick={(e) => this.handleClick(e.target.files)}>{"Buscar"}</span>
                                                            </div>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text label-file search-file btn-adicionar" id="basic-addon4" onClick={(e) => this.addClick(e.target.files)}>{"Adicionar"}</span>
                                                            </div>
                                                            {/* <div className="input-group-append">
                                                                <span className="input-group-text label-file drop-file" id="basic-addon5" onClick={ (e) => this.handleDrop()}><i className="fa fa-times" aria-hidden="true"></i></span>
                                                            </div>   */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className="uninter-box">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
                                            {this.state.lista}
                                        </div>
                                    </div>

                                    <span className="error" id="ListaError"></span>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <table className="table uninter-table-documentos">
                                            <thead>
                                                <tr>
                                                    <th className="primeira">Nome </th>
                                                    <th className="other">Extensão </th>
                                                    <th className="other">Tipo Arquivo </th>
                                                    <th className="other">Tipo Documento </th>
                                                    <th className="other">Modelo Contrato </th>
                                                    <th className="other">Período de Validação </th>
                                                    <th className="other">Descrição/Justificativa </th>
                                                    <th className="ultima">Excluir</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {todoItems}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <center>
                                        <input className="uninter-box-home-convenioempresas-btn-enviar-formulario" type="submit" value="Enviar" />
                                    </center>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}