import React, { Component } from 'react'

export default class AcessoNegado extends Component {
    render() {
        return (
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-2 text-center">
                        <p><i className="fa fa-exclamation-triangle fa-5x"></i><br/>Código: 403</p>
                    </div>
                    <div className="col-md-10">
                        <h3>OPPSSS!!!! Desculpe, mas você está sem permissão para acessar esta funcionalidade.</h3>
                        <p>Para solicitar acesso a ferramenta, entre em contato com o ServiceDesk atraves da ferramenta do Univirtus.<br/></p>
                        <a className="btn btn-danger" href="javascript:history.back()">Voltar</a>
                    </div>
                </div>
            </div>
        )
    }
}
