import React, { Component } from 'react';
import enviromment from '../../environment';
import '../../containers/Default/Default.css';
import SolicitacaoService from '../../services/SolicitacaoService';
import Loading from '../../components/Loading';
import BreadCrumb from '../../components/BreadCrumb';
import Table from '../../components/Table';
import { Form } from 'react-simple-form-control'
import { Link } from 'react-router-dom';
import { Modal } from '../../components'
import { toast } from 'react-toastify';

export default class CancelarLink extends Component {


    constructor(props) {
        super(props);
        this.state = {
            valid: false,
            error: '',
            data: [],
            form: {
                IdEmpresa: {
                    value: props.IdEmpresa || '',
                },
                IdSolicitacao: {
                    value: props.IdSolicitacao || '',
                },
                Descricao: {
                    value: props.Descricao || '',
                    validation: {
                        output: "descricaoError",
                        validators: [
                            {
                                type: 'required',
                                msg: "O campo justificativa é obrigatório."
                            },
                            {
                                type: 'custom',
                                function: (value) => {
                                    value = value.trim();
                                    return value.length > 0;
                                },
                                msg: "O campo justificativa é obrigatório."
                            }
                ]
                    }
                },
            }
        }
        this._onChangeForm = this._onChangeForm.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    load = async () => {
        this.setState({ loading: true })
        const response = await SolicitacaoService.listAll();
        this.setState({ data: response.data, loading: false })
    }

    _onSubmit = async (values, valid) => {
        const { form } = this.state;
        if (valid[0]) {
            try {
                let model = {
                    IdSolicitacao: form.IdSolicitacao.value,
                    IdEmpresa: form.IdEmpresa.value,
                    IdSituacaoSolicitacao: 5,
                    Ativo: 0,
                    Excluido: 1,
                    Descricao:form.Descricao.value
                };
                const alterarSituacao = await SolicitacaoService.OnPostAlterarSituacao(model);
                if (alterarSituacao.success == true) {
                    toast.success("Solicitação cancelada");
                    document.getElementById('btn-buscar').click()
                    document.getElementById('btnCancelar').click();
                }
                document.getElementById('btnCancelar').click();
            } catch (error) {
            }
        }
    }

    async _onChangeForm(form) {
        this.setState({ form })
    }

    _onValid = valid => this.setState({ valid })

    render() {
        const { form, valid } = this.state;

        return (
            <>
                {
                    this.state.loading &&
                    Loading
                }
                {
                    <>
                        <div className="row">
                            <div className="uninter-passo-geral col-xs-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
                                <Form onSubmit={this._onSubmit} formControl={form} onChangeForm={this._onChangeForm} onValid={this._onValid}>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                        <label>Justificativa</label>
                                        <textarea rows="5" id="Descricao" name="Descricao" className="form-control" maxLength="500"></textarea>
                                        <span className="error" id="descricaoError"></span>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <center>
                                            <input className="uninter-box-home-convenioempresas-btn-enviar-formulario" type="submit" value="CANCELAR SOLICITAÇÃO" />                                            
                                        </center>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}
