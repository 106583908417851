import React, { Component }  from 'react';
import imgBanner from '../../assets/_images/EmpresaConvenio/logo-uninter.png';
import './EmpresaConvenio.css'
export default class Topo extends Component {
    render() {
      return <header>
                <nav className="navbar navbar-expand-lg backgroundHeader" id="header" role="heading">
                    <a className="uninter-topo-convenioempresas navbar-brand d-none d-sm-none d-md-flex d-lg-flex d-xl-flex" href="https://www.uninter.com/">
                        <img alt="Logo oficial da Uninter" src={imgBanner}/>
                    </a>
                </nav>
             </header>
    }
  }