import AuthService from "./AuthService";
import AuthApi from "./AuthApi";

export default class AuthRUService extends AuthService{
    static async login(form) {
        try {
            let res = await AuthApi.post(`/v1/authenticate/ru`, form, {
                withCredentials: false
            });
            let authorization = res.data.data;
            if (authorization && authorization.token)
                this._saveCredentials(authorization);
            return true;
        } catch (error) {
            console.log('permission rejected');
            return false
        }
    }

    static async refreshCredentials() {
        window.location = '/login';
    }
}
