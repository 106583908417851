import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export default function ModalAlert(props){
    return (<Modal show={props.show} animation={false}>
        <Modal.Header>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           {props.children} 
        </Modal.Body>
        <Modal.Footer>
            {props.onCancel && <Button variant="secondary" onClick={() => props.onCancel()}>{props.onCancelText}</Button>}
            {props.onConfirm && <Button variant="primary" onClick={() => props.onConfirm()}>{props.onConfirmText}</Button>}
        </Modal.Footer>
    </Modal>);
}