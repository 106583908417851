
import enviromment from '../../environment';
import AuthApi from "./AuthApi";
import Modal from '../../components/Modal'
const keys = {
    tokenName: 'uninterToken_' + enviromment.ambiente,
    userName: 'uninterUser_' + enviromment.ambiente,
    menuName: 'uninterMenu_' + enviromment.ambiente,
    sistemaName: 'uninterSistema_' + enviromment.ambiente,
}

export default class AuthService {


    static logout() {
        //localStorage.clear();
        localStorage.removeItem("uninterMenu_" + enviromment.ambiente);
        localStorage.removeItem("uninterUser_" + enviromment.ambiente);
        localStorage.removeItem("uninterToken_" + enviromment.ambiente);
        localStorage.removeItem("uninterSistema_" + enviromment.ambiente);
    }

    static isLoggedIn() {
        let currentToken = this.getToken();
        if (currentToken === null || currentToken === undefined)
            return false;
        let user = this.getValue(keys.userName);
        if (user === null || user === undefined)
            return false;
        if (user.matricula === null && user.ru === null)
            return false;

        return true;
    }

    static _saveCredentials(authorization) {
        let user = {
            matricula: authorization.user.matricula,
            ru: authorization.user.ru,
            email: authorization.user.email,
            nome: authorization.user.nome
        }

        this.setToken(authorization.token);
        this.setValue(keys.userName, user);
        this.setMenu(authorization.user.menus);
        this.setSistemas(authorization.user.sistemas);
    }

    static setValue(name, value) {
        localStorage.setItem(name, JSON.stringify(value));
    }

    static setToken(value) {
        localStorage.setItem(keys.tokenName, JSON.stringify(value));
    }

    static setMenu(value) {
        localStorage.setItem(keys.menuName, JSON.stringify(value));
    }

    static setSistemas(value) {
        localStorage.setItem(keys.sistemaName, JSON.stringify(value));
    }

    static getValue(value) {
        let res = localStorage.getItem(value);
        if (res === null || res === undefined)
            return null;
        return JSON.parse(res);
    }

    static getToken() {
        let token = localStorage.getItem(keys.tokenName);
        if (token === null || token === undefined)
            return null;
        return JSON.parse(token);
    }

    static getMenu() {
        let menu = localStorage.getItem(keys.menuName);
        if (menu === null || menu === undefined)
            return [];
        return JSON.parse(menu);
    }

    static getUserLogged() {
        let user = localStorage.getItem(keys.userName);
        if (user === null || user === undefined)
            return null;
        return JSON.parse(user);
    }

    static getSistemas() {
        let sistemas = localStorage.getItem(keys.sistemaName);
        if (sistemas === null || sistemas === undefined)
            return [];
        return JSON.parse(sistemas);
    }

    static verifyMenuAcesso(url) {
        let menu = this.getMenu();
        if (menu === null)
            return false;

        //Remove a /
        url = url.substring(1, url.length);

        let acesso = menu.map((x) => {
            if (x.filhos.length > 0)
                return x.filhos.map((x) => x.url);
            return [x.url];
        });
        let permitido = acesso.find((x) => x.find((y) => y === url));
        return (permitido === undefined || permitido === null) ? false : true;
    }

    static verifyMenuAcessoByCodigo(menuCodigo) {
        let menu = this.getMenu();
        if (menu === null)
            return false;

        let acesso = menu.map((x) => {
            if (x.filhos.length > 0)
                return x.filhos.map((x) => x.codigoReferencia);
            return [x.codigoReferencia];
        });
        let permitido = acesso.find((x) => x.find((y) => y === menuCodigo));
        return (permitido === undefined || permitido === null) ? false : true;
    }

    static verifyPrivilegio(menuCodigo, privilegioCodigo) {
        if (!menuCodigo || !privilegioCodigo)
            return false;

        let menu = this.getMenu();
        if (menu === null)
            return false;

        //Busca menus secundarios
        let res = menu.filter((x) => x.filhos.some((y) => y.codigoReferencia === menuCodigo && y.privilegios.some((z) => z.codigoReferencia === privilegioCodigo)))
            .map((x) => x === [] ? false : true);
        if (res[0] === true)
            return true;

        //Busca menus primarios
        res = menu.filter((x) => x.codigoReferencia === menuCodigo && x.privilegios.some((z) => z.codigoReferencia === privilegioCodigo))
            .map((x) => x === [] ? false : true);
        if (res[0] === true)
            return true;

        return false;
    }
}
