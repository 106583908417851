import axios from "axios";
import Auth from "./Auth";
import enviromment from '../environment';
import { toast } from 'react-toastify';

const BaseApi = axios.create({
  baseURL: enviromment.api
});

BaseApi.interceptors.request.use(async config => {
  const token = Auth.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    
     if(config.url == '/v1/Contrato/Upload' || 
        config.url == '/v1/Contrato/MultUpload'|| 
        config.url == '/v1/Aluno/importarAlunosEmMassa')
       config.headers.ContentType = `multipart/form-data`;  
  }
  else{ 
     if(config.url == '/v1/Contrato/Upload' ||  
        config.url == '/v1/Contrato/MultUpload' ||
        config.url == '/v1/Aluno/importarAlunosEmMassa')
       config.headers.ContentType = `multipart/form-data`;  
  }
  return config;
});

BaseApi.interceptors.response.use((response) => {
  return response.data;
}, (error) => {
  if (error.response && error.response.status === 401) {
    window.location.href = `${window.location.origin}/login?redirect=${encodeURIComponent(window.location.href.replace(window.location.origin, ''))}`;
  }
  if(!error.response)
      error = {response:{data:{data:"Falha ao se comunicar com a API."}}};
  if(error.response.data.errors && error.response.data.errors.length){
    for (let i = 0; i < error.response.data.errors.length; i++) {
      toast.error(error.response.data.errors[i]);
    }
  }else{
    toast.error(error.response.data.data);
  }
  return Promise.reject(error);
});

export default BaseApi;