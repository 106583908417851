export const environment = {
  production: false,
  web: 'https://localhost:4900/',
  api: 'https://localhost:59849/',  
  // api: 'https://empresaconvenioapidev.uninter.com/', //Mirian
  // api: 'http://localhost:63182/', //Cleverson
  ambiente: 'DEV',    
  apiAccount: 'https://accountapidev.uninter.com/',
 apiAccount: 'https://accountapidev.uninter.com/',
 webAccount: 'https://accountdev.uninter.com/'

};