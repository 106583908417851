import BaseApi from "./BaseApi";
export default class DocumentoService {

    static async post(command) {
        try {
            let res = await BaseApi.post(`v1/Documento/${command}`);
            return res.data;

        } catch (error) {
            return error.response.data;
        }
    }
    
    static async OnPostUpload(files) {
        try {
            debugger
            let res = await BaseApi.post(`/v1/Documento/Upload`,files);
            return res.data;

        } catch (error) {
            return error.response.data;
        }
    }

    static async listAll(IdSolicitacao) {
        try {
            let res = await BaseApi.get(`v1/Documento/GetAll/${IdSolicitacao}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async listDocType(form) {
        try {
            let res = await BaseApi.post(`v1/Documento/GetDocumentoTipo`, form);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async getDocumentoByCdDocumentoGerado(cdDocumentoGerado) {
        try {
            let res = await BaseApi.post(`/v1/Documento/GetDocumentoByCdDocumentoGerado/${cdDocumentoGerado}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnGetTipos(tipoConvenio) {
        try {
            let res = await BaseApi.get(`v1/Documento/GetTipo/${tipoConvenio}`);
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    }

    static async downloadFile(command) {
        try {
            let res = await BaseApi.get(`/v1/Documento/DownloadDocumento/${command}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async getDocumentoGeradoByCdCadastroEmpresa(command) {
        try {
            debugger
            let res = await BaseApi.post(`/v1/Documento/GetDadosDocumentoByCdCadastroEmpresa`, command);
            return res.data;

        } catch (error) {
            return error.response.data;
        }
    }
}