import React, { Component, useReducer } from 'react';
import enviromment from '../../environment';
import '../../containers/Default/Default.css';
import BreadCrumb from '../../components/BreadCrumb';
export default class Dashboard extends Component {
        render() {
        return (
            <>
                <div className="uninter-class-convenio-interno container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <span className="display-4 d-block">{enviromment.nome}</span>
                            <div className="mb-4 lead">Bem-vindo ao Portal Empresa Convênio</div>
                        </div>
                        <div className="col-md-12 ">
                        </div>
                    </div>
                </div>
            </>
        )
    }
}