import BaseApi from "./BaseApi";

export default class DepartamentoService {

    static async OnGetListaCodigoDescricao() {
        try {
            let res = await BaseApi.get(`/v1/Departamento/ListaCodigoDescricao`);
            return res.data;

        } catch (error) {
            return error.response.data;
        }
    }
}