import BaseApi from "./BaseApi";

export default class UsuarioService {

    static async OnPostInserir(model) {
        try {
            let res = await BaseApi.post(`/v1/Pessoa/Inserir`, model);
            return res;

        } catch (error) {
            return error.response.data;
        }
    }

    static async OnPutUsuario(model) {
        try {
            let res = await BaseApi.put(`/v1/Pessoa/Editar`, model);
            return res;

        } catch (error) {
            return error.response.data;
        }
    }

    static async list(IdEmpresa) {
        try {
            let res = await BaseApi.get(`v1/Pessoa/GetPessoas/${IdEmpresa}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async listFiltered(form) {
        try {
            let res = await BaseApi.post(`v1/Pessoa/Filter`, form);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnGetEmpresas() {
        try {
            let res = await BaseApi.get(`/v1/pessoa/GetEmpresas`);
            return res.data;

        } catch (error) {
            return error.response.data;
        }
    }

    static async OnStatusChange(model) {
        try {
            let res = await BaseApi.post(`/v1/Pessoa/AlterarStatus`, model);
            return res;

        } catch (error) {
            return error.response.data;
        }
    }

    static async OnGetPerfil() {
        try {
            let res = await BaseApi.get(`v1/Pessoa/GetPerfil`);
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnGetPessoaById(id, IdEmpresa) {
        try {
            let res = await BaseApi.get(`v1/Pessoa/GetPessoaById/${id}/${IdEmpresa}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnGetIsExistis() {
        try {
            let res = await BaseApi.get(`v1/Pessoa/IsExistis`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnDesvincularUsuarioEmpresa(idEmpresa, idPessoa) {
        try {
            let res = await BaseApi.delete(`v1/Pessoa/DesvincularUsuarioEmpresa/${idEmpresa}/${idPessoa}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnReenviarEmailNovoUsuario(email, ru) {
        try {
            let res = await BaseApi.post(`v1/Pessoa/ReenviarEmailNovoUsuario/${email}/${ru}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnReenviarEmailRepresentantes(idEmpresa) {
        try {
            let res = await BaseApi.post(`v1/Pessoa/ReenviarEmailRepresentantes/${idEmpresa}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnGetPerfilUsuario() {
        try {
            let res = await BaseApi.get(`v1/Pessoa/GetPerfilUsuario`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }
    static async OnGetUsuarioIsComercial() {
        try {
            let res = await BaseApi.get(`v1/Pessoa/GetUsuarioIsComercial`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnGetPermiteEmailNota(idEmpresa) {
        try {
            let res = await BaseApi.get(`v1/Pessoa/GetPermiteEmailNota/${idEmpresa}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnGetPermiteEmailBoleto(idEmpresa) {
        try {
            let res = await BaseApi.get(`v1/Pessoa/GetPermiteEmailBoleto/${idEmpresa}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }
}