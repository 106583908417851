import React, { Component } from 'react';
import ModalAlert from '../ModalAlert'
export default class Modal extends Component {
    constructor(props){
        super(props);
        Modal.__singletonRef = this;
        this.state = {
            show: false,
            title: '',
            body: null,
            showConfirm: true, 
            showCancel: false,
            callBack: null
        }
    }
    render(){
        return (<ModalAlert 
                    show={this.state.show} 
                    title={this.state.title} 
                    onConfirm={(this.state.showConfirm ? Modal.onConfirm : null)} 
                    onCancel={(this.state.showCancel ? Modal.onCancel : null)}
                    onConfirmText="OK"
                    onCancelText="Cancelar">
                        {this.state.body}
                </ModalAlert>);
    }
    static show(title, body, showConfirm = true, showCancel = false, callBack = null) {
        console.log('show',Modal.__singletonRef);
        Modal.__singletonRef.__show(title, body, showConfirm, showCancel, callBack);
    }
  
    static onConfirm() {
        Modal.__singletonRef.__onConfirm();
    }

    static onCancel() {
        Modal.__singletonRef.__onCancel();
    }
  
    __show(title, body, showConfirm, showCancel, callBack) { 
        console.log('_show',this);
        this.setState({ title, body, show: true, showConfirm, showCancel, callBack});
    }
    __onConfirm() { 
        this.state.callBack && this.state.callBack();
        this.setState({ title: '', body: null, show: false, showConfirm: false, showCancel: false});
    }
    __onCancel() { 
        this.setState({ title: '', body: null, show: false, showConfirm: false, showCancel: false});
    }
}
