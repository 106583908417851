import BaseApi from "./BaseApi";

export default class AssinaturaContratoService {

    static async OnGetAssinaturas() {
        try {
            let res = await BaseApi.get(`/v1/AssinaturaContrato/BuscaAssinaturas`);
            return res.data;

        } catch (error) {
            return error.response.data;
        }
    }
    
    static async OnGetPermiteAdicionarAssinante(IdEmpresa) {
        try {
            let res = await BaseApi.get(`v1/AssinaturaContrato/PermiteAdicionarAssinante/${IdEmpresa}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    /* Busca as empresas que o usuario logado tem contrato para assinar */
    static async OnGetListaEmpresasParaAssinatura() {
        try {
            let res = await BaseApi.get(`/v1/AssinaturaContrato/BuscaEmpresasParaAssinatura`);            
            return res.data;

        } catch (error) {
            return error.response.data;
        }
    }
    
    /* Busca todas as empresas que o usuario logado tem contrato*/
    static async OnGetListaEmpresas() {
        try {
            debugger
            let res = await BaseApi.get(`/v1/AssinaturaContrato/BuscaEmpresas`);
            debugger
            return res.data;

        } catch (error) {
            return error.response.data;
        }
    }

    static async OnInsert(form) {
        try {
            let res = await BaseApi.post(`v1/AssinaturaContrato/Insert`, form);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }
}