import BaseApi from "./BaseApi";
export default class EmpresaService {

    static async getEmpresaCnpj(im) {
        try {
            let res = await BaseApi.get(`v1/getCnpjAx/${im}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }
    static async OnGetListaCodigoDescricao(idTipoConvenio) {
        try {
            let res = await BaseApi.get(`/v1/Empresa/ListaCodigoDescricao/${idTipoConvenio}`);
            return res.data;

        } catch (error) {
            return error.response.data;
        }
    }

    static async OnGetListaEmpresaContrato() {
        try {
            let res = await BaseApi.get(`/v1/Empresa/ListaEmpresaContrato`);
            return res.data;

        } catch (error) {
            return error.response.data;
        }
    }

    static async GetDadosEmpresa(idEmpresa) {
        try {
            let res = await BaseApi.get(`v1/Empresa/GetDadosEmpresa/${idEmpresa}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async GetTituloAtivoEmpresa(idAlunoEmpresa) {
        try {
            let res = await BaseApi.get(`v1/Empresa/GetTituloAtivoEmpresa/${idAlunoEmpresa}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }
}