import React, { Component } from 'react';
import { Form, getFormValues } from 'react-simple-form-control'
import './Login.scss';
import '../../../assets/Geogrotesque/geofrotesque.css';
import Auth from '../../../services/Auth';
import enviromment from '../../../environment';

export default class LoginLDAP extends Component {

    constructor(props) {
        super(props);
        this.state = {
            valid: false,
            error: '',
            form: {
                matricula: {
                    value: '',
                    validation: {
                        output: "matriculaError",
                        validators: [{
                            type: "required",
                            msg: "Campo obrigatório."
                        }]
                    }
                },
                senha: {
                    value: '',
                    validation: {
                        output: "senhaError",
                        validators: [{
                            type: "required",
                            msg: "Campo obrigatório."
                        }]
                    }
                },
                sistema: {
                    value: enviromment.sistema,
                }
            }
        }
        this._onChangeForm = this._onChangeForm.bind(this);
    }

    _onSubmit = async (values, valid) => {
        if (valid[0]) {
            await Auth.login(values);
            this.props.history.push("/");
        }
    }

    async _onChangeForm(form) {
        this.setState({ form })
    }

    _onValid = valid => this.setState({ valid })

    render() {
        const { form } = this.state;
        return (
            <div className="container">
                <Form className="form-signin" onSubmit={this._onSubmit} formControl={form} onChangeForm={this._onChangeForm} onValid={this._onValid}>
                    <h2 className="form-signin-heading">Login - {enviromment.nome}</h2>
                    <label for="inputEmail" className="sr-only">Matricula</label>
                    <input type="text" id="inputMatricula" name="matricula" className="form-control" placeholder="Matricula" required="" autofocus=""/>
                    <label for="inputPassword" className="sr-only">Senha</label>
                    <input type="password" id="inputPassword" name="senha" className="form-control" placeholder="Senha" required=""/>
                    <button className="btn btn-lg btn-primary btn-block" type="submit">Login</button>
                </Form>
            </div>
        )
    }
}
