import React, { Component, useState } from 'react';
import enviromment from '../../environment';
import '../../containers/Default/Default.css';
import SolicitacaoService from '../../services/SolicitacaoService';
import DocumentoService from '../../services/DocumentoService';
import Loading from '../../components/Loading';
import BreadCrumb from '../../components/BreadCrumb';
import Table from '../../components/Table';
import { Form } from 'react-simple-form-control'
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CancelarLink from '../CancelarLink/CancelarLink';
import DocumentoLink from '../DocumentoLink/DocumentoLink';
import Contrato from '../Contrato/Contrato';
import cargoService from '../../services/CargoService';
import solicitacaoService from '../../services/SolicitacaoService';
import usuarioService from '../../services/UsuarioService';
import empresaService from '../../services/EmpresaService';
import combobox, { Combobox } from '../../components/Listas/Combobox';
import assinaturaContratoService from '../../services/AssinaturaContratoService'
import Select from 'react-select'

export default class Usuarios extends Component {
    constructor(props) {
        super(props);

        this.breads = [{
            name: 'Administração',
            url: '/administracao',
            active: true
        }, {
            name: 'Cadastro de Usuários',
            url: '/administracao/cadastroUsuarios',
            active: true
        }]

        this.state = {
            valid: true,
            loading: true,
            error: '',
            Filtro: [],
            data: [],
            documentos: [],
            form: {
                idEmpresa: {
                    value: props.idEmpresa || 0,
                    validation: {
                        output: "idEmpresaError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            }
                        ]
                    }
                },
                usuarioLogadoIsComercial: { value: props.usuarioLogadoIsComercial || false },
                addAssinanteContrato: { value: props.addAssinanteContrato || 0 },
                idPessoaAdmin: { value: props.idPessoaAdmin || 0 },
                idPessoaDCAdmin: { value: props.idPessoaDCAdmin || 0 },
                ruPessoaAdmin: { value: props.ruPessoaAdmin || 0 },
                nomeAdmin: {
                    value: props.nomeAdmin || '',
                    validation: {
                        output: "nomeAdminError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "maxLength",
                                params: [250],
                                msg: "Preencha no máximo 250 caracteres."
                            }
                        ]
                    }
                },
                emailAdmin: {
                    value: props.emailAdmin || '',
                    validation: {
                        output: "emailAdmin",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "maxLength",
                                params: [250],
                                msg: "Preencha no máximo 250 caracteres."
                            },
                            {
                                type: "email",
                                msg: "Email Inválido."
                            }
                        ]
                    }
                },
                idTelefoneAdmin: { value: props.idTelefoneAdmin || 0, },
                telefoneAdmin: {
                    value: props.telefoneAdmin || '',
                    mask: '(99) 99999-9999',
                    validation: {
                        output: "telefoneAdmin",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            }
                        ]
                    }
                },
                tipoTelefoneComercialAdmin: {
                    value: props.tipoTelefoneComercialAdmin || 0,
                    validation: {
                        output: "tipotelefoneComercialAdmin",
                        validators: [
                            {
                                type: "custom",
                                args: ["telefoneComercialAdmin"],
                                params: ["{telefoneComercialAdmin}"],
                                function: (value, telefoneComercialAdmin) => {
                                    return telefoneComercialAdmin.length > 0 && value == 0 ? false : true;
                                },
                                msg: "Verifique preenchimento do campo Tpo Telefone Comercial"
                            }
                        ]
                    }
                },
                idTelefoneComercialAdmin: { value: props.idTelefoneComercialAdmin || 0, },
                telefoneComercialAdmin: {
                    value: props.telefoneComercialAdmin || '',
                    mask: '(99) 99999-9999',
                    validation: {
                        output: "telefoneComercialAdmin",
                        validators: [
                            {
                                type: "custom",
                                args: ["tipoTelefoneComercialAdmin"],
                                params: ["{tipoTelefoneComercialAdmin}"],
                                function: (value, tipoTelefoneComercialAdmin) => {
                                    return tipoTelefoneComercialAdmin == 0 ? true :
                                        tipoTelefoneComercialAdmin == 1 && value.replace("(","").replace(")","").replace("-","").replace(" ","").length < 11 ? false :
                                            tipoTelefoneComercialAdmin == 2 && value.replace("(","").replace(")","").replace("-","").replace(" ","").length < 10 ? false : true;
                                },
                                msg: "Verifique preenchimento do campo Telefone Comercial"
                            }
                        ]
                    }
                },
                cargoAdmin: {
                    value: props.cargoAdmin || 0,
                    validation: {
                        output: "cargoAdminError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            }
                        ]
                    }
                },
                cpfAdmin: {
                    value: props.cpfAdmin || '',
                    mask: '999.999.999-99',
                    validation: {
                        output: "cpfAdminError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "maxLength",
                                params: [15],
                                msg: "Preencha no máximo 14 caracteres."
                            },
                            {
                                type: "custom",
                                function: (value) => {
                                    return value.replace(".","").replace("-","").replace(" ","").length < 11 ? false : true;
                                },
                                msg: "CPF inválido"
                            }
                        ]
                    }
                },
                rgAdmin: {
                    value: props.cpfAdmin || '',
                    validation: {
                        output: "rgAdminError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            }]
                    }
                },
                idPerfil: {
                    value: props.idPerfil || 0,
                },
                assinaContrato: {
                    value: props.assinaContrato || 0,
                },
                recebeEmailNota: {
                    value: props.recebeEmailNota || false,
                },
                recebeEmailBoleto: {
                    value: props.recebeEmailBoleto || false,
                },
                idTipoConvenio: {
                    value: props.idTipoConvenio || 0,
                },
                permiteEmailNota: {
                    value: props.permiteEmailNota || false,
                },
                permiteEmailBoleto: {
                    value: props.permiteEmailBoleto || false,
                },
                listCargos: { value: props.listCargos || [] },
                listEmpresas: { value: props.listEmpresas || [] },
                listTiposPerfils: { value: props.listTiposPerfils || [] }
            }
        }
        this._onChangeForm = this._onChangeForm.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    load = async () => {
        this.setState({ loading: true });
        const { form } = this.state;
        form.listCargos.value = await cargoService.OnGetListaCodigoDescricao();
        form.listEmpresas.value = await empresaService.OnGetListaCodigoDescricao(0);
        form.listTiposPerfils.value = await usuarioService.OnGetPerfil();
        form.usuarioLogadoIsComercial.value = await usuarioService.OnGetUsuarioIsComercial();
        this.setState({ form, loading: false });
    }


    _handleCargoChange = async (event) => {
        const { form } = this.state;
        form.cargo.value = event.value;
        this.setState({ form });
    }

    _onSubmit = async (values, valid) => {
        this.setState({ loading: true });
        const { form } = this.state;
        if (valid[0]) {
            var res = await usuarioService.OnPostInserir(this.buscarDadosForm());
            if (res.success) {
                toast.success('Salvo com Sucesso.');
            }
            this.setState({ form, loading: false });
            document.getElementById('idGerenciarUsuarios').click();
        } else {
            toast.error('Verifique o preenchimento dos campos.');
            this.setState({ loading: false });
        }
    }

    onClickTipoTelefoneAdmin = async (event) => {
        const { form } = this.state;
        if (event.target.value == '1')
            form.telefoneComercialAdmin.mask = "(99) 99999-9999";
        else
            form.telefoneComercialAdmin.mask = "(99) 9999-9999";
        this.setState({ form });
    }

    buscarDadosForm() {
        const { form } = this.state;
        return {
            IdPessoaDadosCadastrais: form.idPessoaDCAdmin.value,
            Id: form.idPessoaAdmin.value,
            Ru: form.ruPessoaAdmin.value,
            Nome: form.nomeAdmin.value,
            NomeSocial: form.nomeAdmin.value,
            DataNascimento: null,
            IdCargo: form.cargoAdmin.value,
            IdEmpresa: form.idEmpresa.value,
            IdPerfil: form.idPerfil.value,
            Email: form.emailAdmin.value,
            Celular: form.telefoneAdmin.value,
            Comercial: form.telefoneComercialAdmin.value,
            Cpf: form.cpfAdmin.value,
            Rg: form.rgAdmin.value,
            AssinaContrato:form.assinaContrato.value == true?1:0,
            recebeEmailNota: form.recebeEmailNota.value,
            recebeEmailBoleto: form.recebeEmailBoleto.value,
        }
    }

    limpaDados() {
        this.setState({ loading: true });
        const { form } = this.state;
        form.idEmpresa.value = 0;
        form.rgAdmin.value = '';
        form.cpfAdmin.value = '';
        form.nomeAdmin.value = '';
        form.cargoAdmin.value = 0;
        form.emailAdmin.value = '';
        form.telefoneAdmin.value = '';
        form.telefoneComercialAdmin.value = '';
        form.tipoTelefoneComercialAdmin.value = 0;
        document.getElementById('idEmpresa').value = 0;
        document.getElementById('cargoAdmin').value = 0;
        document.getElementById('idPerfil').value = 0;
        this.setState({ form, loading: false });
    }

    async _onChangeForm(form) {
        this.setState({ form })
    }

    _onValid = valid => this.setState({ valid })

    _handleCPFChange = async (event) => {
        const { form } = this.state;
        var id = event.target.id;
        if (event.target.values === "000.000.000-00" || event.target.values === '') {
            form.rgAdmin.value = '';
            form.cpfAdmin.value = '';
            form.nomeAdmin.value = '';
            form.rgAdmin.value = '';
            form.emailAdmin.value = '';
            form.telefoneAdmin.value = '';
            form.idPessoaAdmin.value = 0;
            this.setState({ form })
        }
        if (event.target.value !== undefined &&
            event.target.value !== null &&
            event.target.value !== "" &&
            event.target.value !== 0 &&
            event.target.value !== "000.000.000-00" &&
            event.target.value.length === 14) {
            this.setState({ loading: true });
            const res = await solicitacaoService.getDadosPorCPF(event.target.value.replace('.', '').replace('.', '').replace('/', '').replace('-', ''));

            if (res.success === true && res.data !== null && res.data !== undefined) {
                if (res.data.user !== null) {
                    form.nomeAdmin.value = res.data.nome || res.data.user.nome || '';
                    form.emailAdmin.value = res.data.email || res.data.user.email || '';
                    form.telefoneAdmin.value = res.data.telefone || res.data.user.telefone || '';
                    form.idPessoaAdmin.value = res.data.idPessoaDadosCadastrais || 0;
                    form.rgAdmin.value = res.data.rg || '';

                    if (res.data.user.cdPhoneCelular != null) {
                        form.telefoneAdmin.value = res.data.user.celular || '';
                        form.idTelefoneAdmin.value = res.data.user.cdPhoneCelular || null;
                    } else {
                        form.telefoneAdmin.value = '';
                        form.idTelefoneAdmin.value = 0;
                    }
                    if (res.data.user.cdPhoneComercial != null) {
                        if (res.data.user.comercial.length == 10)
                            document.getElementById("FixoAdmin").click();
                        else
                            document.getElementById("CelularAdmin").click();
                        form.tipoTelefoneComercialAdmin.value = res.data.user.comercial.length == 10 ? 2 : 1;
                        form.telefoneComercialAdmin.value = res.data.user.comercial || '';
                        form.idTelefoneComercialAdmin.value = res.data.user.cdPhoneComercial || null;
                        form.ruPessoaAdmin.value = res.data.user.ru || 0;
                    }
                }
                if (res.data.id > 0) {
                    form.rgAdmin.value = res.data.nome || '';
                    form.nomeAdmin.value = res.data.nome || '';
                    form.emailAdmin.value = res.data.user !== null ? res.data.user.email || res.data.email : res.data.email || '';
                    form.idPessoaDCAdmin.value = res.data.idPessoaDadosCadastrais || 0;
                    form.idPessoaAdmin.value = res.data.id || 0;

                    if (res.data.cdPhoneCelular != null) {
                        form.telefoneAdmin.value = res.data.celular || '';
                        form.idTelefoneAdmin.value = res.data.cdPhoneCelular || null;
                    } else {
                        form.telefoneAdmin.value = res.data.user !== null ? res.data.user.celular || 0 : 0;
                        form.idTelefoneAdmin.value = res.data.user !== null ? res.data.user.cdPhoneCelular || 0 : 0;
                    }
                    if (res.data.cdPhoneComercial != null) {
                        if (res.data.user.comercial.length == 10)
                            document.getElementById("FixoAdmin").click();
                        else
                            document.getElementById("CelularAdmin").click();

                        form.tipoTelefoneComercialAdmin.value = res.data.comercial.length == 10 ? 2 : 1;
                        form.telefoneComercialAdmin.value = res.data.comercial || '';
                        form.idTelefoneComercialAdmin.value = res.data.cdPhoneComercial || null;
                    } else {
                        form.tipoTelefoneComercialAdmin.value = res.data.user !== null && res.data.user.comercial != null ? res.data.user.comercial.length == 10 ? 2 : 1 : 0;
                        form.telefoneComercialAdmin.value = res.data.user !== null ? res.data.user.comercial || '' : '';
                        form.idTelefoneComercialAdmin.value = res.data.user !== null ? res.data.user.cdPhoneComercial || null : null;
                    }
                }
            }


            this.setState({ form, loading: false });
        }
    }

    _handleEmpresaChange = async (event) => {
        
        const { form } = this.state;
        form.idEmpresa.value = event.value;
        form.idTipoConvenio.value = event.idTipoConvenio;
        if(event.value > 0 || event.value != null)
        {
            var data = await assinaturaContratoService.OnGetPermiteAdicionarAssinante(form.idEmpresa.value);
            form.addAssinanteContrato.value = data.data.permite;

            if(event.idTipoConvenio == '2')
            {
                form.permiteEmailNota.value = await usuarioService.OnGetPermiteEmailNota(event.value);
                form.permiteEmailBoleto.value = await usuarioService.OnGetPermiteEmailBoleto(event.value);
            }
        }
        else { form.addAssinanteContrato.value = null; }
        this.setState({ form });
    }

    _handleCargoChange = async (event) => {
        const { form } = this.state;
        form.cargoAdmin.value = event.value;
        this.setState({ form });
    }

    _handleTipoPerfilChange = async (event) => {
        const { form } = this.state;
        form.idPerfil.value = parseInt(event.value);
        this.setState({ form, idPerfil: event })
    }

    render() {
        const { form } = this.state;
        return <>
            <BreadCrumb breads={this.breads} />
            <div className="uninter-box">
                <div className="row">
                    <div className="uninter-passo-geral col-xs-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-10 offset-lg-1 uninter-card-empresa-convenio">
                        <Form onSubmit={this._onSubmit} formControl={form} onChangeForm={this._onChangeForm} onValid={this._onValid}>
                            <div className="card uninter-convenioempresas-final uninter-passo">
                                <h1>Cadastro de Usuário</h1>
                            </div>
                            <div className="card-body">
                                <ul className="uninterconvenioempresas uninter-passo-interno">
                                    <li>
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                            <label>Empresa</label>
                                            <Select
                                                onChange={this._handleEmpresaChange}
                                                isLoading={form.listEmpresas.value.length > 0 ? false : true}
                                                options={form.listEmpresas.value.map((o, i) => {
                                                    return { id: i, value: o.codigo, label: o.descricao?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"), idTipoConvenio: o.idTipoConvenio };
                                                })}
                                                id={"idEmpresa"}
                                                name={"idEmpresa"}
                                                defaultValue={{ label: "SELECIONE", value: 0 }} />
                                            <span className="error" id="idEmpresaError"></span>
                                        </div>
                                        <div className="col-xs-10 col-sm-5 col-md-4 col-lg-3 uninter-campos">
                                            <label>CPF</label>
                                            <input type="text" id="cpfAdmin" name="cpfAdmin" onBlur={this._handleCPFChange} />
                                            <span className="error" id="cpfAdminError"></span>
                                        </div>
                                        <div className="col-xs-10 col-sm-5 col-md-4 col-lg-3 uninter-campos">
                                            <label>RG</label>
                                            <input type="text" id="rgAdmin" name="rgAdmin" />
                                            <span className="error" id="rgAdminError"></span>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 uninter-campos">
                                            <label>Nome</label>
                                            <input type="text" id="nomeAdmin" name="nomeAdmin" />
                                            <span className="error" id="nomeAdminError"></span>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 uninter-campos">
                                            <label>Perfil</label>
                                            <Select
                                                onChange={this._handleTipoPerfilChange}
                                                isLoading={form.listTiposPerfils.value.length > 0 ? false : true}
                                                options={form.listTiposPerfils.value.map((o, i) => {
                                                    return { id: i, value: o.codigo, label: o.descricao };
                                                })}
                                                id={"idEmpresa"}
                                                name={"idEmpresa"}
                                                defaultValue={{ label: "SELECIONE", value: 0 }} />
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 uninter-campos">
                                            <label htmlFor="ddlCargoAdmin">Cargo</label>
                                            <Select
                                                onChange={this._handleCargoChange}
                                                isLoading={form.listCargos.value.length > 0 ? false : true}
                                                options={form.listCargos.value.map((o, i) => {
                                                    return { id: i, value: o.codigo, label: o.descricao };
                                                })}
                                                id={"cargoAdmin"}
                                                name={"cargoAdmin"}
                                                defaultValue={{ label: "SELECIONE", value: 0 }} />
                                            <span className="error" id="cargoAdminError"></span>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 uninter-campos">
                                            <label>Email</label>
                                            <input type="email" id="emailAdmin" name="emailAdmin" />
                                            <span className="error" id="emailAdmin"></span>
                                        </div>
                                        <div className="col-xs-10 col-sm-5 col-md-4 col-lg-4 uninter-campos">
                                            <label>Telefone Celular</label><span></span>
                                            <input type="text" id="telefoneAdmin" name="telefoneAdmin" />
                                            <span className="error" id="telefoneAdmin"></span>
                                        </div>
                                        <div className="col-xs-10 col-sm-5 col-md-4 col-lg-4 uninter-campos">
                                            <div className="uninter-campos-checkbox">
                                                <ul className="uninter-declaro-convenioempresas">
                                                    <label className="label">Tipo Telefone Comercial</label>
                                                    <li className="form-check">
                                                        <input type="radio" id="CelularAdmin" onClick={this.onClickTipoTelefoneAdmin} name="tipoTelefoneComercialAdmin" value="1" />
                                                        <label className="label">Celular</label>

                                                        <input type="radio" id="FixoAdmin" onClick={this.onClickTipoTelefoneAdmin} name="tipoTelefoneComercialAdmin" value="2" />
                                                        <label className="label">Fixo</label>
                                                    </li>
                                                </ul>
                                                <center><span className="error" id="tipotelefoneComercialAdmin"></span></center>
                                            </div>
                                        </div>
                                        <div className="col-xs-10 col-sm-5 col-md-4 col-lg-4 uninter-campos">
                                            <label>Telefone Comercial</label>
                                            <input type="text" id="telefoneComercialAdmin" name="telefoneComercialAdmin" />
                                            <span className="error" id="telefoneComercialAdmin"></span>
                                        </div>
                                        <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4 uninter-campos" hidden={form.usuarioLogadoIsComercial.value === false}>
                                            <div className="uninter-campos-checkbox">
                                                <ul className="uninter-declaro-convenioempresas">
                                                    <label className="label">Deve Assinar Contrato?</label>
                                                    <li className="form-check">
                                                        <input type="checkbox" id="assinaContrato" name="assinaContrato" value="1"  disabled={form.idPerfil.value !== 6 || form.addAssinanteContrato.value === 0}/>
                                                        <label className="label">Sim</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4 uninter-campos" hidden={form.idTipoConvenio.value == '2' ? false : true}> 
                                            <div className="uninter-campos-checkbox">
                                                <ul className="uninter-declaro-convenioempresas">
                                                    <label className="label">Recebe Email Nota?</label>
                                                    <li className="form-check">
                                                        <input type="checkbox" id="recebeEmailNota" name="recebeEmailNota" disabled={!form.permiteEmailNota.value}/>
                                                        <label className="label">Sim</label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <span className="uninter-campos">{!form.permiteEmailNota.value ? 'Fora do período de envio de nota' : ''}</span>
                                        </div>
                                        <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4 uninter-campos" hidden={form.idTipoConvenio.value == '2' ? false : true}> 
                                            <div className="uninter-campos-checkbox">
                                                <ul className="uninter-declaro-convenioempresas">
                                                    <label className="label">Recebe Email Boleto?</label>
                                                    <li className="form-check">
                                                        <input type="checkbox" id="recebeEmailBoleto" name="recebeEmailBoleto" disabled={!form.permiteEmailBoleto.value}/>
                                                        <label className="label">Sim</label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <span className="uninter-campos">{!form.permiteEmailBoleto.value ? 'Fora do período de envio de boleto' : ''}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-card-base-btn">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-card-base-btn">
                                    <button className="uninter-box-home-convenioempresas-btn-enviar-formulario" type="submit">
                                        <i className="fa fa-user-plus"></i>
                                        <span style={{ marginLeft: 10 }}>
                                            Criar Usuário
                                    </span>
                                    </button>
                                </div>
                            </div>
                            <Link hidden={true} id='idGerenciarUsuarios' to="/administracao/ListaUsuarios"></Link>
                        </Form>
                    </div>
                </div>
                <br />
            </div>
        </>
    }
}