import BaseApi from "./BaseApi";

export default class PessoaService {

    static async OnGetRepresentantes(idEmpresa) {
        try {
            let res = await BaseApi.get(`/v1/pessoa/GetResponsaveisEmpresaCodigoDescricao/${idEmpresa}`);
            debugger;
            return res.data;

        } catch (error) {
            return error.response.data;
        }
    }
}