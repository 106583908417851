
import {environment as local} from './environment.local';
import {environment as dev} from './environment.dev';
import {environment as prod} from './environment.prod';
import {environment as qa} from './environment.qa';
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : process.env.REACT_APP_STAGE === 'qa'
  ? qa
  : process.env.REACT_APP_STAGE === 'dev'
  ? dev
  : local;

export default {
  ...config,
  sistema: 'empconv',
  nome: 'Empresa Convênio',
};

// export default {
//   ...config,
//   sistema: 'templateinterno',
//   nome: 'Template interno',
// };