import BaseApi from "./BaseApi";

export default class QuintoElementoService
{    
    static async OnGetCashDiscount() {
        try {
            let res = await BaseApi.get(`v1/QuintoElemento/GetCashDiscount`);
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    }
}