import BaseApi from "./BaseApi";

export default class SolicitacaoService {

    static async list(form) {
        try {
            let res = await BaseApi.post(`v1/Solicitacao/Filter`, form);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async listAll() {
        try {
            let res = await BaseApi.get(`v1/Solicitacao/GetAll`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async getDadosPorCNPJ(cnpj) {
        try {
            let res = await BaseApi.get(`v1/Solicitacao/GetDadosPorCNPJ/${cnpj}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async getDadosPorCPF(cpf) {
        try {
            let res = await BaseApi.get(`/v1/Solicitacao/GetDadosPorCPF/${cpf}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async insert(form) {
        try {
            let res = await BaseApi.post(`v1/Solicitacao`, form);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async edit(form) {
        try {
            let res = await BaseApi.put(`v1/Solicitacao/Editar`, form);
            return res.data;

        } catch (error) {
            return error.response.data;
        }
    }

    static async putAtivar(idEmpresa) {
        try {
            let res = await BaseApi.put(`v1/Solicitacao/AtivarSolicitacao/${idEmpresa}`);
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    }

    static async putCancelar(form) {
        try {
            let res = await BaseApi.put(`v1/Solicitacao/CancelarSolicitacao`, form);
            return res.data;

        } catch (error) {
            return error.response.data;
        }
    }

    static async OnPostMultUpload(files) {
        try {
            let res = await BaseApi.post(`/v1/Solicitacao/MultUpload`, files);
            return res;

        } catch (error) {
            return error.response.data;
        }
    }
    static async OnPostAlterarSituacao(model) {
        try {
            let res = await BaseApi.post(`/v1/Solicitacao/AlterarSituacao`, model);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnPostExcluirFormalizacao(model) {
        try {
            let res = await BaseApi.post(`/v1/Solicitacao/ExcluirFormalizacao`, model);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }
    
    static async OnPostTermoAceite(model) {
        try {
            let res = await BaseApi.post(`/v1/Solicitacao/TermoAceite`, model);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnPostAlterarSituacaoPrazoExpirado(model) {
        try {
            let res = await BaseApi.post(`/v1/Solicitacao/AlterarSituacaoPrazoExpirado`, model);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnGetSituacao() {
        try {
            let res = await BaseApi.get(`v1/Solicitacao/GetSituacao`);
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnGetMotivoInativacao() {
        try {
            let res = await BaseApi.get(`v1/Solicitacao/GetMotivoInativacao`);
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnPostAllAnexos(filtros) {
        try {
            let res = await BaseApi.post(`v1/Solicitacao/GetAllAnexos`, filtros);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }
    static async OnPostDownloadAnexo(filtros) {
        try {
            let res = await BaseApi.post(`v1/Solicitacao/DownloadAnexo`, filtros);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async getVigencia(ru) {
        try {
            let res = await BaseApi.get(`v1/Solicitacao/GetVigencia/${ru}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async getSolicitacaoById(idSolicitacao) {
        try {
            let res = await BaseApi.get(`v1/Solicitacao/GetSolicitacaoById/${idSolicitacao}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async OnGetDadosContrato(IdEmpresa) {
        try {
            let res = await BaseApi.get(`v1/Solicitacao/GetDadosContrato/${IdEmpresa}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async getConvenio(filtros) {
        try {
            let res = await BaseApi.post(`v1/Solicitacao/GetConvenio`, filtros);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }   
    
    static async getPolos(filtros) {
        try {
            let res = await BaseApi.post(`v1/Solicitacao/GetPolos`, filtros);
            return res.data;
        } catch (error) {
            return error.response.data;
        }
    }

    static async solicitacaoConvenioPagamento(form) {
        try {
            let res = await BaseApi.post(`v1/Solicitacao/SolicitacaoConvenioPagamento`, form);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }
}