import React, { Component, HTMLInputElement }  from 'react';
import '../../assets/Geogrotesque/geofrotesque.css';
import '../../assets/css/bootstrap-grid.css';
import '../../assets/css/style.css';
import { toast } from 'react-toastify';
export class Combobox extends Component {
    constructor(props) {
        super(props);         
    }
    render() {
        return (           
            <select onChange={this.props.onChange} className={this.props.class} name={this.props.name} id={this.props.id} >
                <option value="0">SELECIONE</option>
                {                
                    this.props.opstions.map(function (item, index) {
                        return <option key={ index} value={ item.codigo }>{item.descricao}</option>;
                    })
                }
            </select>
        );
    }
}