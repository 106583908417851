import React, { Component } from 'react';
import '../Pages/Login/Login.scss';
import '../../assets/Geogrotesque/geofrotesque.css';
import Topo from '../../components/EmpresaConvenio';
import BannerEmpresaConvenio from '../../components/EmpresaConvenio/BannerEmpresaConvenio';
import '../../components/EmpresaConvenio/EmpresaConvenio.css';
import { Link } from 'react-router-dom';

export default class Finish extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <>
                <div>
                    <Topo></Topo>
                    <BannerEmpresaConvenio></BannerEmpresaConvenio>
                    <div className="uninter-container-convenioempresas">
                        <div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 offset-lg-1 uninter-topo-convenioempresas">
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                    <h3>CONVÊNIO EMPRESARIAL UNINTER</h3>
                                    <br></br>
                                    <div className="uninter-conteudo-texto-convenioempresas">
                                        <p>
                                            Quem quer superar metas e desafios — e reconhece o conhecimento como o caminho mais seguro para o sucesso — tem tudo a ver com a Uninter.
</p>
                                        <p>
                                            Estamos entre os maiores grupos educacionais do país, com polos presenciais em mais de 700 cidades do Brasil e também nos Estados Unidos. Além de nos conferir um excelente conceito geral no MEC, a nossa qualidade de ensino nos tornou uma referência não só para o mercado, mas também para as pessoas que acompanham a evolução e as conquistas de nossos alunos.
</p>
                                        <p>
                                            Com o Convênio Empresarial Uninter, sua empresa tem a oportunidade de fazer parte da missão de transformar vidas por meio da educação. Seja no modelo de convênio desconto ou de contrato pagamento, essa é a iniciativa ideal para estimular o contínuo desenvolvimento dos colaboradores, bem como de seus dependentes e familiares. E sem custo para a empresa!
</p>
                                        <p>
                                            A Uninter já é parceira de mais de 8.000 organizações, como: fundações, prefeituras, sindicatos, associações e outras empresas. Faça parte disso: ofereça esse benefício e aproveite as vantagens.

                                        </p>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                    <div className="uninter-box-home-convenioempresas">

                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                                            <h4>SEU FORMULÁRIO FOI ENVIADO!</h4>
                                            <h3>Aguarde retorno a respeito deste envio.</h3>
                                            <br></br>
                                            <div class="uninter-btn-voltar-inicio-box">
                                                <Link className="uninter-btn-voltar-inicio" id="linkLogin" title="Voltar para tela inicial" to="Login">Início</Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                    <div className="uninter-conteudo-texto-convenioempresas-box-paragrafos col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <br></br>
                                            <h3>CONVÊNIO DESCONTO</h3>
                                            <br></br>
                                            <div className="uninter-conteudo-texto-convenioempresas">
                                                <p>
                                                    Neste formato de parceria, a Uninter concede aos beneficiários* o <b>DESCONTO DE 10%</b> sobre o valor líquido das mensalidades dos cursos de graduação e de pós-graduação (Lato Sensu), nas modalidades a distância, presencial, semipresencial e telepresencial. Válido também para o EJA (Educação de Jovens e Adultos), na modalidade a distância.
                                        </p>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <br></br>
                                            <h3>CONTRATO PAGAMENTO (CONVÊNIO)</h3>
                                            <br></br>
                                            <div className="uninter-conteudo-texto-convenioempresas">

                                                <p>
                                                    No convênio por contrato pagamento, <b>a empresa pode custear o percentual de até 100%</b> do valor das parcelas mensais do curso de seus colaboradores. Caso o percentual deste investimento da empresa não atenda a totalidade dos custos, o colaborador terá a responsabilidade de arcar com o saldo restante das parcelas/mensalidades, dentro do prazo de vencimento estipulado em contrato. Por meio deste formato de parceria, todas as modalidades e níveis de cursos também estão disponíveis. Caso tenha interesse neste tipo de contrato, favor encaminhar e-mail para <a href="mailto:parceria.corporativo@uninter.com">parceria.corporativo@uninter.com</a>.
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </>
        )
    }
}

