import React, { Component } from 'react';
import Topo from '../../components/EmpresaConvenio';
import FileUpload from '../../components/EmpresaConvenio/FileUpload';
import { Form, mapValues, getFormValues } from 'react-simple-form-control'
import '../../assets/Geogrotesque/geofrotesque.css';
import '../../assets/css/bootstrap-grid.css';
import '../../assets/css/style.css';
import Loading from '../../components/Loading';
import { cpfMask, telefoneMask } from '../../helpers';
import { toast } from 'react-toastify';
import solicitacaoService from '../../services/SolicitacaoService';
import contratoService from '../../services/ContratoService';
import documentoService from '../../services/DocumentoService';
import enderecoService from '../../services/EnderecoService';
import empresaService from '../../services/EmpresaService';
import cargoService from '../../services/CargoService';
import departamentoService from '../../services/DepartamentoService';
import { Link } from 'react-router-dom';
import ModalAlert from '../../components/ModalAlert';
import { FormText, InputGroup } from 'react-bootstrap';
import combobox, { Combobox } from '../../components/Listas/Combobox';
import Recaptcha from 'react-recaptcha';
import Select from 'react-select';
import enviromment from '../../environment';
import Helpers, { removeCaracteresEspeciais, emailIsValid, isNullOrEmptyOrWhiteSpaceOrUndefined } from '../../helpers/Helper';

export default class Formulario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isVerified: false,
            valid: true,
            loading: false,
            error: '',
            form: {
                lista: {
                    value: [],
                    // validation: {
                    //     output: "listaError",
                    //     validators: [
                    //         {
                    //             type: 'custom',
                    //             function: (value) => {
                    //                 return !(this.isPublicCompany() && value.length == 0);
                    //             },
                    //             msg: "Informe um arquivo"
                    //         }
                    //     ]
                    // }
                },
                arquivoNovo: {
                    value: {}
                },
                tipoDocumento: {
                    value: props.tipoDocumento || 0,
                },
                showModalBlackList: {
                    value: props.showModalBlackList
                },
                idSolicitacao: {
                    value: props.idSolicitacao
                },
                tipoEmpresa: {
                    value: props.tipoEmpresa || 0,
                    description: "Tipo de empresa",
                    validation: {
                        output: "tipoEmpresaError",
                        validators: [
                            {
                                type: 'required',
                                msg: "Selecione a natureza jurídica."
                            }
                        ]
                    }
                },
                arquivo: {
                    value: props.arquivo || 0,
                },
                tipoArquivo: {
                    value: props.tipoArquivo || 0,
                },
                isArquivoRequired: {
                    value: props.isArquivoRequired,
                },
                tipoConvenio: {
                    value: props.tipoConvenio || 0,
                    description: "Tipo de Convênio",
                    validation: {
                        output: "tipoConvenioError",
                        validators: [
                            {
                                type: 'required',
                                msg: "Selecione o tipo do Convênio."
                            }
                        ]
                    }
                },
                polo: {
                    value: props.polo || '',
                    description: "Dados Básicos",
                    validation: {
                        output: "dadosBasicosError",
                        validators: [
                            {
                                type: "custom",
                                params: ["{gestor}", "{polo}"],
                                function: (value, gestor, polo) => {
                                    return polo != '' || gestor != '' ? true : false
                                },
                                msg: "É obrigatório informar Consultor ou Polo"
                            }
                        ]
                    }
                },
                idpolo: {
                    value: props.idpolo || 0
                },
                estadopolo: {
                    value: props.estadopolo || ''
                },
                gestor: {
                    value: props.gestor || ''
                },
                razaoSocial: {
                    value: props.razaoSocial || '',
                    description: "Razão Social",
                    validation: {
                        output: "razaoSocialError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "maxLength",
                                params: [80],
                                msg: "Preencha no máximo 80 caracteres."
                            }
                        ]
                    }
                },
                nomeFantasia: {
                    value: props.nomeFantasia || '',
                    description: "Nome Fantasia",
                    validation: {
                        output: "nomeFantasiaError",
                        validators: [
                            {
                                type: "maxLength",
                                params: [80],
                                msg: "Preencha no máximo 80 caracteres."
                            }
                        ]
                    }
                },
                permiteOfertarCursos: {
                    value: props.permiteOfertarCursos || '',
                },
                rua: {
                    value: props.rua || '',
                    description: "Rua",
                    validation: {
                        output: "ruaError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "maxLength",
                                params: [50],
                                msg: "Preencha no máximo 50 caracteres."
                            }
                        ]
                    }
                },
                numero: {
                    value: props.numero || '',
                    description: "Número",
                    validation: {
                        output: "numeroError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                        ]
                    }
                },
                bairro: {
                    value: props.bairro || '',
                    description: "Bairro",
                    validation: {
                        output: "bairroError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "maxLength",
                                params: [100],
                                msg: "Preencha no máximo 100 caracteres."
                            }
                        ]
                    }
                },
                cep: {
                    value: props.cep || '',
                    mask: '99999-999',
                    description: "CEP",
                    validation: {
                        output: "cepError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "maxLength",
                                params: [14],
                                msg: "Preencha no máximo 14 caracteres."
                            }
                        ]
                    }
                },
                idCidade: { value: props.idCidade || 0, },
                cidade: {
                    value: props.cidade || '',
                    description: "Cidade",
                    validation: {
                        output: "cidadeError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "minLength",
                                params: [3],
                                msg: "Preencha no mínimo 3 caracteres."
                            },
                            {
                                type: "maxLength",
                                params: [100],
                                msg: "Preencha no máximo 100 caracteres."
                            }
                        ]
                    }
                },
                uf: {
                    value: props.uf || '',
                    description: "Estado",
                    validation: {
                        output: "ufError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "minLength",
                                params: [2],
                                msg: "Preencha no mínimo 2 caracteres."
                            },
                            {
                                type: "maxLength",
                                params: [2],
                                msg: "Preencha no máximo 2 caracteres."
                            }
                        ]
                    }
                },
                complemento: { value: props.complemento || '', },
                idZipCode: { value: props.idZipCode || 0, },
                idEndereco: { value: props.idEndereco || 0, },
                ru: { value: props.ru || 0, },
                idEmpresa: { value: props.idEmpresa || 0, },
                cnpj: {
                    mask: '99.999.999/9999-99',
                    value: props.cnpj || '',
                    description: "CNPJ",
                    validation: {
                        output: "cnpjError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "maxLength",
                                params: [18],
                                msg: "Preencha no máximo 18 caracteres."
                            }
                        ]
                    }
                },
                inscricaoEstadual: {
                    value: props.inscricaoEstadual || '',
                    description: "Inscrição Estadual",
                    validation: {
                        output: "inscricaoEstadualError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "maxLength",
                                params: [250],
                                msg: "Preencha no máximo 250 caracteres."
                            }
                        ]
                    }
                },
                site: {
                    value: props.site || '',
                    description: "Site",
                    validation: {
                        output: "siteError",
                        validators: [
                            {
                                type: "maxLength",
                                params: [100],
                                msg: "Preencha no máximo 100 caracteres."
                            }
                        ]
                    }
                },
                emailEmpresa: {
                    value: '',
                    description: "Email empresa",
                    validation: {
                        output: "emailEmpresaError",
                        validators: [
                            {
                                type: "maxLength",
                                params: [250],
                                msg: "Preencha no máximo 100 caracteres."
                            }
                        ]
                    }
                },
                controleColaboradores: {
                    value: props.controleColaboradores || '',
                },
                numeroColaboradores: {
                    value: props.numeroColaboradores || '',
                },
                idPessoaRepresentante: { value: props.idPessoaRepresentante || 0 },
                idPessoaDCRepresentante: { value: props.idPessoaDCRepresentante || 0 },
                ruPessoaRepresentante: { value: props.ruPessoaRepresentante || 0 },
                nomeRepresentante: {
                    value: props.nomeRepresentante || '',
                    description: "Nome Representante",
                    validation: {
                        output: "nomeRepresentanteError",
                        validators: [
                            {
                                type: 'custom',
                                params: ["{adminRepresentante}", "{nomeRepresentante}"],
                                function: (value, adminRepresentante, nomeRepresentante) => {
                                    return adminRepresentante > 0 || nomeRepresentante != '' ? true : false
                                },
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "maxLength",
                                params: [250],
                                msg: "Preencha no máximo 250 caracteres."
                            }
                        ]
                    }
                },
                emailRepresentante: {
                    value: props.emailRepresentante || '',
                    description: "Email Representante",
                    validation: {
                        output: "emailRepresentanteError",
                        validators: [
                            {
                                type: 'custom',
                                params: ["{adminRepresentante}", "{emailRepresentante}"],
                                function: (value, adminRepresentante, emailRepresentante) => {
                                    return adminRepresentante || emailRepresentante != '' ? true : false
                                },
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: 'custom',
                                params: ["{adminRepresentante}", "{emailRepresentante}"],
                                function: (value, adminRepresentante, emailRepresentante) => {
                                    return adminRepresentante || emailRepresentante.length < 250 ? true : false
                                },
                                msg: "Preencha no máximo 250 caracteres."
                            },
                            {
                                type: "custom",
                                params: ["{adminRepresentante}", "{emailRepresentante}"],
                                function: (value, adminRepresentante, emailRepresentante) => {
                                    return adminRepresentante ? true : emailIsValid(value)
                                },
                                msg: "Email Inválido."
                            }
                        ]
                    }
                },
                departamentoRepresentante: {
                    value: props.departamentoRepresentante || 0,
                    description: "Departamento Representante",
                    // validation: {
                    //     output: "departamentoRepresentanteError",
                    //     validators: [
                    //         {
                    //             type: 'required',
                    //             msg: 'Campo obrigatório'
                    //         }
                    //     ]
                    // }
                },
                cargoRepresentante: {
                    value: props.cargoRepresentante || 0,
                    description: "Cargo Representante",
                    validation: {
                        output: "cargoRepresentanteError",
                        validators: [
                            {
                                type: 'custom',
                                params: ["{adminRepresentante}", "{cargoRepresentante}"],
                                function: (value, adminRepresentante, cargoRepresentante) => {
                                    return adminRepresentante || cargoRepresentante > 0 ? true : false
                                },
                                msg: 'Campo obrigatório'
                            }
                        ]
                    }
                },
                idTelefoneRepresentante: { value: props.idTelefoneRepresentante || 0, },
                telefoneRepresentante: {
                    mask: '(99) 99999-9999',
                    value: props.telefoneRepresentante || '',
                    description: "Telefone Representante",
                    validation: {
                        output: "telefoneRepresentanteError",
                        validators: [
                            {
                                type: 'custom',
                                params: ["{adminRepresentante}", "{telefoneRepresentante}"],
                                function: (value, adminRepresentante, telefoneRepresentante) => {
                                    return adminRepresentante || telefoneRepresentante != '' ? true : false
                                },
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "custom",
                                function: (value) => {
                                    return removeCaracteresEspeciais(value) == 11 ? false : true;
                                },
                                msg: "Preencha no máximo 11 números."
                            }
                        ]
                    }
                },
                tipoTelefoneComercialRepresentante: {
                    value: props.tipoTelefoneComercialRepresentante || 0,
                    description: "",
                    validation: {
                        output: "tipoTelefoneComercialRepresentanteError",
                        validators: [
                            {
                                type: "custom",
                                args: ["telefoneComercialRepresentante"],
                                params: ["{telefoneComercialRepresentante}"],
                                function: (value, telefoneComercialRepresentante) => {
                                    return telefoneComercialRepresentante.length > 0 && value == 0 ? false : true;
                                },
                                msg: "Verifique preenchimento do campo"
                            }
                        ]
                    }
                },
                idTelefoneComercialRepresentante: { value: props.idTelefoneComercialRepresentante || 0, },
                telefoneComercialRepresentante: {
                    value: props.telefoneComercialRepresentante || '',
                    mask: '(99) 9999-9999',
                    description: "Telefone Comercial Representante",
                    validation: {
                        output: "telefoneComercialRepresentanteError",
                        validators: [
                            {
                                type: 'custom',
                                params: ["{adminRepresentante}", "{telefoneComercialRepresentante}"],
                                function: (value, adminRepresentante, telefoneComercialRepresentante) => {
                                    return adminRepresentante || telefoneComercialRepresentante != '' ? true : false
                                },
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "custom",
                                args: ["tipoTelefoneComercialRepresentante"],
                                params: ["{tipoTelefoneComercialRepresentante}"],
                                function: (value, tipoTelefoneComercialRepresentante) => {
                                    return tipoTelefoneComercialRepresentante == 0 ? true :
                                        tipoTelefoneComercialRepresentante == 1 && value.replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length < 11 ? false :
                                            tipoTelefoneComercialRepresentante == 2 && value.replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length < 10 ? false : true;
                                },
                                msg: "Verifique preenchimento do campo"
                            }
                        ]
                    }
                },
                cpfRepresentante: {
                    value: props.cpfRepresentante || '',
                    mask: '999.999.999-99',
                    description: "CPF Representante",
                    validation: {
                        output: "cpfRepresentanteError",
                        validators: [
                            {
                                type: 'custom',
                                params: ["{adminRepresentante}", "{cpfRepresentante}"],
                                function: (value, adminRepresentante, cpfRepresentante) => {
                                    return adminRepresentante || cpfRepresentante != '' ? true : false
                                },
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "maxLength",
                                params: [15],
                                msg: "Preencha no máximo 14 caracteres."
                            },
                            {
                                type: "custom",
                                function: (value) => {
                                    return value = null || value == 0 ? true : value.length > 0 && value.replace("-", "").replace("-", "").length < 11 ? false : true;
                                },
                                msg: "CPF inválido"
                            }
                        ]
                    }
                },
                rgRepresentante: {
                    value: props.rgRepresentante || '',
                    description: "RG Representante",
                    validation: {
                        output: "rgRepresentanteError",
                        validators: [
                            {
                                type: 'custom',
                                params: ["{adminRepresentante}", "{rgRepresentante}"],
                                function: (value, adminRepresentante, rgRepresentante) => {
                                    return adminRepresentante || rgRepresentante != '' ? true : false
                                },
                                msg: 'Campo obrigatório'
                            }
                        ]
                    }
                },
                assinaContratoRepresentante: { value: props.assinaContratoRepresentante || '', },
                idPessoaRepresentante2: { value: props.idPessoaRepresentante2 || 0 },
                idPessoaDCRepresentante2: { value: props.idPessoaDCRepresentante2 || 0 },
                ruPessoaRepresentante2: { value: props.ruPessoaRepresentante2 || 0 },
                nomeRepresentante2: {
                    value: props.nomeRepresentante2 || '',
                },
                emailRepresentante2: {
                    value: props.emailRepresentante2 || '',
                },
                departamentoRepresentante2: {
                    value: props.departamentoRepresentante2 || 0,
                },
                cargoRepresentante2: {
                    value: props.cargoRepresentante2 || 0,
                },
                idTelefoneRepresentante2: { value: props.idTelefoneRepresentante2 || 0, },
                telefoneRepresentante2: {
                    mask: '(99) 99999-9999',
                    value: props.telefoneRepresentante2 || ''
                },
                tipoTelefoneComercialRepresentante2: {
                    value: props.tipoTelefoneComercialRepresentante2 || 0,
                    description: "Tipo Telefone Comercial Representante 2",
                    validation: {
                        output: "tipoTelefoneComercialRepresentante2",
                        validators: [
                            {
                                type: "custom",
                                args: ["telefoneComercialRepresentante2"],
                                params: ["{telefoneComercialRepresentante2}"],
                                function: (value, telefoneComercialRepresentante2) => {
                                    return telefoneComercialRepresentante2.length > 0 && value == 0 ? false : true;
                                },
                                msg: "Verifique preenchimento do campo"
                            }
                        ]
                    }
                },
                idTelefoneComercialRepresentante2: { value: props.idTelefoneComercialRepresentante2 || 0, },
                telefoneComercialRepresentante2: {
                    value: props.telefoneComercialRepresentante2 || '',
                    mask: '(99) 9999-9999',
                    description: "Telefone Comercial Representante 2",
                    validation: {
                        output: "telefoneComercialRepresentante2",
                        validators: [
                            {
                                type: "custom",
                                args: ["tipoTelefoneComercialRepresentante2"],
                                params: ["{tipoTelefoneComercialRepresentante2}"],
                                function: (value, tipoTelefoneComercialRepresentante2) => {
                                    return tipoTelefoneComercialRepresentante2 == 0 ? true :
                                        tipoTelefoneComercialRepresentante2 == 1 && value.replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length < 11 ? false :
                                            tipoTelefoneComercialRepresentante2 == 2 && value.replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length < 10 ? false : true;
                                },
                                msg: "Verifique preenchimento do campo"
                            }
                        ]
                    }
                },
                cpfRepresentante2: {
                    value: props.cpfRepresentante2 || '',
                    mask: '999.999.999-99',
                },
                rgRepresentante2: {
                    value: props.rgRepresentante2 || '',
                },
                assinaContratoRepresentante2: { value: props.assinaContratoRepresentante2 || '', },
                idPessoaRepresentante3: { value: props.idPessoaRepresentante3 || 0 },
                idPessoaDCRepresentante3: { value: props.idPessoaDCRepresentante3 || 0 },
                ruPessoaRepresentante3: { value: props.ruPessoaRepresentante3 || 0 },
                nomeRepresentante3: {
                    value: props.nomeRepresentante3 || '',
                },
                emailRepresentante3: {
                    value: props.emailRepresentante3 || '',
                },
                departamentoRepresentante3: {
                    value: props.departamentoRepresentante3 || 0,
                },
                cargoRepresentante3: {
                    value: props.cargoRepresentante3 || 0,
                },
                idTelefoneRepresentante3: { value: props.idTelefoneRepresentante3 || 0, },
                telefoneRepresentante3: {
                    mask: '(99) 99999-9999',
                    value: props.telefoneRepresentante3 || ''
                },
                tipoTelefoneComercialRepresentante3: {
                    value: props.tipoTelefoneComercialRepresentante3 || 0,
                    description: "Tipo Telefone Comercial Representante 3",
                    validation: {
                        output: "tipoTelefoneComercialRepresentante3",
                        validators: [
                            {
                                type: "custom",
                                args: ["telefoneComercialRepresentante3"],
                                params: ["{telefoneComercialRepresentante3}"],
                                function: (value, telefoneComercialRepresentante3) => {
                                    return telefoneComercialRepresentante3.length > 0 && value == 0 ? false : true;
                                },
                                msg: "Verifique preenchimento do campo"
                            }
                        ]
                    }
                },
                idTelefoneComercialRepresentante3: { value: props.idTelefoneComercialRepresentante3 || 0, },
                telefoneComercialRepresentante3: {
                    value: props.telefoneComercialRepresentante3 || '',
                    mask: '(99) 9999-9999',
                    description: "Telefone Comercial Representante 3",
                    validation: {
                        output: "telefoneComercialRepresentante3",
                        validators: [
                            {
                                type: "custom",
                                args: ["tipoTelefoneComercialRepresentante3"],
                                params: ["{tipoTelefoneComercialRepresentante3}"],
                                function: (value, tipoTelefoneComercialRepresentante3) => {
                                    return tipoTelefoneComercialRepresentante3 == 0 ? true :
                                        tipoTelefoneComercialRepresentante3 == 1 && value.replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length < 11 ? false :
                                            tipoTelefoneComercialRepresentante3 == 2 && value.replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length < 10 ? false : true;
                                },
                                msg: "Verifique preenchimento do campo"
                            }
                        ]
                    }
                },
                cpfRepresentante3: {
                    value: props.cpfRepresentante3 || '',
                    mask: '999.999.999-99',
                },
                rgRepresentante3: {
                    value: props.rgRepresentante3 || '',
                },
                assinaContratoRepresentante3: { value: props.assinaContratoRepresentante3 || '', },
                idPessoaRepresentante4: { value: props.idPessoaRepresentante4 || 0 },
                idPessoaDCRepresentante4: { value: props.idPessoaDCRepresentante4 || 0 },
                ruPessoaRepresentante4: { value: props.ruPessoaRepresentante4 || 0 },
                nomeRepresentante4: {
                    value: props.nomeRepresentante4 || '',
                },
                emailRepresentante4: {
                    value: props.emailRepresentante4 || '',
                },
                departamentoRepresentante4: {
                    value: props.departamentoRepresentante4 || 0,
                },
                cargoRepresentante4: {
                    value: props.cargoRepresentante4 || 0,
                },
                idTelefoneRepresentante4: { value: props.idTelefoneRepresentante4 || 0, },
                telefoneRepresentante4: {
                    mask: '(99) 99999-9999',
                    value: props.telefoneRepresentante4 || ''
                },
                tipoTelefoneComercialRepresentante4: {
                    value: props.tipoTelefoneComercialRepresentante4 || 0,
                    description: "Tipo Telefone Comercial epresentante 4",
                    validation: {
                        output: "tipoTelefoneComercialRepresentante4",
                        validators: [
                            {
                                type: "custom",
                                args: ["telefoneComercialRepresentante4"],
                                params: ["{telefoneComercialRepresentante4}"],
                                function: (value, telefoneComercialRepresentante4) => {
                                    return telefoneComercialRepresentante4.length > 0 && value == 0 ? false : true;
                                },
                                msg: "Verifique preenchimento do campo"
                            }
                        ]
                    }
                },
                idTelefoneComercialRepresentante4: { value: props.idTelefoneComercialRepresentante4 || 0, },
                telefoneComercialRepresentante4: {
                    value: props.telefoneComercialRepresentante4 || '',
                    mask: '(99) 9999-9999',
                    description: "Telefone Comercial Representante 4",
                    validation: {
                        output: "telefoneComercialRepresentante4",
                        validators: [
                            {
                                type: "custom",
                                args: ["tipoTelefoneComercialRepresentante4"],
                                params: ["{tipoTelefoneComercialRepresentante4}"],
                                function: (value, tipoTelefoneComercialRepresentante4) => {
                                    return tipoTelefoneComercialRepresentante4 == 0 ? true :
                                        tipoTelefoneComercialRepresentante4 == 1 && value.replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length < 11 ? false :
                                            tipoTelefoneComercialRepresentante4 == 2 && value.replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length < 10 ? false : true;
                                },
                                msg: "Verifique preenchimento do campo"
                            }
                        ]
                    }
                },
                cpfRepresentante4: {
                    value: props.cpfRepresentante4 || '',
                    mask: '999.999.999-99',
                },
                rgRepresentante4: {
                    value: props.rgRepresentante4 || '',
                },
                assinaContratoRepresentante4: { value: props.assinaContratoRepresentante4 || '', },
                idPessoaRepresentante5: { value: props.idPessoaRepresentante5 || 0 },
                idPessoaDCRepresentante5: { value: props.idPessoaDCRepresentante5 || 0 },
                ruPessoaRepresentante5: { value: props.ruPessoaRepresentante5 || 0 },
                nomeRepresentante5: {
                    value: props.nomeRepresentante5 || '',
                },
                emailRepresentante5: {
                    value: props.emailRepresentante5 || '',
                },
                departamentoRepresentante5: {
                    value: props.departamentoRepresentante5 || 0,
                },
                cargoRepresentante5: {
                    value: props.cargoRepresentante5 || 0,
                },
                idTelefoneRepresentante5: { value: props.idTelefoneRepresentante5 || 0, },
                telefoneRepresentante5: {
                    mask: '(99) 99999-9999',
                    value: props.telefoneRepresentante5 || ''
                },
                tipoTelefoneComercialRepresentante5: {
                    value: props.tipoTelefoneComercialRepresentante5 || 0,
                    description: "Tipo Telefone Comercial Representante 5",
                    validation: {
                        output: "tipoTelefoneComercialRepresentante5",
                        validators: [
                            {
                                type: "custom",
                                args: ["telefoneComercialRepresentante5"],
                                params: ["{telefoneComercialRepresentante5}"],
                                function: (value, telefoneComercialRepresentante5) => {
                                    return telefoneComercialRepresentante5.length > 0 && value == 0 ? false : true;
                                },
                                msg: "Verifique preenchimento do campo"
                            }
                        ]
                    }
                },
                idTelefoneComercialRepresentante5: { value: props.idTelefoneComercialRepresentante5 || 0, },
                telefoneComercialRepresentante5: {
                    value: props.telefoneComercialRepresentante5 || '',
                    mask: '(99) 9999-9999',
                    description: "Telefone Comercial Representante 5",
                    validation: {
                        output: "telefoneComercialRepresentante5",
                        validators: [
                            {
                                type: "custom",
                                args: ["tipoTelefoneComercialRepresentante5"],
                                params: ["{tipoTelefoneComercialRepresentante5}"],
                                function: (value, tipoTelefoneComercialRepresentante5) => {
                                    return tipoTelefoneComercialRepresentante5 == 0 ? true :
                                        tipoTelefoneComercialRepresentante5 == 1 && value.replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length < 11 ? false :
                                            tipoTelefoneComercialRepresentante5 == 2 && value.replace("(", "").replace(")", "").replace("-", "").replace(" ", "").length < 10 ? false : true;
                                },
                                msg: "Verifique preenchimento do campo"
                            }
                        ]
                    }
                },
                cpfRepresentante5: {
                    value: props.cpfRepresentante5 || '',
                    mask: '999.999.999-99',
                },
                rgRepresentante5: {
                    value: props.rgRepresentante5 || '',
                },
                assinaContratoRepresentante5: { value: props.assinaContratoRepresentante5 || '', },
                idPessoaAdmin: { value: props.idPessoaAdmin || 0 },
                idPessoaDCAdmin: { value: props.idPessoaDCAdmin || 0 },
                ruPessoaAdmin: { value: props.ruPessoaAdmin || 0 },
                nomeAdmin: {
                    value: props.nomeAdmin || '',
                    description: "Nome Administrador",
                    validation: {
                        output: "nomeAdminError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "maxLength",
                                params: [250],
                                msg: "Preencha no máximo 250 caracteres."
                            }
                        ]
                    }
                },
                emailAdmin: {
                    value: props.emailAdmin || '',
                    description: "Email Administrador",
                    validation: {
                        output: "emailAdminError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "maxLength",
                                params: [250],
                                msg: "Preencha no máximo 250 caracteres."
                            },
                            {
                                type: "email",
                                msg: "Email Inválido."
                            }
                        ]
                    }
                },
                idTelefoneAdmin: { value: props.idTelefoneAdmin || 0, },
                telefoneAdmin: {
                    value: props.telefoneAdmin || '',
                    description: "Telefone Administrador",
                    mask: '(99) 99999-9999',
                    validation: {
                        output: "telefoneAdminError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "custom",
                                function: (value) => {
                                    return removeCaracteresEspeciais(value) == 11 ? false : true;
                                },
                                msg: "Preencha no máximo 11 números."
                            }
                        ]
                    }
                },
                tipoTelefoneComercialAdmin: {
                    value: props.tipoTelefoneComercialAdmin || 0,
                    description: "Tipo Telefone Comercial Administrador",
                    validation: {
                        output: "tipotelefoneComercialAdminError",
                        validators: [
                            {
                                type: "custom",
                                args: ["telefoneComercialAdmin"],
                                params: ["{telefoneComercialAdmin}"],
                                function: (value, telefoneComercialAdmin) => {
                                    return telefoneComercialAdmin.length > 0 && value == 0 ? false : true;
                                },
                                msg: "Verifique preenchimento do campo"
                            }
                        ]
                    }
                },
                idTelefoneComercialAdmin: { value: props.idTelefoneComercialAdmin || 0, },
                telefoneComercialAdmin: {
                    value: props.telefoneComercialAdmin || '',
                    mask: '(99) 9999-9999',
                    description: "Telefone Comercial Administrador",
                    validation: {
                        output: "telefoneComercialAdminError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "custom",
                                args: ["tipoTelefoneComercialAdmin"],
                                params: ["{tipoTelefoneComercialAdmin}"],
                                function: (value, tipoTelefoneComercialAdmin) => {
                                    return tipoTelefoneComercialAdmin == 0 ? true :
                                        tipoTelefoneComercialAdmin == 1 && value.length < 15 ? false :
                                            tipoTelefoneComercialAdmin == 2 && value.length < 14 ? false : true;
                                },
                                msg: "Verifique preenchimento do campo"
                            }
                        ]
                    }
                },
                cargoAdmin: {
                    value: props.cargoAdmin || 0,
                    description: "Cargo Administrador",
                    validation: {
                        output: "cargoAdminError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            }
                        ]
                    }
                },
                departamentoAdmin: {
                    value: props.departamentoAdmin || 0,
                    description: "Departamento Administrador",
                    // validation: {
                    //     output: "departamentoAdminError",
                    //     validators: [
                    //         {
                    //             type: 'required',
                    //             msg: 'Campo obrigatório'
                    //         }
                    //     ]
                    // }
                },
                cpfAdmin: {
                    value: props.cpfAdmin || '',
                    mask: '999.999.999-99',
                    description: "CPF Administrador",
                    validation: {
                        output: "cpfAdminError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            },
                            {
                                type: "maxLength",
                                params: [15],
                                msg: "Preencha no máximo 14 caracteres."
                            },
                            {
                                type: "custom",
                                function: (value) => {
                                    return value = null || value == 0 ? true : value.length > 0 && value.replace("-", "").replace("-", "").length < 11 ? false : true;
                                },
                                msg: "CPF inválido"
                            }
                        ]
                    }
                },
                rgAdmin: {
                    value: props.rgAdmin || '',
                    description: "RG Administrador",
                    validation: {
                        output: "rgAdminError",
                        validators: [
                            {
                                type: 'required',
                                msg: 'Campo obrigatório'
                            }
                        ]
                    }
                },
                adminRepresentante: {
                    value: props.adminRepresentante || '',
                },
                recebeEmailNotaAdm: {
                    value: props.recebeEmailNotaAdm || '',
                },
                recebeEmailBoletoAdm: {
                    value: props.recebeEmailBoletoAdm || '',
                },
                recebeEmailNota: {
                    value: props.recebeEmailNota || '',
                },
                recebeEmailBoleto: {
                    value: props.recebeEmailBoleto || '',
                },
                recebeEmailNotaRepresentante2: {
                    value: props.recebeEmailNotaRepresentante2 || '',
                },
                recebeEmailBoletoRepresentante2: {
                    value: props.recebeEmailBoletoRepresentante2 || '',
                },
                recebeEmailNotaRepresentante3: {
                    value: props.recebeEmailNotaRepresentante3 || '',
                },
                recebeEmailBoletoRepresentante3: {
                    value: props.recebeEmailBoletoRepresentante3 || '',
                },
                recebeEmailNotaRepresentante4: {
                    value: props.recebeEmailNotaRepresentante4 || '',
                },
                recebeEmailBoletoRepresentante4: {
                    value: props.recebeEmailBoletoRepresentante4 || '',
                },
                recebeEmailNotaRepresentante5: {
                    value: props.recebeEmailNotaRepresentante5 || '',
                },
                recebeEmailBoletoRepresentante5: {
                    value: props.recebeEmailBoletoRepresentante5 || '',
                },
                representanteEscolha: {
                    value: props.representanteEscolha || '',
                },
                representanteEscolhaCpf: {
                    value: props.representanteEscolhaCpf || '',
                },
                assinaContrato: { value: props.assinaContrato || '', },
                idModeloContrato: { value: props.idModeloContrato, },

                habilitacep: { value: props.habilitacep || false, },
                habilitaDadosPessoais: { value: props.habilitaDadosPessoais || false, },
                habilitaDadosEmpresa: { value: props.habilitaDadosEmpresa || false, },
                habilitaDadosAdmin: { value: props.habilitaDadosAdmin || false, },
                habilitaDadosR1: { value: props.habilitaDadosR1 || false, },

                listModelos: { value: props.listModelos || [{ codigo: 0, descricao: 'SELECIONE' }], },
                listTiposContrato: { value: props.listTiposContrato || [{ codigo: 0, descricao: 'SELECIONE' }], },
                listTiposDocumentos: { value: props.listTiposDocumentos || [{ codigo: 0, descricao: 'SELECIONE' }], },
                listDepartamento: { value: props.listDepartamento || [] },
                listCargo: { value: props.listCargo || [] },
                listEstado: { value: props.listEstado || [{ codigo: 0, descricao: 'SELECIONE' }], },
                listCidade: { value: props.listCidade || [{ codigo: 0, descricao: 'SELECIONE' }], },
                listPolo: { value: props.listPolo || [{ codigo: 0, descricao: 'SELECIONE' }], },
                listResponsaveis: { value: props.listResponsaveis || [], },
            },
            showModal: false,
            loading: true,
            errors: []
        }

        this._onChangeForm = this._onChangeForm.bind(this);
        this._handleConvenioChange = this._handleConvenioChange.bind(this);
        this._handleTipoEmpresaChange = this._handleTipoEmpresaChange.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._handleCepChange = this._handleCepChange.bind(this);
        this._handleCnpjChange = this._handleCnpjChange.bind(this);
        this._handleCPFChange = this._handleCPFChange.bind(this);
        this._handleCPFAdminChange = this._handleCPFAdminChange.bind(this);
        this._handleResponsavelChange = this._handleResponsavelChange.bind(this);
        this.multifileInputRef = React.createRef();
        this.handleChangeMultiInputFile = this.handleChangeMultiInputFile.bind(this);
        this._handleArquivoChange = this._handleArquivoChange.bind(this);
        this.onClickExcluir = this.onClickExcluir.bind(this);
        this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        // this._handleDepartamentoChange = this._handleDepartamentoChange(this);
        // this._handleDepartamentoAdminChange = this._handleDepartamentoAdminChange(this);
        // this._handleCargoChange = this._handleCargoChange(this);
        // this._handleCargoAdminChange = this._handleCargoAdminChange(this);
    }

    _handleDepartamentoChange = async (event) => {
        const { form } = this.state;
        form.departamentoRepresentante.value = event.value;
        this.setState({ form });
    }

    _handleDepartamentoAdminChange = async (event) => {
        const { form } = this.state;
        form.departamentoAdmin.value = event.value;
        this.setState({ form });
    }

    _handleCargoChange = async (event) => {
        const { form } = this.state;
        form.cargoRepresentante.value = event.value;
        this.setState({ form });
    }

    _handleEstadoChange = async (event) => {
        const { form } = this.state;
        form.estadopolo.value = event.value;
        form.listCidade.value = await enderecoService.getCidades(event.value);
        this.setState({ form });
    }

    _handleCidadeChange = async (event) => {
        const { form } = this.state;
        var obj = {
            estado: form.estadopolo.value,
            cidade: event.label
        };
        form.listPolo.value = await solicitacaoService.getPolos(obj);
        this.setState({ form });
    }

    _handlePoloChange = async (event) => {
        const { form } = this.state;
        form.idpolo.value = event.value;
        form.polo.value = event.label;
        this.setState({ form });
    }

    _handleResponsavelChangeSet = async (nomeResponsavel, codigoResponsavel) => {
        const { form } = this.state;

        var indexItem = form.listResponsaveis.value.map(function (e) {
            return e.codigo;
        }).indexOf(codigoResponsavel);

        if (indexItem > -1)
            form.listResponsaveis.value.splice(indexItem, 1)

        if (!isNullOrEmptyOrWhiteSpaceOrUndefined(nomeResponsavel))
            form.listResponsaveis.value.push({ descricao: nomeResponsavel, codigo: codigoResponsavel, })

        this.setState({ form });
    }

    _handleResponsavelChange = async (event) => {
        var result = await this._handleResponsavelChangeSet(event.target.value.toUpperCase(), event.currentTarget.id)
    }

    _handleCargoAdminChange = async (event) => {
        const { form } = this.state;
        form.cargoAdmin.value = event.value;
        this.setState({ form });
    }

    componentDidMount() {
        this.loadForm();
    }

    async loadForm() {

        //Verificação recaptcha
        if (enviromment.ambiente !== "PROD")
            this.setState({ isVerified: true })

        const {
            idSolicitacao
        } = this.props;
        var form = this.state.form;
        form.tipoEmpresa.value = 0;
        form.numeroColaboradores.value = 0;
        form.controleColaboradores.value = 0;
        form.permiteOfertarCursos.value = 0;
        form.adminRepresentante.value = 0;
        
        form.recebeEmailNotaAdm.value = 0;
        form.recebeEmailBoletoAdm.value = 0;

        form.recebeEmailNota.value = 0;
        form.recebeEmailBoleto.value = 0;

        form.recebeEmailNotaRepresentante2.value = 0;
        form.recebeEmailBoletoRepresentante2.value = 0;

        form.recebeEmailNotaRepresentante3.value = 0;
        form.recebeEmailBoletoRepresentante3.value = 0;

        form.recebeEmailNotaRepresentante4.value = 0;
        form.recebeEmailBoletoRepresentante4.value = 0;

        form.recebeEmailNotaRepresentante5.value = 0;
        form.recebeEmailBoletoRepresentante5.value = 0;

        form.complemento.value = "";
        form.showModalBlackList.value = false;
        form.isArquivoRequired.value = false;

        form.listModelos.value = await contratoService.OnGetModelos();
        form.listTiposContrato.value = await contratoService.OnGetTipos();
        form.listTiposDocumentos.value = await documentoService.OnGetTipos();
        form.listDepartamento.value = await departamentoService.OnGetListaCodigoDescricao();
        form.listCargo.value = await cargoService.OnGetListaCodigoDescricao();
        form.listEstado.value = await enderecoService.getUFs();

        this.setState({ form, loading: false });

        // document.getElementById("pagamento").disabled = true;
        document.getElementById("desconto").disabled = true;
    }

    /* multiupload*/
    handleUploadDemand = (ie: ChangeEvent<HTMLInputElement>) => {
        const fileList: FileList = ie.target.files;
        // do something with the FileList, for example:
        const fileReader = new FileReader();
        fileReader.onload = () => {
            const str = String(fileReader.result);
            try {
            } catch (error) {
            }
        };
        fileReader.readAsBinaryString(fileList[0])
    }
    handleChangeMultiInputFile(selectorFiles: FileList) {
        const { form } = this.state;
        var texto = "";
        let validos = /(\.jpg|\.png|\.gif|\.jpeg|\.pdf|\.doc|\.docx)$/i;
        var newFileList = Array.from(selectorFiles)
        for (var i = 0; i < selectorFiles.length; i++) {
            if (!validos.test(selectorFiles[i].name)) {
                newFileList.splice(i, 1);
                toast.warn("Tipo de arquivo selecionado não é permitido. O arquivo [" + selectorFiles[i].name + "] será removido.");
            }
            else {
                if (texto === "")
                    texto = selectorFiles[i].name;
                else
                    texto += ", " + selectorFiles[i].name;

                if (texto.length > 14) {
                    var textoReduzido = texto.substring(0, 14) + "...";
                    document.getElementById('inputGroupFile012').innerHTML = textoReduzido;
                }
                else
                    document.getElementById('inputGroupFile012').innerHTML = texto;

                document.getElementById('inputGroupFile012').title = texto;
            }
        }
        if (texto !== "")
            document.getElementById('basic-addon3').innerHTML = "Alterar";
        else
            document.getElementById('basic-addon3').innerHTML = "Buscar";

        selectorFiles = newFileList;
        form.arquivoNovo.value = newFileList;
        this.setState({ form });
    }

    handleClick() {
        this.multifileInputRef.current.click();
    }

    addClick = async (event) => {
        const { form } = this.state;
        var returnTypeFile = function (name) {
            let validos = /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx)$/i;
            if (validos.test(name)) {
                validos = /(\.jpg)$/i;
                if (validos.test(name)) {
                    return 'jpg';
                }
                validos = /(\.png)$/i;
                if (validos.test(name)) {
                    return 'png';
                }
                validos = /(\.jpeg)$/i;
                if (validos.test(name)) {
                    return 'jpeg';
                }
                validos = /(\.doc)$/i;
                if (validos.test(name)) {
                    return 'doc';
                }
                validos = /(\.pdf)$/i;
                if (validos.test(name)) {
                    return 'pdf';
                }
                validos = /(\.docx)$/i;
                if (validos.test(name)) {
                    return 'docx';
                }
            }
        }
        var checkArquivo = function (value) {
            return (value.arquivo.name == form.arquivoNovo.value[0].name &&
                value.arquivo.type == form.arquivoNovo.value[0].type &&
                value.arquivo.size == form.arquivoNovo.value[0].size);
        }
        if (form.tipoArquivo.value === 0) {
            toast.warn('Selecione o tipo de Arquivo.');
        } else
            if ((form.tipoDocumento.value === 0 || form.tipoDocumento.value === "0") && form.tipoArquivo.value == 2) {
                toast.warn('Selecione o tipo de Documento.');
            }
            else if (form.arquivoNovo == undefined || form.arquivoNovo.value[0] == undefined) { toast.warn('Selecione o Arquivo.'); }
            else {
                if (form.lista.value !== undefined && form.lista.value.length > 0 && form.lista.value.filter(checkArquivo).length > 0)
                    toast.warn("Não é permitido adicionar o mesmo arquivo mais de uma vez.");
                else {
                    var count = form.lista.value.length == 0 ? 1 : form.lista.value.length + 1;
                    var tipoDoc = form.tipoArquivo.value == 1 ? '---' : document.getElementById('tipoDocumento').options[document.getElementById('tipoDocumento').selectedIndex].text;
                    var idTipoDoc = form.tipoArquivo.value == 1 ? undefined : form.tipoDocumento.value;
                    var type = returnTypeFile(form.arquivoNovo.value[0].name) || '';
                    form.lista.value.push({ name: form.arquivoNovo.value[0].name, extensao: type, tipo: tipoDoc, idTipo: idTipoDoc, arquivo: form.arquivoNovo.value[0], id: count, idTipoArquivo: form.tipoArquivo.value, tipoArquivo: form.tipoArquivo.value == 1 ? 'Contrato' : 'Outros Documentos', representante: form.representanteEscolha.value.trim(), representanteCpf: form.representanteEscolhaCpf.value.trim() });
                    form.arquivoNovo.value[0] = undefined;

                    if (form.tipoConvenio.value == 2) {
                        var countArquivo = 0;

                        if (form.lista.value.length > 0) {
                            form.lista.value.forEach(element => {
                                if (element.idTipo === idTipoDoc && element.representante.toUpperCase() === form.representanteEscolha.value.toUpperCase()) {
                                    // tipo contrato social/estatuto somente para pessoa juríca
                                    if (element.idTipo == 4 && element.representante !== form.razaoSocial.value) {
                                        form.lista.value.pop();
                                        toast.error(`Tipo contrato social/estatuto somente para Pessoa Jurídica.`)
                                    }
                                    // tipo CPF ou RG somente para pessoa física
                                    if ((element.idTipo == 5 || element.idTipo == 6) && element.representante === form.razaoSocial.value) {
                                        form.lista.value.pop();
                                        toast.error(`Tipo CPF ou RG somente para Pessoa Física.`)
                                    }
                                    countArquivo++;
                                }
                                if (countArquivo > 1) {
                                    form.lista.value.pop();
                                    toast.error(`Arquivo já adicionado para o responsável selecionado.`)
                                }
                            });
                        }
                    }
                    this.setState({ form });
                    this.handleDrop();
                    // document.getElementById('basic-addon5').click();
                }
            }
    }
    onChangeTipoDocumento = async (event) => {
        const { form } = this.state;

        if (event == 'cleanSelect') {
            form.tipoDocumento.value = 0;
            document.querySelector('#tipoDocumento').value = '';
        }
        else 
            form.tipoDocumento.value = event.target.value;

        this.setState({ form });
    }

    onChangeResponsavel = async (event) => {
        const { form } = this.state;
        let responsavel = event.target.value;
        let listDocFiltered = await documentoService.OnGetTipos(form.tipoConvenio.value);
       
        this.onChangeTipoDocumento('cleanSelect');

        if (responsavel === '0') {
            form.listTiposDocumentos.value = listDocFiltered;
        }
        else if (responsavel == 'razaoSocial') {
            form.listTiposDocumentos.value = listDocFiltered.filter(doc => doc.codigo == '4');
        }
        else {
            form.listTiposDocumentos.value = listDocFiltered.filter(doc => doc.codigo != '4');
        }

        if (responsavel !== '0') {
            form.representanteEscolha.value = document.getElementById(responsavel).value;

            switch (responsavel) {
                case 'razaoSocial':
                    form.representanteEscolhaCpf.value = document.getElementById('cnpj').value;
                    break;
                case 'nomeAdmin':
                    form.representanteEscolhaCpf.value = document.getElementById('cpfAdmin').value;
                    break;
                case 'nomeRepresentante':
                    form.representanteEscolhaCpf.value = document.getElementById('cpfRepresentante').value;
                    break;
                case 'nomeRepresentante2':
                    form.representanteEscolhaCpf.value = document.getElementById('cpfRepresentante2').value;
                    break;
                case 'nomeRepresentante3':
                    form.representanteEscolhaCpf.value = document.getElementById('cpfRepresentante3').value;
                    break;
                case 'nomeRepresentante4':
                    form.representanteEscolhaCpf.value = document.getElementById('cpfRepresentante4').value;
                    break;
                case 'nomeRepresentante5':
                    form.representanteEscolhaCpf.value = document.getElementById('cpfRepresentante5').value;
                    break;
                default:
                    form.representanteEscolhaCpf.value = '';
                    break;
            }
        }
        else {
            form.representanteEscolha.value = '';
            form.representanteEscolhaCpf.value = '';
        }
        this.setState({ form });
    }

    handleDrop() {
        document.getElementById('idMultiplosArquivos').value = '';
        document.getElementById('basic-addon3').innerHTML = "Buscar";
        document.getElementById('inputGroupFile012').innerHTML = "Selecione um arquivo";
    }

    onClickExcluir = async (event) => {
        const { form } = this.state;
        if (event.currentTarget.accessKey !== undefined &&
            event.currentTarget.accessKey !== null &&
            event.currentTarget.accessKey !== '' &&
            event.currentTarget.accessKey !== '0') {
            var index = form.lista.value.map(function (o) { return o.id; }).indexOf(parseInt(event.currentTarget.accessKey));
            form.lista.value.splice(index, 1);
            this.setState({ form });
        }
    }

    recaptchaLoaded() {
        console.log('captcha carregado');
    }

    verifyCallback(response) {
        if (response) {
            this.setState({
                isVerified: true
            })
        }
    }

    _handleModeloContratoChange = async (event) => {
        const { form } = this.state;
        form.IdModeloContrato.value = parseInt(event.target.value);
        this.setState({ form, IdModeloContrato: event })
    }
    /*multiupload*/

    _handleTipoEmpresaChange(event) {
        const { form } = this.state;
        form.lista.value = [];

        if (event.target.checked && event.target.value !== undefined) {
            form.tipoEmpresa.value = parseInt(event.target.value);
            if (event.target.value == 1) {
                form.isArquivoRequired.value = false;
                //document.getElementById("pagamento").disabled = false;
                document.getElementById("desconto").disabled = false;
            }
            else {
                form.isArquivoRequired.value = true;
                setTimeout(() => {
                    document.getElementById("desconto").disabled = false;
                    //document.getElementById("pagamento").disabled = true;
                    document.getElementById("desconto").click();
                }, 0);
            }
            // form.isArquivoRequired.value = false;
            // setTimeout(() => {
            //     document.getElementById("desconto").disabled = false;
            //     document.getElementById("desconto").click();
            // }, 0);
        }
        this.setState({ form, tipoEmpresa: event })
        this.setState({ form, tipoConvenio: event })
    }

    isPublicCompany() {
        const { form } = this.state;
        return form.isArquivoRequired.value;
    }

    async _handleConvenioChange(event) {
        const { form } = this.state;
        form.lista.value = [];

        if (event.target.checked && event.target.value !== undefined)
            form.tipoConvenio.value = parseInt(event.target.value);
        if (event.target.value == '2') {
            form.listTiposDocumentos.value = await documentoService.OnGetTipos(form.tipoConvenio.value);
        }
        else
            form.listTiposDocumentos.value = await documentoService.OnGetTipos();

        this.setState({ form, tipoConvenio: event })
    }

    _handleArquivoChange(event) {
        const { form } = this.state;
        if (event.target.checked && event.target.value !== undefined)
            form.tipoArquivo.value = parseInt(event.target.value);

        this.setState({ form, tipoArquivo: event })
    }

    onClickTipoTelefoneAdmin = async (event) => {
        const { form } = this.state;
        if (event.target.value == '1')
            form.telefoneComercialAdmin.mask = "(99) 99999-9999";
        else
            form.telefoneComercialAdmin.mask = "(99) 9999-9999";
        this.setState({ form });
    }

    onClickTipoTelefoneRepresentante = async (event) => {
        const { form } = this.state;
        if (event.target.value == '1') {
            form.telefoneComercialRepresentante.mask = "(99) 99999-9999";
            form.telefoneComercialRepresentante.maxLength = "15";
        }
        else {
            form.telefoneComercialRepresentante.mask = "(99) 9999-9999";
            form.telefoneComercialRepresentante.maxLength = "14";
        }
        this.setState({ form });
    }

    onClickTipoTelefoneRepresentante2 = async (event) => {
        const { form } = this.state;
        if (event.target.value == '1') {
            form.telefoneComercialRepresentante2.mask = "(99) 99999-9999";
            form.telefoneComercialRepresentante2.maxLength = "15";
        }
        else {
            form.telefoneComercialRepresentante2.mask = "(99) 9999-9999";
            form.telefoneComercialRepresentante2.maxLength = "14";
        }
        this.setState({ form });
    }

    onClickTipoTelefoneRepresentante3 = async (event) => {
        const { form } = this.state;
        if (event.target.value == '1') {
            form.telefoneComercialRepresentante3.mask = "(99) 99999-9999";
            form.telefoneComercialRepresentante3.maxLength = "15";
        }
        else {
            form.telefoneComercialRepresentante3.mask = "(99) 9999-9999";
            form.telefoneComercialRepresentante3.maxLength = "14";
        }
        this.setState({ form });
    }

    onClickTipoTelefoneRepresentante4 = async (event) => {
        const { form } = this.state;
        if (event.target.value == '1') {
            form.telefoneComercialRepresentante4.mask = "(99) 99999-9999";
            form.telefoneComercialRepresentante4.maxLength = "15";
        }
        else {
            form.telefoneComercialRepresentante4.mask = "(99) 9999-9999";
            form.telefoneComercialRepresentante4.maxLength = "14";
        }
        this.setState({ form });
    }

    onClickTipoTelefoneRepresentante5 = async (event) => {
        const { form } = this.state;
        if (event.target.value == '1') {
            form.telefoneComercialRepresentante5.mask = "(99) 99999-9999";
            form.telefoneComercialRepresentante5.maxLength = "15";
        }
        else {
            form.telefoneComercialRepresentante5.mask = "(99) 9999-9999";
            form.telefoneComercialRepresentante5.maxLength = "14";
        }
        this.setState({ form });
    }


    buscarDadosForm() {
        const { form } = this.state;
        return {
            Solicitacao: {
                IdSolicitacao: null,
                PoloId: form.idpolo.value,
                Polo: form.polo.value,
                Consultor: form.gestor.value.trim(),
                IdEmpresa: form.idEmpresa.value,
                IdSituacaoSolicitacao: 1,
                IdTipoConvenio: form.tipoConvenio.value,
                ControlarFuncionarios: form.controleColaboradores.value,
                PermiteOfertarCursos: form.permiteOfertarCursos.value,
                DataCriacao: null,
                DataModificacao: null,
                IdUsuarioCriador: null,
                IdUsuarioModificador: null,
                Ativo: true,
                Excluido: false
            }
            , Empresa: {
                Id: form.idEmpresa.value,
                RazaoSocial: form.razaoSocial.value.trim(),
                NomeFantasia: (form.nomeFantasia.value == '' || undefined) ? form.razaoSocial.value : form.nomeFantasia.value.trim(),
                Cnpj: form.cnpj.value.trim(),
                InscricaoEstadual: form.inscricaoEstadual.value.trim(),
                Site: form.site.value.trim(),
                QtdeFuncionarios: (form.numeroColaboradores.value == '' || undefined) ? 0 : form.numeroColaboradores.value.trim(),
                IdTipoEmpresa: form.tipoEmpresa.value,
                RU: form.ru.value,
                // IdEmpresaMatriz: null,
                IdEndereco: form.idEndereco.value,
                Email: form.emailEmpresa.value != "" || form.emailEmpresa.value != null ? form.emailEmpresa.value.trim() : "",
                // Id: null,
                // DataCriacao: null,
                // DataModificacao: null,
                // IdUsuarioCriador: null,
                // IdUsuarioModificador: null,
                // Ativo: true,
                // Excluido: false
            }
            , Endereco: {
                TipoEndereco: 0,
                Logradouro: form.rua.value.trim(),
                Complemento: form.complemento.value.trim(),
                Cep: form.cep.value.trim(),
                //Pais: '',
                Uf: form.uf.value.trim(),
                Cidade: form.cidade.value.trim(),
                Bairro: form.bairro.value.trim(),
                Numero: form.numero.value.trim(),
                IdCidade: form.idCidade.value,
                IdEmpresa: form.idEmpresa.value,
                IdZipCode: form.idZipCode.value,
                IdUf: form.uf.value,
            }
            , Representante: {
                IdPessoaDadosCadastrais: form.idPessoaDCRepresentante.value,
                Ru: form.ruPessoaRepresentante.value,
                Nome: form.nomeRepresentante.value.trim(),
                NomeSocial: form.nomeRepresentante.value.trim(),
                DataNascimento: null,
                IdCargo: form.cargoRepresentante.value,
                IdPerfil: 6,
                IdDepartamento: 3,
                Email: form.emailRepresentante.value.trim(),
                Celular: form.telefoneRepresentante.value.trim(),
                Comercial: form.telefoneComercialRepresentante.value.trim(),
                Cpf: form.cpfRepresentante.value.trim(),
                Rg: form.rgRepresentante.value.trim(),
                Id: form.idPessoaRepresentante.value,
                RecebeEmailNota: form.recebeEmailNota.value,
                RecebeEmailBoleto: form.recebeEmailBoleto.value,
            }
            , Representante2: {
                IdPessoaDadosCadastrais: form.idPessoaDCRepresentante2.value,
                Ru: form.ruPessoaRepresentante2.value,
                Nome: form.nomeRepresentante2.value.trim(),
                NomeSocial: form.nomeRepresentante2.value.trim(),
                DataNascimento: null,
                IdCargo: form.cargoRepresentante.value,
                IdPerfil: 6,
                IdDepartamento: 3,
                Email: form.emailRepresentante2.value.trim(),
                Celular: form.telefoneRepresentante2.value.trim(),
                Comercial: form.telefoneComercialRepresentante2.value.trim(),
                Cpf: form.cpfRepresentante2.value.trim(),
                Rg: form.rgRepresentante2.value.trim(),
                Id: form.idPessoaRepresentante2.value,
                RecebeEmailNota: form.recebeEmailNotaRepresentante2.value,
                RecebeEmailBoleto: form.recebeEmailBoletoRepresentante2.value,
            }
            , Representante3: {
                IdPessoaDadosCadastrais: form.idPessoaDCRepresentante3.value,
                Ru: form.ruPessoaRepresentante3.value,
                Nome: form.nomeRepresentante3.value.trim(),
                NomeSocial: form.nomeRepresentante3.value.trim(),
                DataNascimento: null,
                IdCargo: form.cargoRepresentante.value,
                IdPerfil: 6,
                IdDepartamento: 3,
                Email: form.emailRepresentante3.value.trim(),
                Celular: form.telefoneRepresentante3.value.trim(),
                Comercial: form.telefoneComercialRepresentante3.value.trim(),
                Cpf: form.cpfRepresentante3.value.trim(),
                Rg: form.rgRepresentante3.value.trim(),
                Id: form.idPessoaRepresentante3.value,
                RecebeEmailNota: form.recebeEmailNotaRepresentante3.value,
                RecebeEmailBoleto: form.recebeEmailBoletoRepresentante3.value,
            }
            , Representante4: {
                IdPessoaDadosCadastrais: form.idPessoaDCRepresentante4.value,
                Ru: form.ruPessoaRepresentante4.value,
                Nome: form.nomeRepresentante4.value.trim(),
                NomeSocial: form.nomeRepresentante4.value.trim(),
                DataNascimento: null,
                IdCargo: form.cargoRepresentante.value,
                IdPerfil: 6,
                IdDepartamento: 3,
                Email: form.emailRepresentante4.value.trim(),
                Celular: form.telefoneRepresentante4.value.trim(),
                Comercial: form.telefoneComercialRepresentante4.value.trim(),
                Cpf: form.cpfRepresentante4.value.trim(),
                Rg: form.rgRepresentante4.value.trim(),
                Id: form.idPessoaRepresentante4.value,
                RecebeEmailNota: form.recebeEmailNotaRepresentante4.value,
                RecebeEmailBoleto: form.recebeEmailBoletoRepresentante4.value,
            }
            , Representante5: {
                IdPessoaDadosCadastrais: form.idPessoaDCRepresentante5.value,
                Ru: form.ruPessoaRepresentante5.value,
                Nome: form.nomeRepresentante5.value.trim(),
                NomeSocial: form.nomeRepresentante5.value.trim(),
                DataNascimento: null,
                IdCargo: form.cargoRepresentante.value,
                IdPerfil: 6,
                IdDepartamento: 3,
                Email: form.emailRepresentante5.value.trim(),
                Celular: form.telefoneRepresentante5.value.trim(),
                Comercial: form.telefoneComercialRepresentante5.value.trim(),
                Cpf: form.cpfRepresentante5.value.trim(),
                Rg: form.rgRepresentante5.value.trim(),
                Id: form.idPessoaRepresentante5.value,
                RecebeEmailNota: form.recebeEmailNotaRepresentante5.value,
                RecebeEmailBoleto: form.recebeEmailBoletoRepresentante5.value,
            }
            , Administrador: {
                IdPessoaDadosCadastrais: form.idPessoaDCAdmin.value,
                Ru: form.ruPessoaAdmin.value,
                Nome: form.nomeAdmin.value.trim(),
                NomeSocial: form.nomeAdmin.value.trim(),
                DataNascimento: null,
                IdCargo: form.cargoAdmin.value,
                IdPerfil: 2,
                IdDepartamento: 3,
                Email: form.emailAdmin.value.trim(),
                Celular: form.telefoneAdmin.value.trim(),
                Comercial: form.telefoneComercialAdmin.value.trim(),
                Cpf: form.cpfAdmin.value.trim(),
                Rg: form.rgAdmin.value.trim(),
                Id: form.idPessoaAdmin.value,
                IsRepresentante: form.adminRepresentante.value,
                RecebeEmailNota: form.recebeEmailNotaAdm.value,
                RecebeEmailBoleto: form.recebeEmailBoletoAdm.value,
                // DataCriacao: null,
                // DataModificacao: null,
                // IdUsuarioCriador: null,
                // IdUsuarioModificador: null,
                Ativo: true,
                Excluido: false
            }
        }
    }

    montarJsonContrato(data) {
        const { form } = this.state;
        return {
            IdContrato: 0,
            Caminho: data,
            DataInicioVigencia: null,
            DataFimVigencia: null,
            IdTipoContrato: 1, /*Contrato próprio*/
            IdModeloContrato: null,
            IdSolicitacao: form.idSolicitacao.value,
            QtdFuncionarios: null
        }
    }

    atualizaSolicitacao(value) {
        const { form } = this.state;
        form.idSolicitacao.value = value;
        this.setState({ form, idSolicitacao: value });
    }

    _handleCepChange = async (event) => {
        const { form } = this.state;
        if (event.target.value === "00000-000") {
            form.cep.value = "";
            form.cidade.value = "";
            form.idCidade.value = "";
            form.uf.value = "";
            form.rua.value = "";
            form.bairro.value = "";
            form.habilitacep = false;
            this.setState({ form })
        }
        if (event.target.value !== undefined &&
            event.target.value !== null &&
            event.target.value !== "" &&
            event.target.value !== 0 &&
            event.target.value !== "00000-000" &&
            event.target.value.length === 9) {
            this.setState({ loading: true });
            const res = await enderecoService.getCep(event.target.value);

            form.cidade.value = res.cidade;
            form.uf.value = res.uf;
            form.rua.value = res.logradouro;
            form.bairro.value = res.bairro;
            form.idCidade.value = res.idCidade;
            form.habilitacep = res.bairro == '' || res.bairro == null;
            this.setState({ form, loading: false })

            if (typeof (res.id) == 'undefined') {
                toast.warn("CEP não encontrado");
            }
        }
    }

    _handleCnpjChange = async (event) => {
        const { form } = this.state;
        form.habilitaDadosEmpresa.value = false;
        if (event.target.value === "00.000.000/0000-00") {
            form.razaoSocial.value = "";
            form.cnpj.value = "";
            form.nomeFantasia.value = "";
            form.inscricaoEstadual.value = "";
            form.site.value = "";
            form.habilitaDadosEmpresa.value = false;
            this.setState({ form, loading: false })
        }
        if (event.target.value !== undefined &&
            event.target.value !== null &&
            event.target.value !== "" &&
            event.target.value !== 0 &&
            event.target.value !== "00.000.000/0000-00" &&
            event.target.value.length === 18) {
            this.setState({ loading: true });
            const res = await solicitacaoService.getDadosPorCNPJ(event.target.value.replace('.', '').replace('.', '').replace('/', '').replace('-', ''));
            if (res.success === true && res.data !== null && res.data !== undefined) {

                if (res.data.idSolicitacao !== null && res.data.idSolicitacao !== undefined && res.data.idSolicitacao !== 0) {
                    toast.warn("Empresa já cadastrada e com solicitação realizada. Entre com o login e senha ou faça a solicitação dos seus dados de acesso junto ao setor comercial da Uninter S/A.");
                    document.getElementById('linkLogin').click();
                }
                else {
                    if (res.data.empresa !== undefined && res.data.empresa !== null) {
                        form.idEmpresa.value = res.data.empresa.Id || 0;
                        form.razaoSocial.value = res.data.empresa.razaoSocial || '';
                        form.nomeFantasia.value = res.data.empresa.nomeFantasia || '';
                        form.inscricaoEstadual.value = res.data.empresa.inscricaoEstadual || '';
                        form.site.value = res.data.empresa.site || '';
                        form.idZipCode.value = res.data.empresa.zipCodeId || '';
                        form.idCidade.value = res.data.empresa.cidadeId || 0;
                        form.cidade.value = res.data.empresa.cidade || '';
                        form.uf.value = res.data.empresa.uf || '';
                        form.bairro.value = res.data.empresa.bairro || '';
                        form.complemento.value = res.data.empresa.complemento || '';
                        form.numero.value = res.data.empresa.numero || '';
                        form.rua.value = res.data.empresa.logradouro || '';
                        form.cep.value = res.data.empresa.cep || '';
                        form.numeroColaboradores.value = res.data.empresa.qtdeFuncionarios || 0;
                        // form.tipoEmpresa.value = res.data.empresa.idTipoEmpresa || 0;
                        form.ru.value = res.data.empresa.ru || 0;

                        form.habilitaDadosEmpresa.value = (res.data.empresa.razaoSocial != "" && res.data.empresa.razaoSocial != null);
                    }
                }
            }
            else {
                form.razaoSocial.value = "";
                form.nomeFantasia.value = "";
                form.inscricaoEstadual.value = "";
                form.site.value = "";
                form.idZipCode = 0;
                form.habilitaDadosEmpresa.value = false;
            }

            this.setState({ form, loading: false })
        }
    }

    _handleCPFChange = async (event) => {
        const { form } = this.state;
        var id = event.target.id;
        var cpfInformado = event.target.value.substr(0, 14);
        if (cpfInformado === "000.000.000-00"
            || cpfInformado == ''
            || cpfInformado == undefined
            || cpfInformado == null
            || cpfInformado == 0) {
            form.cpfRepresentante.value = '';
            form.nomeRepresentante.value = '';
            form.emailRepresentante.value = '';
            form.telefoneRepresentante.value = '';
            form.telefoneComercialRepresentante.value = '';
            form.idTelefoneRepresentante.value = 0;
            form.idTelefoneComercialRepresentante.value = 0;
            form.idPessoaRepresentante.value = 0;
            form.habilitaDadosR1.value = false;
            this.setState({ form })
        }
        if (cpfInformado !== undefined &&
            cpfInformado !== null &&
            cpfInformado !== "" &&
            cpfInformado !== 0 &&
            cpfInformado !== "000.000.000-00" &&
            cpfInformado.length === 14) {
            if (form.cpfAdmin.value == form.cpfRepresentante.value) {
                var cpf = cpfInformado;
                form.cpfAdmin.value = '';
                form.nomeAdmin.value = '';
                form.emailAdmin.value = '';
                form.telefoneAdmin.value = '';
                form.telefoneComercialAdmin.value = '';
                form.idTelefoneAdmin.value = 0;
                form.idTelefoneComercialAdmin.value = 0;
                form.idPessoaAdmin.value = 0;
                form.cpfAdmin.ref.current.focus();
                form.habilitaDadosR1.value = false;
                this.setState({ form, loading: false });
                toast.warn("O CPF informado [" + cpf + "] foi informado no campo do cpf do Representante.");
            }
            else {
                this.setState({ loading: true });
                const res = await solicitacaoService.getDadosPorCPF(cpfInformado.replace('.', '').replace('.', '').replace('/', '').replace('-', ''));
                if (res.success === true && res.data !== null && res.data !== undefined) {
                    if (res.data.user !== null) {
                        form.nomeRepresentante.value = res.data.nome || res.data.user.nome || '';
                        form.emailRepresentante.value = res.data.email || res.data.user.email || '';

                        if (res.data.user.cdPhoneCelular != null) {
                            form.telefoneRepresentante.value = res.data.user.celular || '';
                            form.idTelefoneRepresentante.value = res.data.user.cdPhoneCelular || 0;
                        } else {
                            form.telefoneRepresentante.value = '';
                            form.idTelefoneRepresentante.value = 0;
                        }
                        if (res.data.user.cdPhoneComercial != null) {
                            if (res.data.user.comercial.length == 10)
                                document.getElementById("FixoRepresentante").click();
                            else
                                document.getElementById("CelularRepresentante").click();
                            form.tipoTelefoneComercialRepresentante.value = res.data.user.comercial.length == 10 ? 2 : 1;
                            form.telefoneComercialRepresentante.value = res.data.user.comercial || '';
                            form.idTelefoneComercialRepresentante.value = res.data.user.cdPhoneComercial || 0;
                        } else {
                            form.tipoTelefoneComercialRepresentante.value = 0;
                            form.telefoneComercialRepresentante.value = '';
                            form.idTelefoneComercialRepresentante.value = 0;
                        }
                        form.ruPessoaRepresentante.value = res.data.user.ru || 0;
                        form.habilitaDadosR1.value = !(res.data.nome != '' && res.data.nome != null && res.data.user.nome != '' && res.data.user.nome != null);

                        this._handleResponsavelChangeSet(form.nomeRepresentante.value, 'nomeRepresentante');
                    }

                    if (res.data.id > 0) {
                        form.nomeRepresentante.value = res.data.nome || '';
                        form.emailRepresentante.value = res.data.user !== null ? res.data.user.email || res.data.email : res.data.email || '';

                        if (res.data.cdPhoneCelular != null) {
                            form.telefoneRepresentante.value = res.data.celular || '';
                            form.idTelefoneRepresentante.value = res.data.cdPhoneCelular || null;
                        } else {
                            form.telefoneRepresentante.value = res.data.user !== null ? res.data.user.celular || 0 : 0;
                            form.idTelefoneRepresentante.value = res.data.user !== null ? res.data.user.cdPhoneCelular || 0 : 0;
                        }
                        if (res.data.cdPhoneComercial != null) {
                            if (res.data.comercial.length == 10)
                                document.getElementById("FixoRepresentante").click();
                            else
                                document.getElementById("CelularRepresentante").click();
                            form.tipoTelefoneComercialRepresentante.value = res.data.comercial.length == 10 ? 2 : 1;
                            form.telefoneComercialRepresentante.value = res.data.comercial || '';
                            form.idTelefoneComercialRepresentante.value = res.data.cdPhoneComercial || null;
                        } else {
                            form.tipoTelefoneComercialRepresentante.value = res.data.user !== null && res.data.user.comercial != null ? res.data.user.comercial.length == 10 ? 2 : 1 : 0;
                            form.telefoneComercialRepresentante.value = res.data.user !== null ? res.data.user.comercial || '' : '';
                            form.idTelefoneComercialRepresentante.value = res.data.user !== null ? res.data.user.cdPhoneComercial || null : null;
                        }
                        form.idPessoaDCRepresentante.value = res.data.idPessoaDadosCadastrais || 0;
                        form.idPessoaRepresentante.value = res.data.id || 0;
                        form.habilitaDadosR1.value = (res.data.nome != '' && res.data.nome != null);

                        this._handleResponsavelChangeSet(form.nomeRepresentante.value, 'nomeRepresentante');
                    }
                }
                else {
                    form.nomeRepresentante.value = '';
                    form.emailRepresentante.value = '';
                    form.telefoneRepresentante.value = '';
                    form.idPessoaRepresentante.value = 0;
                    form.idTelefoneRepresentante.value = 0;
                    form.idTelefoneComercialRepresentante.value = 0;
                    form.telefoneComercialRepresentante.value = '';
                    form.idPessoaDCRepresentante.value = 0;
                    form.idPessoaRepresentante.value = 0;
                    form.ruPessoaRepresentante.value = 0;
                    form.habilitaDadosR1.value = false;
                }
            }
            this.setState({ form, loading: false });
        }
    }

    _handleAdminIsRepresentante = async (event) => {
        const { form } = this.state;
        if (form.adminRepresentante.value) {
            this._handleResponsavelChangeSet(form.nomeAdmin.value.trim(), 'nomeAdmin')
        }
        else {
            var indexItem = form.listResponsaveis.value.map(function (e) {
                return e.codigo;
            }).indexOf('nomeAdmin');

            if (indexItem > -1)
                form.listResponsaveis.value.splice(indexItem, 1)
        }
        this.setState({ form });
    }

    _handleCPFAdminChange = async (event) => {
        const { form } = this.state;
        var id = event.target.id;
        var cpfInformado = event.target.value.substr(0, 14);
        if (cpfInformado === "000.000.000-00"
            || cpfInformado == ''
            || cpfInformado == undefined
            || cpfInformado == null
            || cpfInformado == 0) {
            form.cpfAdmin.value = "";
            form.nomeAdmin.value = '';
            form.emailAdmin.value = '';
            form.telefoneAdmin.value = '';
            form.telefoneComercialAdmin.value = '';
            form.idTelefoneAdmin.value = 0;
            form.idPessoaAdmin.value = 0;
            form.habilitaDadosAdmin.value = false;
            this.setState({ form })
        }
        if (cpfInformado !== undefined &&
            cpfInformado !== null &&
            cpfInformado !== "" &&
            cpfInformado !== 0 &&
            cpfInformado !== "000.000.000-00" &&
            cpfInformado.length === 14) {
            this.setState({ loading: true });
            if (form.cpfRepresentante.value == cpfInformado) {
                var cpf = cpfInformado;
                form.cpfAdmin.value = '';
                form.nomeAdmin.value = '';
                form.emailAdmin.value = '';
                form.telefoneAdmin.value = '';
                form.telefoneComercialAdmin.value = '';
                form.idTelefoneAdmin.value = 0;
                form.idPessoaAdmin.value = 0;
                form.cpfAdmin.ref.current.focus();
                form.habilitaDadosAdmin.value = false;
                this.setState({ form, loading: false });
                toast.warn("O CPF informado [" + cpf + "] foi informado no campo do cpf do Representante.");
            }
            else {
                const res = await solicitacaoService.getDadosPorCPF(cpfInformado.replace('.', '').replace('.', '').replace('/', '').replace('-', ''));
                if (res.success === true && res.data !== null && res.data !== undefined) {
                    // if(res.data.idEmpresa != '' form.cnpj.value.replace('.', '').replace('.', '').replace('/', '').replace('-', ''))
                    // if(res.data.idEmpresa != '' && res.data.idEmpresa !== null && res.data.idEmpresa !== undefined)
                    // {                   
                    //     var cpf = '';                           
                    //     cpf=form.cpfAdmin.value;
                    //     form.cpfAdmin.value = '';
                    //     form.nomeAdmin.value = '';
                    //     form.emailAdmin.value = '';
                    //     form.telefoneAdmin.value = '';
                    //     form.telefoneComercialAdmin.value = '';
                    //     form.idTelefoneAdmin.value = 0;
                    //     form.telefoneComercialAdmin.value = 0;
                    //     form.idPessoaAdmin.value =  0;
                    //     form.cpfAdmin.ref.current.focus();

                    //     this.setState({ form, loading: false });
                    //     toast.warn("O CPF informado [" + cpf + "] perterce a um usuário de outra empresa. Por favor informe outro CPF.");
                    // }
                    // else{
                    if (res.data.user !== null) {
                        form.nomeAdmin.value = res.data.nome || res.data.user.nome || '';
                        form.emailAdmin.value = res.data.email || res.data.user.email || '';
                        if (res.data.user.cdPhoneCelular != null) {
                            form.telefoneAdmin.value = res.data.user.celular || '';
                            form.idTelefoneAdmin.value = res.data.user.cdPhoneCelular || null;
                        } else {
                            form.telefoneAdmin.value = '';
                            form.idTelefoneAdmin.value = 0;
                        }
                        if (res.data.user.cdPhoneComercial != null) {
                            if (res.data.user.comercial.length == 10)
                                document.getElementById("FixoAdmin").click();
                            else
                                document.getElementById("CelularAdmin").click();
                            form.tipoTelefoneComercialAdmin.value = res.data.user.comercial.length == 10 ? 2 : 1;
                            form.telefoneComercialAdmin.value = res.data.user.comercial || '';
                            form.idTelefoneComercialAdmin.value = res.data.user.cdPhoneComercial || null;
                        }
                        else {
                            form.tipoTelefoneComercialAdmin.value = 0;
                            form.telefoneComercialAdmin.value = '';
                        }
                        form.ruPessoaAdmin.value = res.data.user.ru || 0;
                        form.habilitaDadosAdmin.value = (res.data.nome != '' && res.data.nome != null && res.data.user.nome != '' && res.data.user.nome != null);
                    }
                    if (res.data.id > 0) {
                        form.nomeAdmin.value = res.data.nome || '';
                        form.emailAdmin.value = res.data.user !== null ? res.data.user.email || res.data.email : res.data.email || '';
                        if (res.data.cdPhoneCelular != null) {
                            form.telefoneAdmin.value = res.data.celula || '';
                            form.idTelefoneAdmin.value = res.data.cdPhoneCelular || null;
                        } else {
                            form.telefoneAdmin.value = res.data.user !== null ? res.data.user.celular || 0 : 0;
                            form.idTelefoneAdmin.value = res.data.user !== null ? res.data.user.cdPhoneCelular || 0 : 0;
                        }
                        if (res.data.cdPhoneComercial != null) {
                            if (res.data.comercial.length == 10)
                                document.getElementById("FixoAdmin").click();
                            else
                                document.getElementById("CelularAdmin").click();

                            form.tipoTelefoneComercialAdmin.value = res.data.comercial.length == 10 ? 2 : 1;
                            form.telefoneComercialAdmin.value = res.data.comercial || '';
                            form.idTelefoneComercialAdmin.value = res.data.cdPhoneComercial || null;
                        }
                        else {
                            form.tipoTelefoneComercialAdmin.value = res.data.user !== null && res.data.user.comercial != null ? res.data.user.comercial.length == 10 ? 2 : 1 : 0;
                            form.telefoneComercialAdmin.value = res.data.user !== null ? res.data.user.comercial || '' : '';
                            form.idTelefoneComercialAdmin.value = res.data.user !== null ? res.data.user.cdPhoneComercial || null : null;
                        }

                        form.idPessoaDCAdmin.value = res.data.idPessoaDadosCadastrais || 0;
                        form.idPessoaAdmin.value = res.data.id || 0;
                        form.habilitaDadosAdmin.value = (res.data.nome != '' && res.data.nome != null);
                    }
                    // }                 
                }
                else {
                    form.nomeAdmin.value = '';
                    form.emailAdmin.value = '';
                    form.telefoneAdmin.value = '';
                    form.telefoneComercialAdmin.value = '';
                    form.idTelefoneAdmin.value = 0;
                    form.idPessoaAdmin.value = 0;
                    form.idPessoaDCAdmin.value = 0;
                    form.ruPessoaAdmin.value = 0;
                    form.habilitaDadosAdmin.value = false;
                }
            }
            this.setState({ form, loading: false });
        }
    }

    onBlurTelefoneAdmin = async (event) => {
        const { form } = this.state;
        if (event.target.value != '') {
            this.setState({ loading: true });
            if (form.tipoTelefoneComercialAdmin.value == '1' && event.target.value.length < 15) {
                toast.warn('Verifique o campo Telefone Comercial nos dados do Representando Comercial');
                window.scrollTo(0, form.telefoneComercialAdmin.ref.current.offsetTop);
            }
            else if (form.tipoTelefoneComercialAdmin.value == '1' && event.target.value.length < 14) {
                toast.warn('Verifique o campo Telefone Comercial nos dados do Administrador');
                window.scrollTo(0, form.telefoneComercialAdmin.ref.current.offsetTop);
            }

            this.setState({ form, loading: false });
        }
    }
    onBlurCelularAdmin = async (event) => {
        const { form } = this.state;
        if (event.target.value != '') {
            this.setState({ loading: true });
            if (event.target.value.length < 15) {
                window.scrollTo(0, form.telefoneAdmin.ref.current.offsetTop);
                toast.warn('Verifique o campo Telefone Comercial nos dados do Administrador');
            }
            this.setState({ form, loading: false });
        }
    }
    onBlurCelularRepresentante = async (event) => {
        const { form } = this.state;
        if (event.target.value != '') {
            this.setState({ loading: true });
            if (event.target.value.length < 15) {
                window.scrollTo(0, form.telefoneComercialRepresentante.ref.current.offsetTop);
                toast.warn('Verifique o campo Telefone Comercial nos dados do Representando Comercial');
            }
            this.setState({ form, loading: false });
        }
    }

    onBlurTelefoneRepresentante = async (event) => {
        const { form } = this.state;
        if (event.target.value != '') {
            this.setState({ loading: true });
            if (form.tipoTelefoneComercialRepresentante.value == '1' && event.target.value.length < 15) {
                window.scrollTo(0, form.telefoneComercialRepresentante.ref.current.offsetTop);
                toast.warn('Verifique o campo Telefone Comercial nos dados do Representando Comercial');
            }
            else if (form.tipoTelefoneComercialRepresentante.value == '1' && event.target.value.length < 14) {
                window.scrollTo(0, form.telefoneComercialRepresentante.ref.current.offsetTop);
                toast.warn('Verifique o campo Telefone Comercial nos dados do Representando Comercial');
            }

            this.setState({ form, loading: false });
        }
    }
    async _onChangeForm(form) {
        this.setState({ form })
    }

    _validarDocumento = async (values) => {

        if (!isNullOrEmptyOrWhiteSpaceOrUndefined(values.cpfRepresentante)) {
            var countRepresentante = 0;
            var listHasRepresentante = values.lista.map(function (e) { return e.representante == values.nomeRepresentante });
            listHasRepresentante.forEach(element => {
                if (element === true) { countRepresentante++ }
            });
            if (countRepresentante < 2)
                return `Envie o CPF e RG do representante: ${values.nomeRepresentante}`;
        }

        if (!isNullOrEmptyOrWhiteSpaceOrUndefined(values.cpfRepresentante2)) {
            var countRepresentante = 0;
            var listHasRepresentante = values.lista.map(function (e) { return e.representante == values.nomeRepresentante2 });
            listHasRepresentante.forEach(element => {
                if (element === true) { countRepresentante++ }
            });
            if (countRepresentante < 2)
                return `Envie o CPF e RG do representante: ${values.nomeRepresentante2}`;
        }

        if (!isNullOrEmptyOrWhiteSpaceOrUndefined(values.cpfRepresentante3)) {
            var countRepresentante = 0;
            var listHasRepresentante = values.lista.map(function (e) { return e.representante == values.nomeRepresentante3 });
            listHasRepresentante.forEach(element => {
                if (element === true) { countRepresentante++ }
            });
            if (countRepresentante < 2)
                return `Envie o CPF e RG do representante: ${values.nomeRepresentante3}`;
        }

        if (!isNullOrEmptyOrWhiteSpaceOrUndefined(values.cpfRepresentante4)) {
            var countRepresentante = 0;
            var listHasRepresentante = values.lista.map(function (e) { return e.representante == values.nomeRepresentante4 });
            listHasRepresentante.forEach(element => {
                if (element === true) { countRepresentante++ }
            });
            if (countRepresentante < 2)
                return `Envie o CPF e RG do representante: ${values.nomeRepresentante4}`;
        }

        if (!isNullOrEmptyOrWhiteSpaceOrUndefined(values.cpfRepresentante5)) {
            var countRepresentante = 0;
            var listHasRepresentante = values.lista.map(function (e) { return e.representante == values.nomeRepresentante5 });
            listHasRepresentante.forEach(element => {
                if (element === true) { countRepresentante++ }
            });
            if (countRepresentante < 2)
                return `Envie o CPF e RG do representante: ${values.nomeRepresentante5}`;
        }

        if (!isNullOrEmptyOrWhiteSpaceOrUndefined(values.cnpj)) {
            var countRepresentante = 0;
            var listHasRepresentante = values.lista.map(function (e) { return e.representante == values.razaoSocial });
            listHasRepresentante.forEach(element => {
                if (element === true) { countRepresentante++ }
            });
            if (countRepresentante < 1)
                return `Envie o contrato social/estatuto da empresa: ${values.razaoSocial}`;
        }
        return '';
    }

    _onSubmit = async (values, valid) => {
        this.setState({ loading: true });
        const { form } = this.state;
       
        if (this.state.isVerified) {
            if (valid[0]) {
                var d = this.buscarDadosForm();

                var notConvenioPagamentoHasDoc = true;
                if (form.tipoConvenio.value == 2) {
                    var validDoc = await this._validarDocumento(values);
                   
                    if (!isNullOrEmptyOrWhiteSpaceOrUndefined(validDoc)) {
                        notConvenioPagamentoHasDoc = false;
                        toast.error(validDoc);
                    }
                }

                if (notConvenioPagamentoHasDoc) {
                    const result = await solicitacaoService.insert(this.buscarDadosForm());
                    
                    if (result.success == true) {
                        this.atualizaSolicitacao(result.data);

                        /*ENVIANDO CONTRATO E DOCUMENTOS*/
                        if (form.tipoEmpresa.value == 1 && form.tipoConvenio.value != 2) {
                            let files = document.getElementById('idContrato').files;
                          
                            if (files !== undefined) {
                                let contrato = new FormData();
                                for (var index = 0; index < files.length; index++) {
                                    var element = files[index];
                                    contrato.append('File', element);
                                    contrato.append('isContrato', 1);
                                    contrato.append('Tipo', 1);
                                    contrato.append('IdSolicitacao', form.idSolicitacao.value);
                                    contrato.append('IdPessoa', null);
                                    contrato.append('Descricao', '');
                                    contrato.append('DataInicioVigencia', null);
                                    contrato.append('DataFimVigencia', null);

                                    const returnContrato = await solicitacaoService.OnPostMultUpload(contrato);
                                }
                            }
                        } else if (form.tipoEmpresa.value == 2 || form.tipoConvenio.value == 2) {
                            if (form.lista.value.length > 0) {
                                for (var index = 0; index < form.lista.value.length; index++) {
                                    let doc = new FormData();
                                    var element = form.lista.value[index];
                                    doc.append('File', element.arquivo);
                                    doc.append('isContrato', element.idTipoArquivo);
                                    doc.append('Tipo', element.idTipoArquivo == 1 ? 1 : element.idTipo);
                                    doc.append('IdSolicitacao', form.idSolicitacao.value);
                                    doc.append('IdPessoa', null);
                                    doc.append('Descricao', '');
                                    doc.append('ResponsavelCPF', element.representanteCpf);
                                    doc.append('DataInicioVigencia', null);
                                    doc.append('DataFimVigencia', null);
                                 
                                    const returnContrato = await solicitacaoService.OnPostMultUpload(doc);
                                }
                            }
                        }
                        /*ENVIANDO CONTRATO E DOCUMENTOS*/

                        this.setState({ loading: false });
                        document.getElementById('idFinish').click();
                    }
                }
                else {
                    toast.warn("Não foi possível salvar o formulário");
                }
            } else {
                toast.error('Verifique o preenchimento dos campos.');
                const { form } = this.state;
                form.showModalBlackList.value = true;
                let errors = valid[1].map(erro => `${form[erro.field].description || erro.field}: ${erro.value}`);
                this.setState({ errors });
                this.setState({ form });
            }
        } else {
            toast.error("Favor preencher o reCaptcha.")
        }
        this.setState({ loading: false });
    }

    _onValid = valid => this.setState({ valid })

    render() {
        const { form, errors } = this.state;

        const todoItems = form.lista.value.map((todo) =>
            <tr className="" >
                <td className="" title={todo.name}>{todo.name.length > 15 ? todo.name.substring(0, 14) + '...' : todo.name}</td>
                <td className="">{todo.extensao}</td>
                <td className="">{todo.tipo}</td>
                <td hidden={form.tipoConvenio.value == '2'} className="">{todo.tipoArquivo}</td>
                <td hidden={form.tipoConvenio.value == '1'} className="">{todo.representante}</td>
                <td className=""><i className="fa fa-times-circle" aria-hidden="true" onClick={this.onClickExcluir} accessKey={todo.id}></i></td>
            </tr>
        );
        return (
            <>
                {
                    this.state.loading &&
                    Loading
                }
                <div>
                    <div>
                        <Topo></Topo>
                        <div className="uninter-container-convenioempresas">
                            <Form onSubmit={this._onSubmit} formControl={form} onChangeForm={this._onChangeForm} >
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
                                        <Link className="uninter-btn-voltar-inicio" id="linkLogin" title="Voltar para tela inicial" to="Login">Início</Link>
                                        <h3 className="titleFormulario">FORMULÁRIO PARA FORMALIZAÇÃO DO CONVÊNIO</h3>
                                        <div className="uninter-box-convenioempresas-empresas row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <ul className="uninter-declaro-convenioempresas col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-box-convenioempresas-curso-base">
                                                    <span className="midletitle">Tipo de empresa</span>
                                                    <li className="form-check">
                                                        <input type="radio" id="empresa-privada" name="tipoEmpresa" value="1" onChange={this._handleTipoEmpresaChange} />
                                                        <label className="form-check-label">PRIVADA</label>
                                                        <input type="radio" id="empresa-publica" name="tipoEmpresa" value="2" onChange={this._handleTipoEmpresaChange} />
                                                        <label className="form-check-label">PÚBLICA</label>
                                                    </li>
                                                    <span className="error" id="tipoEmpresaError"></span>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="uninter-box-convenioempresas-empresas row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <ul className="uninter-declaro-convenioempresas col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-box-convenioempresas-curso-base">
                                                    <span className="midletitle">Tipo de convênio</span>
                                                    <li className="form-check">
                                                        <input type="radio" id="desconto" name="tipoConvenio" value="1" disabled onChange={this._handleConvenioChange} />
                                                        <label className="form-check-label">DESCONTO</label>
                                                        {/* altmig */}
                                                        {/* <input type="radio" id="pagamento" name="tipoConvenio" value="2" disabled className="checkPagamento" onChange={this._handleConvenioChange} />
                                                        <label className="form-check-label checkPagamento">PAGAMENTO</label><br /> */} 
                                                    </li>
                                                    <span className="error" id="tipoConvenioError"></span>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="uninter-passo-geral col-xs-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
                                        <div className="accordion " id="accordionFormulario">
                                            <div className="card uninter-convenioempresas-final uninter-passo uninter-dados_basicos">
                                                <div className="card-header uninter-convenioempresas-header" id="dadosBasicos">
                                                    <h3 className="colapseTitle" data-toggle="collapse" data-target="#collapseDadosBasicos" aria-expanded="true" aria-controls="collapseDadosBasicos" id="titleDadosBasicos">Dados básicos
                                                        <i hidden={form.polo.value === '' && form.gestor.value === ''} className="fa fa-check answer-sucess" aria-hidden="true"></i></h3>
                                                </div>
                                                <div id="collapseDadosBasicos" className="collapse show colapseDescription" aria-labelledby="dadosBasicos" data-parent="#accordionFormulario">
                                                    <div className="card-body">
                                                        <ul className="uninterconvenioempresas uninter-passo-interno">
                                                            <li>
                                                                {/* <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 uninter-campos">
                                                                    <label className="form-check-label" htmlFor="txtPolo">Campos/Polo de Apoio Presencial</label>
                                                                    <input type="text" id="polo" name="polo" title="Favor informar o polo que fez a divulgação do convênio. *Este campo é opcional" />
                                                                </div> */}
                                                                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-9 uninter-campos">
                                                                    <label>Consultor de Negócios/Gestor</label>
                                                                    <input type="text" id="gestor" name="gestor" title="Favor informar o gestor/consultor que fez a divulgação do convênio. *Este campo é opcional" />
                                                                </div>


                                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 uninter-campos">
                                                                    <label htmlFor="ddlEstado">Estado (polo)</label>
                                                                    <Select
                                                                        onChange={this._handleEstadoChange}
                                                                        isLoading={form.listEstado.value.length > 0 ? false : true}
                                                                        options={form.listEstado.value.map((o, i) => {
                                                                            return { id: i, value: o.id, label: o.name };
                                                                        })}
                                                                        placeholder="SELECIONE"
                                                                        id={"estadopolo"}
                                                                        name={"estadopolo"}
                                                                        defaultValue={{ label: "SELECIONE", value: 0 }}
                                                                    />
                                                                    <span className="error" id="estadoError"></span>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                    <label htmlFor="ddlCidade">Cidade (polo)</label>
                                                                    <Select
                                                                        onChange={this._handleCidadeChange}
                                                                        isLoading={form.listCidade.value.length > 0 ? false : true}
                                                                        options={form.listCidade.value.map((o, i) => {
                                                                            return { id: i, value: o.id, label: o.name };
                                                                        })}
                                                                        placeholder="SELECIONE"
                                                                        id={"cidadepolo"}
                                                                        name={"cidadepolo"}
                                                                        defaultValue={{ label: "SELECIONE", value: 0 }}
                                                                    />
                                                                    <span className="error" id="cidadepoloError"></span>
                                                                </div>
                                                                {
                                                                    form.listPolo &&
                                                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 uninter-campos">
                                                                        <label htmlFor="ddlPolo">Polo</label>
                                                                        <Select
                                                                            onChange={this._handlePoloChange}
                                                                            isLoading={form.listPolo.value.length > 0 ? false : true}
                                                                            options={form.listPolo.value.map((o, i) => {
                                                                                return { id: i, value: o.id, label: o.nome };
                                                                            })}
                                                                            placeholder="SELECIONE"
                                                                            id={"polo"}
                                                                            name={"polo"}
                                                                            defaultValue={{ label: "SELECIONE", value: 0 }}
                                                                        />
                                                                        <span className="error" id="poloError"></span>
                                                                    </div>
                                                                }
                                                            </li>
                                                        </ul>
                                                        <span className="error" id="dadosBasicosError"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card uninter-convenioempresas-final uninter-passo">
                                                <div className="card-header uninter-convenioempresas-header" id="dadosEmpresaConveniada">
                                                    <h3 className="colapseTitle" data-toggle="collapse" data-target="#collapseDadosEmpresaConveniada" aria-expanded="false" aria-controls="collapseDadosEmpresaConveniada" id="titleDadosEmpresa">Dados da Empresa Conveniada
                                                        <i hidden={form.cnpj.value === '' || form.inscricaoEstadual.value === '' || form.razaoSocial.value === '' || form.cep.value === ''
                                                            || form.rua.value === '' || form.numero.value === '' || form.bairro.value === '' || form.uf.value === ''} className="fa fa-check answer-sucess" aria-hidden="true"></i></h3>
                                                </div>
                                                <div id="collapseDadosEmpresaConveniada" className="collapse" aria-labelledby="dadosEmpresaConveniada" data-parent="#accordionFormulario">
                                                    <div className="card-body">
                                                        <ul className="uninterconvenioempresas uninter-passo-interno">
                                                            <li>
                                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                    <label>CNPJ</label>
                                                                    <input type="text" id="cnpj" name="cnpj" maxLength="18" onBlur={this._handleCnpjChange} />
                                                                    <span className="error" id="cnpjError"></span>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                    <label>Inscrição Estadual/(Isento)</label>
                                                                    <input type="text" id="inscricaoEstadual" name="inscricaoEstadual" disabled={form.habilitaDadosEmpresa.value} />
                                                                    <span className="error" id="inscricaoEstadualError"></span>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                    <label>Razão Social</label>
                                                                    <input type="text" id="razaoSocial" name="razaoSocial" minLength="5" maxLength="80" disabled={form.habilitaDadosEmpresa.value} onBlur={this._handleResponsavelChange} />
                                                                    <span className="error" id="razaoSocialError"></span>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                    <label>Nome Fantasia</label>
                                                                    <input type="text" id="nomeFantasia" name="nomeFantasia" minLength="2" maxLength="80" disabled={form.habilitaDadosEmpresa.value} />
                                                                    <span className="error" id="nomeFantasiaError"></span>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                    <label>Email</label>
                                                                    <input id="emailEmpresa" name="emailEmpresa" maxLength="250" type="email" />
                                                                    <span className="error" id="emailEmpresaError"></span>
                                                                </div>
                                                                <div className="row uninter-container-row">
                                                                    <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 uninter-campos">
                                                                        <label>CEP</label>
                                                                        <input type="text" id="cep" name="cep" maxLength="10" onBlur={this._handleCepChange} />
                                                                        <span className="error" id="cepError"></span>
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-6 col-md-8 col-lg-8 uninter-campos">
                                                                        <label>Rua/Logradouro</label>
                                                                        <input type="text" id="rua" name="rua" />
                                                                        <span className="error" id="ruaError"></span>
                                                                    </div>
                                                                    <div className="col-xs-4 col-sm-3 col-md-2 col-lg-2 uninter-campos">
                                                                        <label>Nº/(S/N)</label>
                                                                        <input type="text" id="numero" name="numero" />
                                                                        <span className="error" id="numeroError"></span>
                                                                    </div>
                                                                </div>
                                                                <div className="row uninter-container-row">
                                                                    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 uninter-campos">
                                                                        <label>Bairro</label>
                                                                        <input type="text" id="bairro" name="bairro" />
                                                                        <span className="error" id="bairroError"></span>
                                                                    </div>

                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label>Cidade</label>
                                                                        <input type="text" id="cidade" name="cidade" disabled='true' />
                                                                        <span className="error" id="cidadeError"></span>
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2 uninter-campos">
                                                                        <label>UF</label>
                                                                        <input type="text" id="uf" name="uf" disabled='true' />
                                                                        <span className="error" id="ufError"></span>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 uninter-campos">
                                                                    <label>Site <span className="indicativoSite">(se houver)</span></label>
                                                                    <input type="text" id="site" name="site" maxLength="100" />
                                                                    <span className="error" id="siteError"></span>
                                                                </div>
                                                                <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 uninter-campos">
                                                                    <label>Número de Colaboradores</label>
                                                                    <input type="text" id="numeroColaboradores" name="numeroColaboradores" />
                                                                    <span className="error" id="numeroColaboradoresError"></span>
                                                                </div>
                                                                <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4 uninter-campos">
                                                                    <ul className="uninter-declaro-convenioempresas">
                                                                        <label className="label">Controlar Colaboradores Conveniados</label>
                                                                        <li className="form-check">
                                                                            <input type="checkbox" id="controleColaboradores" name="controleColaboradores" value="1" />
                                                                            <label className="label">Sim</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4 uninter-campos">
                                                                    <ul className="uninter-declaro-convenioempresas">
                                                                        <label className="label">Permite Ofertas Curso?</label>
                                                                        <li className="form-check">
                                                                            <input type="checkbox" id="permiteOfertarCursos" name="permiteOfertarCursos" value="1" />
                                                                            <label className="label">Sim</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card uninter-convenioempresas-final uninter-passo">
                                                <div className="card-header uninter-convenioempresas-header" id="dadosUsuarioAdmin">
                                                    <h3 className="colapseTitle collapsed" data-toggle="collapse" data-target="#collapseDadosUsuarioAdmin" aria-expanded="false" aria-controls="collapseDadosUsuarioAdmin" id="titleDadosAdmin">
                                                        {form.adminRepresentante.value == 0 ? 'Dados Administrador da Conveniada' : 'Dados Administrador/Representante legal da Conveniada'}
                                                        <i hidden={form.cpfAdmin.value === '' || form.nomeAdmin.value === '' || form.cargoAdmin.value === ''
                                                            || form.emailAdmin.value === '' || form.telefoneAdmin.value === '' || form.telefoneComercialAdmin.value === ''} className="fa fa-check answer-sucess" aria-hidden="true"></i></h3>
                                                </div>
                                                <div id="collapseDadosUsuarioAdmin" className="collapse" aria-labelledby="dadosUsuarioAdmin" data-parent="#accordionFormulario">
                                                    <div className="card-body">
                                                        <ul className="uninterconvenioempresas uninter-passo-interno">
                                                            <li>
                                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                    <label>CPF</label>
                                                                    <input type="text" id="cpfAdmin" name="cpfAdmin" onBlur={this._handleCPFAdminChange} />
                                                                    <span className="error" id="cpfAdminError"></span>
                                                                </div>

                                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                    <label>Nome</label>
                                                                    <input type="text" id="nomeAdmin" name="nomeAdmin" disabled={form.habilitaDadosAdmin.value} />
                                                                    <span className="error" id="nomeAdminError"></span>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                    <label>RG</label>
                                                                    <input type="text" id="rgAdmin" name="rgAdmin" />
                                                                    <span className="error" id="rgAdminError"></span>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                    <label htmlFor="ddlCargoAdmin">Cargo</label>
                                                                    <Select
                                                                        onChange={this._handleCargoAdminChange}
                                                                        isLoading={form.listCargo.value.length > 0 ? false : true}
                                                                        options={form.listCargo.value.map((o, i) => {
                                                                            return { id: i, value: o.codigo, label: o.descricao };
                                                                        })}
                                                                        placeholder="SELECIONE"
                                                                        id={"cargoAdmin"}
                                                                        name={"cargoAdmin"}
                                                                        defaultValue={{ label: "SELECIONE", value: 0 }}
                                                                    />
                                                                    <span className="error" id="cargoAdminError"></span>
                                                                </div>
                                                                {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                    <label>Departamento</label>
                                                                    <Select
                                                                        onChange={this._handleDepartamentoAdminChange}
                                                                        isLoading={form.listDepartamento.value.length > 0 ? false : true}
                                                                        options={form.listDepartamento.value.map((o, i) => {
                                                                            return { id: i, value: o.codigo, label: o.descricao };
                                                                        })}
                                                                        placeholder="SELECIONE"
                                                                        id={"departamentoAdmin"}
                                                                        name={"departamentoAdmin"}
                                                                        defaultValue={{ label: "SELECIONE", value: 0 }}
                                                                    />
                                                                    <span className="error" id="departamentoAdminError"></span>
                                                                </div> */}

                                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                    <label>Email</label>
                                                                    <input type="email" id="emailAdmin" name="emailAdmin" maxLength="250" />
                                                                    <span className="error" id="emailAdminError"></span>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 uninter-campos">
                                                                    <label>Telefone Celular</label>
                                                                    <input type="text" id="telefoneAdmin" name="telefoneAdmin" />
                                                                    <span className="error" id="telefoneAdminError"></span>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 uninter-campos">
                                                                    <ul className="uninter-declaro-convenioempresas">
                                                                        <label className="label">Tipo Telefone Comercial</label>
                                                                        <li className="form-check">
                                                                            <input type="radio" id="CelularAdmin" name="tipoTelefoneComercialAdmin" onClick={this.onClickTipoTelefoneAdmin} value="1" />
                                                                            <label className="label">Celular</label>

                                                                            <input type="radio" id="FixoAdmin" name="tipoTelefoneComercialAdmin" onClick={this.onClickTipoTelefoneAdmin} value="2" />
                                                                            <label className="label">Fixo</label>
                                                                        </li>
                                                                    </ul>
                                                                    <center><span className="error" id="tipoTelefoneComercialAdminError"></span></center>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 uninter-campos">
                                                                    <label>Telefone Comercial</label>
                                                                    <input type="text" id="telefoneComercialAdmin" name="telefoneComercialAdmin" />
                                                                    <span className="error" id="telefoneComercialAdminError"></span>
                                                                </div>
                                                                <br />
                                                                <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4 uninter-campos">
                                                                    <ul className="uninter-declaro-convenioempresas">
                                                                        <label className="label" tooltip-position="bottom" tooltip="Selecionar quando o administrador também for o representante legal da conveniada.">Representante legal</label>
                                                                        <li className="form-check">
                                                                            <input type="checkbox" id="adminRepresentante" name="adminRepresentante" value="1" disabled={isNullOrEmptyOrWhiteSpaceOrUndefined(form.cpfAdmin.value) || isNullOrEmptyOrWhiteSpaceOrUndefined(form.nomeAdmin.value)} onBlur={this._handleAdminIsRepresentante} />
                                                                            <label className="label">Sim</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos" hidden={form.tipoConvenio.value == 1 || form.tipoConvenio.value == 0}>
                                                                    <ul className="uninter-declaro-convenioempresas">
                                                                        <label className="label" tooltip-position="bottom" tooltip="Selecionar quando for necessário envio de nota por e-mail.">Receber nota por e-mail</label>
                                                                        <li className="form-check">
                                                                            <input type="checkbox" id="recebeEmailNotaAdm" name="recebeEmailNotaAdm" value="1" />
                                                                            <label className="label">Sim</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos uninter-campos" hidden={form.tipoConvenio.value == 1 || form.tipoConvenio.value == 0}>
                                                                    <ul className="uninter-declaro-convenioempresas">
                                                                        <label className="label" tooltip-position="bottom" tooltip="Selecionar quando for necessário envio de boleto por e-mail.">Receber boleto por e-mail</label>
                                                                        <li className="form-check">
                                                                            <input type="checkbox" id="recebeEmailBoletoAdm" name="recebeEmailBoletoAdm" value="1" />
                                                                            <label className="label">Sim</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card uninter-convenioempresas-final uninter-passo" hidden={form.adminRepresentante.value == 1}>
                                                <div className="card-header uninter-convenioempresas-header" id="dadosRepresentante">
                                                    <h3 className="colapseTitle" data-toggle="collapse" data-target="#collapseDadosRepresentante" aria-expanded="false" aria-controls="collapseDadosRepresentante" id="titleDadosRepresentante">Dados do Representante legal da Conveniada
                                                        <i hidden={form.cpfRepresentante.value === '' || form.nomeRepresentante.value === '' || form.cargoRepresentante.value === ''
                                                            || form.emailRepresentante.value === '' || form.telefoneRepresentante.value === '' || form.telefoneComercialRepresentante.value === ''} className="fa fa-check answer-sucess" aria-hidden="true"></i></h3>
                                                </div>
                                                <div id="collapseDadosRepresentante" className="collapse" aria-labelledby="dadosRepresentante" data-parent="#accordionFormulario">

                                                    <center>
                                                        <input className="uninter-box-home-convenioempresas-btn-enviar-formulario" type="button" data-toggle="collapse" href="#representante2" value="Representante 2" />
                                                        <input className="uninter-box-home-convenioempresas-btn-enviar-formulario" style={{ marginLeft: 5 }} type="button" data-toggle="collapse" href="#representante3" value="Representante 3" />
                                                        <input className="uninter-box-home-convenioempresas-btn-enviar-formulario" style={{ marginLeft: 5 }} type="button" data-toggle="collapse" href="#representante4" value="Representante 4" />
                                                        <input className="uninter-box-home-convenioempresas-btn-enviar-formulario" style={{ marginLeft: 5 }} type="button" data-toggle="collapse" href="#representante5" value="Representante 5" />
                                                    </center>
                                                    <div className="card-body">
                                                        <h3>Representante 1</h3>
                                                        <ul className="uninterconvenioempresas uninter-passo-interno">
                                                            <li>
                                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                    <label>CPF</label>
                                                                    <input type="text" id="cpfRepresentante" name="cpfRepresentante" onBlur={this._handleCPFChange} />
                                                                    <span className="error" id="cpfRepresentanteError"></span>
                                                                </div>

                                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                    <label>Nome</label>
                                                                    <input type="text" id="nomeRepresentante" name="nomeRepresentante" disabled={form.habilitaDadosR1.value} onBlur={this._handleResponsavelChange} />
                                                                    <span className="error" id="nomeRepresentanteError"></span>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                    <label>RG</label>
                                                                    <input type="text" id="rgRepresentante" name="rgRepresentante" />
                                                                    <span className="error" id="rgRepresentanteError"></span>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                    <label htmlFor="ddlCargoRepresentante">Cargo</label>
                                                                    <Select
                                                                        onChange={this._handleCargoChange}
                                                                        isLoading={form.listCargo.value.length > 0 ? false : true}
                                                                        options={form.listCargo.value.map((o, i) => {
                                                                            return { id: i, value: o.codigo, label: o.descricao };
                                                                        })}
                                                                        placeholder="SELECIONE"
                                                                        id={"cargoRepresentante"}
                                                                        name={"cargoRepresentante"}
                                                                        defaultValue={{ label: "SELECIONE", value: 0 }}
                                                                    />
                                                                    <span className="error" id="cargoRepresentanteError"></span>
                                                                </div>
                                                                {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                    <label>Departamento</label>
                                                                    <Select
                                                                        onChange={this._handleDepartamentoChange}
                                                                        isLoading={form.listDepartamento.value.length > 0 ? false : true}
                                                                        options={form.listDepartamento.value.map((o, i) => {
                                                                            return { id: i, value: o.codigo, label: o.descricao };
                                                                        })}
                                                                        placeholder="SELECIONE"
                                                                        id={"departamentoRepresentante"}
                                                                        name={"departamentoRepresentante"}
                                                                        defaultValue={{ label: "SELECIONE", value: 0 }}
                                                                    />
                                                                    <span className="error" id="departamentoRepresentanteError"></span>
                                                                </div> */}

                                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                    <label>Email</label>
                                                                    <input type="email" id="emailRepresentante" name="emailRepresentante" maxLength="250" />
                                                                    <span className="error" id="emailRepresentanteError"></span>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 uninter-campos">
                                                                    <label>Telefone Celular</label>
                                                                    <input type="text" id="telefoneRepresentante" name="telefoneRepresentante" maxLength="15" />
                                                                    <span className="error" id="telefoneRepresentanteError"></span>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 uninter-campos">
                                                                    <ul className="uninter-declaro-convenioempresas">
                                                                        <label className="label">Tipo Telefone Comercial</label>
                                                                        <li className="form-check">
                                                                            <input type="radio" id="CelularRepresentante" name="tipoTelefoneComercialRepresentante" onClick={this.onClickTipoTelefoneRepresentante} value="1" />
                                                                            <label className="label">Celular</label>

                                                                            <input type="radio" id="FixoRepresentante" name="tipoTelefoneComercialRepresentante" onClick={this.onClickTipoTelefoneRepresentante} value="2" />
                                                                            <label className="label">Fixo</label>
                                                                        </li>
                                                                    </ul>
                                                                    <center><span className="error" id="tipoTelefoneComercialRepresentanteError"></span></center>
                                                                </div>
                                                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 uninter-campos">
                                                                    <label>Telefone Comercial</label>
                                                                    <input type="text" id="telefoneComercialRepresentante" name="telefoneComercialRepresentante" />
                                                                    <span className="error" id="telefoneComercialRepresentanteError"></span>
                                                                </div>
                                                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos" hidden={form.tipoConvenio.value == 1 || form.tipoConvenio.value == 0}>
                                                                    <ul className="uninter-declaro-convenioempresas">
                                                                        <label className="label" tooltip-position="bottom" tooltip="Selecionar quando for necessário envio de nota por e-mail.">Receber nota por e-mail</label>
                                                                        <li className="form-check">
                                                                            <input type="checkbox" id="recebeEmailNota" name="recebeEmailNota" value="1" />
                                                                            <label className="label">Sim</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos uninter-campos" hidden={form.tipoConvenio.value == 1 || form.tipoConvenio.value == 0}>
                                                                    <ul className="uninter-declaro-convenioempresas">
                                                                        <label className="label" tooltip-position="bottom" tooltip="Selecionar quando for necessário envio de boleto por e-mail.">Receber boleto por e-mail</label>
                                                                        <li className="form-check">
                                                                            <input type="checkbox" id="recebeEmailBoleto" name="recebeEmailBoleto" value="1" />
                                                                            <label className="label">Sim</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="collapse" id="representante2">
                                                        <div className="card-body">
                                                            <h3>Representante 2</h3>
                                                            <ul className="uninterconvenioempresas uninter-passo-interno">
                                                                <li>
                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label>CPF</label>
                                                                        <input type="text" id="cpfRepresentante2" name="cpfRepresentante2" />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label>Nome</label>
                                                                        <input type="text" id="nomeRepresentante2" name="nomeRepresentante2" onBlur={this._handleResponsavelChange} />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label>RG</label>
                                                                        <input type="text" id="rgRepresentante2" name="rgRepresentante2" />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label htmlFor="ddlCargoRepresentante2">Cargo</label>
                                                                        <Select
                                                                            onChange={this._handleCargoChange}
                                                                            isLoading={form.listCargo.value.length > 0 ? false : true}
                                                                            options={form.listCargo.value.map((o, i) => {
                                                                                return { id: i, value: o.codigo, label: o.descricao };
                                                                            })}
                                                                            placeholder="SELECIONE"
                                                                            id={"cargoRepresentante2"}
                                                                            name={"cargoRepresentante2"}
                                                                            defaultValue={{ label: "SELECIONE", value: 0 }}
                                                                        />
                                                                    </div>
                                                                    {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                        <label>Departamento</label>
                                                                        <Select
                                                                            onChange={this._handleDepartamentoChange}
                                                                            isLoading={form.listDepartamento.value.length > 0 ? false : true}
                                                                            options={form.listDepartamento.value.map((o, i) => {
                                                                                return { id: i, value: o.codigo, label: o.descricao };
                                                                            })}
                                                                            placeholder="SELECIONE"
                                                                            id={"departamentoRepresentante2"}
                                                                            name={"departamentoRepresentante2"}
                                                                            defaultValue={{ label: "SELECIONE", value: 0 }}
                                                                        />
                                                                    </div> */}

                                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                        <label>Email</label>
                                                                        <input type="email" id="emailRepresentante2" name="emailRepresentante2" maxLength="250" />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 uninter-campos">
                                                                        <label>Telefone Celular</label>
                                                                        <input type="text" id="telefoneRepresentante2" name="telefoneRepresentante2" maxLength="15" />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 uninter-campos">
                                                                        <ul className="uninter-declaro-convenioempresas">
                                                                            <label className="label">Tipo Telefone Comercial</label>
                                                                            <li className="form-check">
                                                                                <input type="radio" id="CelularRepresentante2" onClick={this.onClickTipoTelefoneRepresentante2} name="tipoTelefoneComercialRepresentante2" value="1" />
                                                                                <label className="label">Celular</label>

                                                                                <input type="radio" id="FixoRepresentante2" onClick={this.onClickTipoTelefoneRepresentante2} name="tipoTelefoneComercialRepresentante2" value="2" />
                                                                                <label className="label">Fixo</label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 uninter-campos">
                                                                        <label>Telefone Comercial</label>
                                                                        <input type="text" id="telefoneComercialRepresentante2" name="telefoneComercialRepresentante2" maxLength="15" />
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos" hidden={form.tipoConvenio.value == 1 || form.tipoConvenio.value == 0}>
                                                                        <ul className="uninter-declaro-convenioempresas">
                                                                            <label className="label" tooltip-position="bottom" tooltip="Selecionar quando for necessário envio de nota por e-mail.">Receber nota por e-mail</label>
                                                                            <li className="form-check">
                                                                                <input type="checkbox" id="recebeEmailNotaRepresentante2" name="recebeEmailNotaRepresentante2" value="1" />
                                                                                <label className="label">Sim</label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos uninter-campos" hidden={form.tipoConvenio.value == 1 || form.tipoConvenio.value == 0}>
                                                                        <ul className="uninter-declaro-convenioempresas">
                                                                            <label className="label" tooltip-position="bottom" tooltip="Selecionar quando for necessário envio de boleto por e-mail.">Receber boleto por e-mail</label>
                                                                            <li className="form-check">
                                                                                <input type="checkbox" id="recebeEmailBoletoRepresentante2" name="recebeEmailBoletoRepresentante2" value="1" />
                                                                                <label className="label">Sim</label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="collapse" id="representante3">
                                                        <div className="card-body">
                                                            <h3>Representante 3</h3>
                                                            <ul className="uninterconvenioempresas uninter-passo-interno">
                                                                <li>
                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label>CPF</label>
                                                                        <input type="text" id="cpfRepresentante3" name="cpfRepresentante3" />
                                                                    </div>

                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label>Nome</label>
                                                                        <input type="text" id="nomeRepresentante3" name="nomeRepresentante3" onBlur={this._handleResponsavelChange} />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label>RG</label>
                                                                        <input type="text" id="rgRepresentante3" name="rgRepresentante3" />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label htmlFor="ddlCargoRepresentante3">Cargo</label>
                                                                        <Select
                                                                            onChange={this._handleCargoChange}
                                                                            isLoading={form.listCargo.value.length > 0 ? false : true}
                                                                            options={form.listCargo.value.map((o, i) => {
                                                                                return { id: i, value: o.codigo, label: o.descricao };
                                                                            })}
                                                                            placeholder="SELECIONE"
                                                                            id={"cargoRepresentante3"}
                                                                            name={"cargoRepresentante3"}
                                                                            defaultValue={{ label: "SELECIONE", value: 0 }}
                                                                        />
                                                                    </div>
                                                                    {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                        <label>Departamento</label>
                                                                        <Select
                                                                            onChange={this._handleDepartamentoChange}
                                                                            isLoading={form.listDepartamento.value.length > 0 ? false : true}
                                                                            options={form.listDepartamento.value.map((o, i) => {
                                                                                return { id: i, value: o.codigo, label: o.descricao };
                                                                            })}
                                                                            placeholder="SELECIONE"
                                                                            id={"departamentoRepresentante3"}
                                                                            name={"departamentoRepresentante3"}
                                                                            defaultValue={{ label: "SELECIONE", value: 0 }}
                                                                        />
                                                                    </div> */}

                                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                        <label>Email</label>
                                                                        <input type="email" id="emailRepresentante3" name="emailRepresentante3" maxLength="250" />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 uninter-campos">
                                                                        <label>Telefone Celular</label>
                                                                        <input type="text" id="telefoneRepresentante3" name="telefoneRepresentante3" maxLength="15" />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 uninter-campos">
                                                                        <ul className="uninter-declaro-convenioempresas">
                                                                            <label className="label">Tipo Telefone Comercial</label>
                                                                            <li className="form-check">
                                                                                <input type="radio" id="CelularRepresentante3" onClick={this.onClickTipoTelefoneRepresentante3} name="tipoTelefoneComercialRepresentante3" value="1" />
                                                                                <label className="label">Celular</label>

                                                                                <input type="radio" id="FixoRepresentante3" onClick={this.onClickTipoTelefoneRepresentante3} name="tipoTelefoneComercialRepresentante3" value="2" />
                                                                                <label className="label">Fixo</label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 uninter-campos">
                                                                        <label>Telefone Comercial</label>
                                                                        <input type="text" id="telefoneComercialRepresentante3" name="telefoneComercialRepresentante3" maxLength="15" />
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos" hidden={form.tipoConvenio.value == 1 || form.tipoConvenio.value == 0}>
                                                                        <ul className="uninter-declaro-convenioempresas">
                                                                            <label className="label" tooltip-position="bottom" tooltip="Selecionar quando for necessário envio de nota por e-mail.">Receber nota por e-mail</label>
                                                                            <li className="form-check">
                                                                                <input type="checkbox" id="recebeEmailNotaRepresentante3" name="recebeEmailNotaRepresentante3" value="1" />
                                                                                <label className="label">Sim</label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos uninter-campos" hidden={form.tipoConvenio.value == 1 || form.tipoConvenio.value == 0}>
                                                                        <ul className="uninter-declaro-convenioempresas">
                                                                            <label className="label" tooltip-position="bottom" tooltip="Selecionar quando for necessário envio de boleto por e-mail.">Receber boleto por e-mail</label>
                                                                            <li className="form-check">
                                                                                <input type="checkbox" id="recebeEmailBoletoRepresentante3" name="recebeEmailBoletoRepresentante3" value="1" />
                                                                                <label className="label">Sim</label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="collapse" id="representante4">
                                                        <div className="card-body">
                                                            <h3>Representante 4</h3>
                                                            <ul className="uninterconvenioempresas uninter-passo-interno">
                                                                <li>
                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label>CPF</label>
                                                                        <input type="text" id="cpfRepresentante4" name="cpfRepresentante4" />
                                                                    </div>

                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label>Nome</label>
                                                                        <input type="text" id="nomeRepresentante4" name="nomeRepresentante4" onBlur={this._handleResponsavelChange} />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label>RG</label>
                                                                        <input type="text" id="rgRepresentante4" name="rgRepresentante4" />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label htmlFor="ddlCargoRepresentante4">Cargo</label>
                                                                        <Select
                                                                            onChange={this._handleCargoChange}
                                                                            isLoading={form.listCargo.value.length > 0 ? false : true}
                                                                            options={form.listCargo.value.map((o, i) => {
                                                                                return { id: i, value: o.codigo, label: o.descricao };
                                                                            })}
                                                                            placeholder="SELECIONE"
                                                                            id={"cargoRepresentante4"}
                                                                            name={"cargoRepresentante4"}
                                                                            defaultValue={{ label: "SELECIONE", value: 0 }}
                                                                        />
                                                                    </div>
                                                                    {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                        <label>Departamento</label>
                                                                        <Select
                                                                            onChange={this._handleDepartamentoChange}
                                                                            isLoading={form.listDepartamento.value.length > 0 ? false : true}
                                                                            options={form.listDepartamento.value.map((o, i) => {
                                                                                return { id: i, value: o.codigo, label: o.descricao };
                                                                            })}
                                                                            placeholder="SELECIONE"
                                                                            id={"departamentoRepresentante4"}
                                                                            name={"departamentoRepresentante4"}
                                                                            defaultValue={{ label: "SELECIONE", value: 0 }}
                                                                        />
                                                                    </div> */}

                                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                        <label>Email</label>
                                                                        <input type="email" id="emailRepresentante4" name="emailRepresentante4" maxLength="250" />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 uninter-campos">
                                                                        <label>Telefone Celular</label>
                                                                        <input type="text" id="telefoneRepresentante4" name="telefoneRepresentante4" maxLength="15" />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 uninter-campos">
                                                                        <ul className="uninter-declaro-convenioempresas">
                                                                            <label className="label">Tipo Telefone Comercial</label>
                                                                            <li className="form-check">
                                                                                <input type="radio" id="CelularRepresentante4" onClick={this.onClickTipoTelefoneRepresentante4} name="tipoTelefoneComercialRepresentante4" value="1" />
                                                                                <label className="label">Celular</label>

                                                                                <input type="radio" id="FixoRepresentante4" onClick={this.onClickTipoTelefoneRepresentante4} name="tipoTelefoneComercialRepresentante4" value="2" />
                                                                                <label className="label">Fixo</label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 uninter-campos">
                                                                        <label>Telefone Comercial</label>
                                                                        <input type="text" id="telefoneComercialRepresentante4" name="telefoneComercialRepresentante4" maxLength="15" />
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos" hidden={form.tipoConvenio.value == 1 || form.tipoConvenio.value == 0}>
                                                                        <ul className="uninter-declaro-convenioempresas">
                                                                            <label className="label" tooltip-position="bottom" tooltip="Selecionar quando for necessário envio de nota por e-mail.">Receber nota por e-mail</label>
                                                                            <li className="form-check">
                                                                                <input type="checkbox" id="recebeEmailNotaRepresentante4" name="recebeEmailNotaRepresentante4" value="1" />
                                                                                <label className="label">Sim</label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos uninter-campos" hidden={form.tipoConvenio.value == 1 || form.tipoConvenio.value == 0}>
                                                                        <ul className="uninter-declaro-convenioempresas">
                                                                            <label className="label" tooltip-position="bottom" tooltip="Selecionar quando for necessário envio de boleto por e-mail.">Receber boleto por e-mail</label>
                                                                            <li className="form-check">
                                                                                <input type="checkbox" id="recebeEmailBoletoRepresentante4" name="recebeEmailBoletoRepresentante4" value="1" />
                                                                                <label className="label">Sim</label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="collapse" id="representante5">
                                                        <div className="card-body">
                                                            <h3>Representante 5</h3>
                                                            <ul className="uninterconvenioempresas uninter-passo-interno">
                                                                <li>
                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label>CPF</label>
                                                                        <input type="text" id="cpfRepresentante5" name="cpfRepresentante5" />
                                                                    </div>

                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label>Nome</label>
                                                                        <input type="text" id="nomeRepresentante5" name="nomeRepresentante5" onBlur={this._handleResponsavelChange} />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label>RG</label>
                                                                        <input type="text" id="rgRepresentante5" name="rgRepresentante5" />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 uninter-campos">
                                                                        <label htmlFor="ddlCargoRepresentante5">Cargo</label>
                                                                        <Select
                                                                            onChange={this._handleCargoChange}
                                                                            isLoading={form.listCargo.value.length > 0 ? false : true}
                                                                            options={form.listCargo.value.map((o, i) => {
                                                                                return { id: i, value: o.codigo, label: o.descricao };
                                                                            })}
                                                                            placeholder="SELECIONE"
                                                                            id={"cargoRepresentante5"}
                                                                            name={"cargoRepresentante5"}
                                                                            defaultValue={{ label: "SELECIONE", value: 0 }}
                                                                        />
                                                                    </div>
                                                                    {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                        <label>Departamento</label>
                                                                        <Select
                                                                            onChange={this._handleDepartamentoChange}
                                                                            isLoading={form.listDepartamento.value.length > 0 ? false : true}
                                                                            options={form.listDepartamento.value.map((o, i) => {
                                                                                return { id: i, value: o.codigo, label: o.descricao };
                                                                            })}
                                                                            placeholder="SELECIONE"
                                                                            id={"departamentoRepresentante5"}
                                                                            name={"departamentoRepresentante5"}
                                                                            defaultValue={{ label: "SELECIONE", value: 0 }}
                                                                        />
                                                                    </div> */}

                                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 uninter-campos">
                                                                        <label>Email</label>
                                                                        <input type="email" id="emailRepresentante5" name="emailRepresentante5" maxLength="250" />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 uninter-campos">
                                                                        <label>Telefone Celular</label>
                                                                        <input type="text" id="telefoneRepresentante5" name="telefoneRepresentante5" maxLength="15" />
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 uninter-campos">
                                                                        <ul className="uninter-declaro-convenioempresas">
                                                                            <label className="label">Tipo Telefone Comercial</label>
                                                                            <li className="form-check">
                                                                                <input type="radio" id="CelularRepresentante5" onClick={this.onClickTipoTelefoneRepresentante5} name="tipoTelefoneComercialRepresentante5" value="1" />
                                                                                <label className="label">Celular</label>

                                                                                <input type="radio" id="FixoRepresentante5" onClick={this.onClickTipoTelefoneRepresentante5} name="tipoTelefoneComercialRepresentante5" value="2" />
                                                                                <label className="label">Fixo</label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 uninter-campos">
                                                                        <label>Telefone Comercial</label>
                                                                        <input type="text" id="telefoneComercialRepresentante5" name="telefoneComercialRepresentante5" maxLength="15" />
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos" hidden={form.tipoConvenio.value == 1 || form.tipoConvenio.value == 0}>
                                                                        <ul className="uninter-declaro-convenioempresas">
                                                                            <label className="label" tooltip-position="bottom" tooltip="Selecionar quando for necessário envio de nota por e-mail.">Receber nota por e-mail</label>
                                                                            <li className="form-check">
                                                                                <input type="checkbox" id="recebeEmailNotaRepresentante5" name="recebeEmailNotaRepresentante5" value="1" />
                                                                                <label className="label">Sim</label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 uninter-campos uninter-campos" hidden={form.tipoConvenio.value == 1 || form.tipoConvenio.value == 0}>
                                                                        <ul className="uninter-declaro-convenioempresas">
                                                                            <label className="label" tooltip-position="bottom" tooltip="Selecionar quando for necessário envio de boleto por e-mail.">Receber boleto por e-mail</label>
                                                                            <li className="form-check">
                                                                                <input type="checkbox" id="recebeEmailBoletoRepresentante5" name="recebeEmailBoletoRepresentante5" value="1" />
                                                                                <label className="label">Sim</label>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card uninter-convenioempresas-final uninter-passo">
                                                <div className="card-header uninter-convenioempresas-header" id="uploadArquivos">
                                                    <h3 className="colapseTitle" data-toggle="collapse" data-target="#collapseUploadArquivos" aria-expanded="true" aria-controls="collapseUploadArquivos" id="titleUploadArquivos"> {form.tipoEmpresa.value == 0 || form.tipoEmpresa.value == 1 ? 'Envio de Contrato' : 'Envio de Arquivos'}
                                                        <span hidden={form.tipoEmpresa.value == 0 || form.tipoEmpresa.value == 1}></span>
                                                        <i hidden={form.lista.value.length == 0} className="fa fa-check answer-sucess" aria-hidden="true"></i></h3>
                                                </div>
                                                <div id="collapseUploadArquivos" className="collapse colapseDescription" aria-labelledby="uploadArquivos" data-parent="#accordionFormulario">
                                                    <div className="card-body">
                                                        <ul className="uninterconvenioempresas uninter-passo-interno">
                                                            <li>
                                                                <label hidden={form.tipoEmpresa.value > 0}>Selecione o tipo da empresa</label>
                                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" hidden={(form.tipoEmpresa.value == 0 || form.tipoEmpresa.value == 2) || (form.tipoEmpresa.value == 1 && (form.tipoConvenio.value == 0 || form.tipoConvenio.value == 2))}>
                                                                    <h4 className="titleFormulario">Envio de Contrato próprio</h4>
                                                                    <div className="uninter-declaro-convenioempresas uninter-desconto-contrato-top">
                                                                        <span id="enviocontrato">Envio Opcional</span>
                                                                    </div>
                                                                    <div className="uninter-declaro-convenioempresas uninter-desconto-contrato">
                                                                        Caso tenha que inserir dados/cláusulas de um contrato próprio, favor enviar arquivo abaixo.
                                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 marginBox">
                                                                            <FileUpload ltype="Contrato" linput="Escolha o arquivo" lsearch="Buscar" idfile="idContrato" multiple="multiple" name="arquivounico" accept="application/pdf,image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" hidden={(form.tipoEmpresa.value == 0 || form.tipoEmpresa.value == 1) && (form.tipoConvenio.value == 0 || form.tipoConvenio.value == 1)}>
                                                                    <h4 className="titleFormulario">Envio de arquivos</h4>
                                                                    <div className="uninter-declaro-convenioempresas uninter-documentos-top">
                                                                        <span id="enviocontrato">{form.tipoConvenio.value == 1 ? "Envio Opcional" : "Envio Obrigatório"}</span>
                                                                    </div>

                                                                    <div className="uninter-declaro-convenioempresas uninter-documentos">
                                                                        Para inserir contrato e outros documentos, favor selecionar o arquivo abaixo e adicionar.<br /><br />
                                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 marginBox">
                                                                            <div className="row">
                                                                                <ul className="uninter-declaro-convenioempresas uninter-desconto-pagamento">
                                                                                    <li className="form-check">
                                                                                        <input type="radio" id="contrato" name="tipoArquivo" value="1" onChange={this._handleArquivoChange} disabled={form.tipoConvenio.value == '2'} />
                                                                                        <label className="form-check-label ">Contrato</label>
                                                                                        <input type="radio" id="documento" name="tipoArquivo" value="2" onChange={this._handleArquivoChange} className="checkPagamento" />
                                                                                        <label className="form-check-label checkPagamento">Outros Documentos</label><br />
                                                                                    </li>
                                                                                    <span className="error" id="tipoArquivoError"></span>
                                                                                </ul>
                                                                            </div>
                                                                            <div className="row" hidden={form.tipoArquivo.value == 0 || form.tipoArquivo.value == 1}>
                                                                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1 uninter-campos" hidden={form.tipoConvenio.value == 1}>
                                                                                    <label>Selecione o responsável pelo envio dos documentos obrigatórios</label>
                                                                                    <Combobox onChange={this.onChangeResponsavel} opstions={form.listResponsaveis.value} id={"representanteEscolha"} name={"representanteEscolha"}></Combobox>
                                                                                    <span className="error" id="representanteEscolhaError"></span>
                                                                                </div>
                                                                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1 uninter-campos">
                                                                                    <label>Selecione tipo de documento</label>
                                                                                    <Combobox onChange={this.onChangeTipoDocumento} opstions={form.listTiposDocumentos.value} id={"tipoDocumento"} name={"tipoDocumento"}></Combobox>
                                                                                    <span className="error" id="tipoDocumentoError"></span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row" hidden={form.tipoArquivo.value == 0}>
                                                                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
                                                                                    <input type="file" className="custom-file-input" id={"idMultiplosArquivos"} name='arquivo' aria-describedby="inputGroupFileAddon02" onChange={(e) => this.handleChangeMultiInputFile(e.target.files)}
                                                                                        multiple={false} ref={this.multifileInputRef} hidden={true} accept="application/pdf,image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" name={this.props.name} />
                                                                                    <br />
                                                                                    <div className="input-group">
                                                                                        <div className="input-group-prepend">
                                                                                            <span className="input-group-text label-file" id="inputGroupFileAddon02" id="basic-addon2">{"Arquivo"}</span>
                                                                                        </div>
                                                                                        <label type="text" className="form-control label-file input-white-file" aria-label="Large" id="inputGroupFile012" aria-describedby="basic-addon2 basic-addon3">{"Selecione um arquivo"}</label>
                                                                                        <div className="input-group-append">
                                                                                            <span className="input-group-text label-file search-file" id="basic-addon3" onClick={(e) => this.handleClick(e.target.files)}>{"Buscar"}</span>
                                                                                        </div>
                                                                                        <div className="input-group-append">
                                                                                            <span className="input-group-text label-file search-file btn-adicionar" id="basic-addon4" onClick={(e) => this.addClick(e.target.files)}>{"Adicionar"}</span>
                                                                                        </div>
                                                                                        {/* <div className="input-group-append">
                                                                                                <span className="input-group-text label-file drop-file" id="basic-addon5" onClick={(e) => this.handleDrop()}><i className="fa fa-times" aria-hidden="true"></i></span>
                                                                                            </div> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row" hidden={form.tipoArquivo.value == 0}>
                                                                                <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
                                                                                    {this.state.lista}
                                                                                </div>
                                                                            </div>

                                                                            <span className="error" id="listaError" hidden={form.tipoArquivo.value == 0}></span>
                                                                            <div hidden={form.tipoArquivo.value == 0}>
                                                                                <table className="uninter-table-documentos">
                                                                                    <tr>
                                                                                        <th className="primeira">Nome </th>
                                                                                        <th className="other">Extensão </th>
                                                                                        <th className="other">Tipo Arquivo </th>
                                                                                        {form.tipoConvenio.value != '2' ? <th className="other">Tipo Documento</th> : ''}
                                                                                        {form.tipoConvenio.value == '2' ? <th className="other">Responsável</th> : ''}
                                                                                        <th className="ultima">Excluir</th>
                                                                                    </tr>
                                                                                    {todoItems}
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="uninter-box-convenioempresas-curso-base indicativoAssinatura">
                                            Esta página é parte integrada e indissociável do CONVÊNIO ENTRE UNINTER EDUCACIONAL S/A e a CONVENIADA, firmado na data da assinatura abaixo mencionada, com o qual a CONVENIADA concorda em sua integralidade.
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <center>
                                                <Recaptcha
                                                    sitekey="6Lc8vaUUAAAAAI2cXfHs3ZE8Dt5O6M9ap1E7GzSV"
                                                    render="explicit"
                                                    onloadCallback={this.recaptchaLoaded}
                                                    verifyCallback={this.verifyCallback}
                                                    hl="pt-BR"
                                                />
                                                <input className="uninter-box-home-convenioempresas-btn-enviar-formulario" type="submit" value="ENVIAR FORMULÁRIO" />
                                                {errors && errors.length > 0 &&
                                                    <div className="listErros">
                                                        <div className="uninter-box-convenioempresas-curso-base indicativoAssinatura col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                            <h3>Foram encontrados os seguintes erros</h3>
                                                            {errors.map((item, i) => <div className="listErrosTags" key={i}><i className="fa fa-exclamation-circle"></i>{item}</div>)}
                                                        </div>
                                                    </div>
                                                }
                                            </center>

                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <h3 className="footerDate">Curitiba, {new Date().getDate().toString().padStart(2, '0')} de {["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][new Date().getMonth()]} de {new Date().getFullYear().toString()}</h3>
                                            <span className="pull-left" >
                                                {new Date().getFullYear().toString()} &copy; Empresa Convênio Uninter - Departamento de Tecnologia da Informação
                                            </span>
                                            <p className="copyright pull-right"></p>
                                        </div>
                                        <Link hidden={true} id='idFinish' to="Finish"></Link>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}