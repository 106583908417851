import React from 'react';
import { Link } from 'react-router-dom';

export default function MenuItem(props) {

    //const [showMenu, setMenuState] = useState(false);
    const isOpen = props.id === props.menuAbertoId;

    const menus = props.filhos
        .filter((menu) => {
            return menu.visivel === 1;
        }).map(menu =>
            <li key={menu.id}>
                <MenuItem {...menu} nomeIcone="fa fa-circle icon-submenu" />
            </li>
        );


    return (<React.Fragment>
        {(props.filhos != null && props.filhos.length > 0 ?
            <React.Fragment>
                <a onClick={() => props.toggleMenu(props.id)} data-toggle="collapse" className={`dropdown-toggle ${(isOpen ? 'abrir-menu' : '')} d-flex`} to="">
                    <i className={props.nomeIcone}></i>
                    <div className="uninter-class-convenio-menu-base-texto">
                        {props.nome}
                    </div>
                </a>
                <ul className={`collapse list-unstyled sub-menu ${(isOpen ? 'show' : '')}`}>
                        {menus}
                </ul>
            </React.Fragment>
            :
            <Link to={`/${props.url}`} >
                <i className={props.nomeIcone}></i>
                <div className="uninter-class-convenio-menu-base-texto">
                    {props.nome}
                </div>
            </Link>
        )}
    </React.Fragment>
    );
}

MenuItem.defaultProps = {
    nomeIcone: 'm-menu__link-bullet m-menu__link-bullet--dot',
};
//m-menu__link-bullet m-menu__link-bullet--dot