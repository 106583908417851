import React, { Component, useState } from 'react';
import './Login.scss';
import '../../../assets/Geogrotesque/geofrotesque.css';
import Loading from '../../../components/Loading'
import '../../../components/EmpresaConvenio/EmpresaConvenio.css';
import Select from 'react-select'
import EmpresaService from '../../../services/EmpresaService';
import AssinaturaContratoService from '../../../services/AssinaturaContratoService';

export default class SelecionarEmpresa extends Component {

    constructor(props) {
        super(props);
        const config = process.env.REACT_APP_STAGE === 'prod'
        this.state = {
            valid: false,
            loading: false,
            error: '',
            form: {
                idEmpresa: {
                    value: props.idEmpresa || 0,
                },
                listEmpresas: { value: props.listEmpresas || [] },
                ru: props.RU,
                senha: props.Senha,
                sistema: props.Sistema
            },

        }
        this._onChangeForm = this._onChangeForm.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    load = async () => {
        const { form } = this.state;
        //form.listEmpresas.value = await EmpresaService.OnGetListaEmpresaContrato();
        form.listEmpresas.value = await AssinaturaContratoService.OnGetListaEmpresasParaAssinatura();
        this.setState({ form });
    }

    _handleEmpresaChange = async (event) => {
        const { form } = this.state;
        form.idEmpresa.value = event.value;
        this.props.history.push(`/administracao/visualizarContrato/${form.idEmpresa.value}`);
        localStorage.setItem("IdEmpresa", form.idEmpresa.value);
        this.setState({ form });
    }

    async _onChangeForm(form) {
        this.setState({ form })
    }

    _onValid = valid => this.setState({ valid })

    render() {
        const { loading } = this.state;
        const { form } = this.state;

        return (
            <>
                {loading && Loading}
                <div className="row uninter-card-interno">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-2">
                        <div className="uninter-passo-geral col-xs-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-10 offset-lg-1 uninter-card-empresa-convenio">
                            <div className="card uninter-convenioempresas-final uninter-passo">
                                <h1>Selecione a Empresa</h1>
                            </div>
                            <div className="card-body">
                                <ul className="uninterconvenioempresas uninter-passo-interno">
                                    <li>
                                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 uninter-campos">
                                            <label>Empresa / CNPJ</label>
                                            <Select
                                                onChange={this._handleEmpresaChange}
                                                isLoading={form.listEmpresas.value.length > 0 ? false : true}
                                                options={form.listEmpresas.value.map((o, i) => {
                                                    return { id: i, value: o.codigo, label: o.descricao?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") };
                                                })}
                                                id={"idEmpresa"}
                                                name={"idEmpresa"}
                                                defaultValue={{ label: "SELECIONE", value: 0 }} />
                                            <span className="error" id="idEmpresaError"></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}