import React, { Component } from 'react';
import { Route, Redirect } from "react-router-dom";
import AuthService from '../Auth';
import enviromment from '../../environment';

export default class PrivateRoute extends Component {

    isAuthenticated = () => {
        return AuthService.isLoggedIn();
    }

    hasPermission = (menu, privilegio) => {
        return AuthService.verifyPrivilegio(menu, privilegio) || (menu === "default" && privilegio === 'basic');
    }

    render() {
        const {
            component: Component,
            privilegio,
            menu,
            name,
            ...rest
        } = this.props;
     
        document.title = `${name} | ${enviromment.nome}`;
        
        return (
            <Route
                {...rest}
                render={props =>
                    this.isAuthenticated() ? (
                        this.hasPermission(menu, privilegio) ? (
                            <Component {...props} />
                        ) : (
                                <Redirect to={{ pathname: "/500", state: { from: props.location } }} />
                            )
                    ) : (
                            <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                        )
                }
            />
        )
    }
}
//bele
PrivateRoute.defaultProps = {
    privilegio: 'basic',
    menu: 'default',
};