import BaseApi from "./BaseApi";

export default class ContratoService {

    static async post(command) {
        try {
            let res = await BaseApi.post(`v1/postDocumento/${command}`);
            return res.data;

        } catch (error) {
            debugger
            return error.response.data;
        }
    }

    static async OnPostUpload(files) {
        try {
            let res = await BaseApi.post(`/v1/Contrato/Upload`, files);
            return res;

        } catch (error) {
            return error.response.data;
        }
    }

    static async OnPostMultUpload(files) {
        try {
            debugger;
            let res = await BaseApi.post(`/v1/Contrato/MultUpload`, files);
            debugger;
            return res;

        } catch (error) {
            return error.response.data;
        }
    }
    static async OnPostInserir(command) {
        try {
            let res = await BaseApi.post(`/v1/Contrato/Inserir`, command);
            return res;

        } catch (error) {
            return error.response.data;
        }
    }

    static async OnGetModelos() {
        try {
            let res = await BaseApi.get(`/v1/Contrato/GetModelos`);
            return res.data;

        } catch (error) {
            return error.response.data;
        }
    }

    static async OnGetTipos() {
        try {
            let res = await BaseApi.get(`/v1/Contrato/GetTipo`);
            return res.data;

        } catch (error) {
            return error.response.data;
        }
    }

    static async gerarContrato(form) {
        try {
            let res = await BaseApi.post(`v1/Contrato/GerarContrato/`, form);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async downloadFormularioBase64(IdEmpresa) {
        try {
            let res = await BaseApi.post(`v1/DownloadContrato/DownloadContratoBase64/${IdEmpresa}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async downloadFormulario(IdEmpresa) {
        try {
            let res = await BaseApi.post(`v1/DownloadContrato/DownloadContrato/${IdEmpresa}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }

    static async downloadContratoAssinado(IdEmpresa) {
        try {
            let res = await BaseApi.post(`v1/DownloadContrato/DownloadContratoAssinado/${IdEmpresa}`);
            return res;
        } catch (error) {
            return error.response.data;
        }
    }
}